import {IPatientCase} from "../interfaces";

export const PATIENT_CASE_DEFAULT_STATE: { patient_case: IPatientCase, rules: any[]} = {
  patient_case: {
    sex: "W",
    adm_mode: "01",
    sep_mode: "00",
    age_years: 40,
    age_days: 0,
    gest_age: 0,
    age_selection: "1",
    adm_weight: 0,
    adm_date: null,
    exit_date: null,
    los: 10,
    vacation_days: 0,
    hmv: 0,
    procedures: [{code: "", side: "", date: "", active: true, valid: true, used: false, text: ''}],
    main_diagnosis: {code: "", active: true, valid: true, used: false, text: ''},
    secondary_diagnoses: [],
    drugs: [],
    tarpos: []
  },
  rules: [],
};
