import * as React from 'react';
import {IPcsGrouperResults} from "../../interfaces";
import { i18n } from "../../config/i18n";
import Spinner from "../Spinner";

interface Props {
    grouperResults: IPcsGrouperResults
}

const PcsGroupResult: React.FunctionComponent<Props> = (
    {
        grouperResults}) => {
    return (
        <div className="code_proposal_box" id="group_results_box">
            <div className="row vertical-spacer">
                <div className="col-sm-12">
                    <h4>{i18n.t("group_results")}</h4>
                </div>
                <div className="col-12 p-0">
                    <div className="row">
                        <div className="col-sm-12">
                            {grouperResults ?
                                <>
                                    <div className="code_description" id="drg_description">
                                        <h4>
                                            {grouperResults.group}
                                        </h4>
                                    </div>

                                    <table className="grouping-table">
                                        <tbody>
                                        { grouperResults.decision_path &&
                                            <tr>
                                                <td><label>{i18n.t('decision_path')}</label></td>
                                                <td>
                                                    <div className="">
                                                        {grouperResults.decision_path.map((explanation, i) => (
                                                            <div className="alert alert-info" key={i}>
                                                                {explanation.name}
                                                                {explanation.explanation &&
                                                                <div>
                                                                    {explanation.explanation.variables.map((k, i) =>
                                                                        <div key={i} className="badge bg-primary me-1">{k}</div>)
                                                                    }
                                                                    {explanation.explanation.codes.map((k, i) =>
                                                                        <div key={i} className="badge bg-info text-white me-1">{k}</div>)
                                                                    }
                                                                </div>
                                                            }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </>
                                : <Spinner/>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PcsGroupResult
