import * as React from 'react';
import { i18n } from "../config/i18n";
import Select from 'react-select'
import {httpRails} from "../services/api";
import {useState} from "react";

interface Props {
    paramA: string,
    locale: string
}

const SearchCompare: React.FunctionComponent<Props> = (props) => {
    const [options, setOptions] = useState<{label: string, options: {label: string, value: string}[]}[]>([])

    function openComparison(paramB: string) {
        const {paramA} = props
        const split = paramB.split('_');
        if(split[1] === undefined){
            alert("Please select a population to compare (ICD-, CHOP-, DRG-Benchmark, import, rule).");
            return;
        }
        const comparison_config = paramA + '&' + split[0] + '_b_id=' + split[1];
        const link = '/comparisons/compare?' + comparison_config;
        console.log('Opening ' + link);
        window.open(link, comparison_config);
    }

    function searchComparisons(searchTerm: string) {
        console.log('Searching for ' + searchTerm);
        // TODO move endpoint (http) to internal API
        httpRails.get('/comparisons/search_comparison_objects',
            {}, {
                params: {term: searchTerm}
            }).then(response => response.data)
            .then((data) => {
                // @ts-ignore
                setOptions(data)
            });
    }

    const {locale} = props
    i18n.locale = locale;
    return <div className="col-lg-5">
        <label>{i18n.t('compare_with')}</label>
        <Select
            options={options}
            onInputChange={(e) => {
                searchComparisons(e)
            }}
            onChange={(e) => {
                openComparison(e.value)
            }}
            isSearchable={true}
            menuPortalTarget={document.querySelector('body')}
            placeholder={i18n.t('search_comparison_objects')}
        />
    </div>
}

export default SearchCompare
