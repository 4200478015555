import React from 'react';
import { IRule } from '../../interfaces';
import { i18n } from '../../config/i18n';
import { buildRailsStyleUrl, getCurrentUrl } from '../../utils/UrlUtils';

interface PreparedFiltersProps {
    filterId: string | null;
    filterCategory: {
        id: string,
        rules: IRule[];
    };
    filterDescription: string;
    hidePreparedFilters: boolean;
    params: { [key: string]: any | null };
}

const PreparedFilters: React.FC<PreparedFiltersProps> = ({
    filterId,
    filterCategory,
    filterDescription,
    hidePreparedFilters,
    params
}) => {

    const currentURL = getCurrentUrl();

    return (
        <>
            <div className="col-lg-9">
                <h4>
                    {!hidePreparedFilters && <>
                        <a
                            href={buildRailsStyleUrl(currentURL, { ...params, filter: null, rule: null })}
                            className={"badge " + (!filterId ? 'bg-info' : 'text-bg-secondary')}
                        >
                            {i18n.t('all')}
                        </a>

                        {filterCategory.rules.map((rule) => (
                            <a
                                className={"ms-1 badge " +
                                    (filterId === rule.id.toString()
                                        ? 'bg-info'
                                        : 'text-bg-secondary')
                                }
                                key={rule.id}
                                href={buildRailsStyleUrl(currentURL, { ...params, filter: rule.id, rule: null })}
                                title={rule.hint}

                            >
                                {rule.name}
                            </a>
                        ))}

                        <div
                            className="icon badge text-bg-light"
                            title={i18n.t('edit_filters')}
                            style={{ display: 'inline-block' }}>
                            <a href={'/categories/' + filterCategory.id + '/show_rules'}>
                                <i className="fa fa-pencil action action-edit"></i>
                            </a>
                        </div>
                    </>
                    }
                    {filterDescription && (
                    <div className="icon badge text-bg-light ms-2">
                        {i18n.t('active_filter')}:
                        <span dangerouslySetInnerHTML={{__html: filterDescription}} />
                    </div>
                )}
                </h4>    
            </div>
        </>
    );
};

export default PreparedFilters;