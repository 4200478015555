import * as React from 'react';
import {Field} from "formik";
import classnames from 'classnames';
import FieldWrapper from "./FieldWrapper";
import {IFlag} from "../interfaces";

interface Props {
    name: string;
    label?: string;
    disabled?: boolean;
    flags?: IFlag;
    inputWrapperClassName?: string;
    grid?: { label: number, input: number };
    validate?: any;
    value?: any;
    onChange?: any;
}

export const CheckboxField: React.FunctionComponent<Props> =
    ({flags, disabled, name, validate, value, onChange, ...otherProps}) => {
        const valueProps = (typeof value === 'undefined') ? {} : {value: value};
        const onChangeProps = (typeof onChange === 'undefined') ? {} : {onChange: onChange};

        return (
            <FieldWrapper {...otherProps} name={name}>
                <Field type="checkbox"
                       name={name}
                       id={name}
                       {...valueProps}
                       {...onChangeProps}
                       validate={validate}
                       className={
                           classnames(
                               "form-check-input",
                               {"input-invalid": flags && flags.valid === false},
                               {"input-used": flags && flags.used === true},
                               {"input-disabled": disabled}
                           )
                       }
                       disabled={disabled}
                />
            </FieldWrapper>
        );
    };

export default CheckboxField;
