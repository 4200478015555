import * as React from 'react';
import {LocaleProvider} from '../contexts/LocaleContext';
import {UserProvider} from '../contexts/UserContext';
import { i18n } from "../config/i18n";
import {IUser, IYearCatalogs} from "../interfaces";
import Spinner from '../components/Spinner';
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";
import Summary, { ISummaryProps } from '../components/summary-of-cases/Summary';
import FilterCases, {IFilterCasesProps} from "../components/filter/FilterCases";
import { VariablesProvider } from '../contexts/VariableContext';
import { buildRailsStyleUrl, getCurrentUrl } from '../utils/UrlUtils';
import { ExistingFilterProvider } from '../contexts/ExistingFilterContext';
import { MedcodelogicLoader } from '../contexts/MedcodelogicLoaderContext';


interface Props {
    locale: string,
    user: IUser,
    catalogs: IYearCatalogs,
    summaryProps: ISummaryProps,
    filterProps: IFilterCasesProps,
    downloadAsExcel: boolean,
    existingFilter: string
}

const SummaryWithFilterPage: React.FunctionComponent<Props> = (props) => {
    const {locale, user, summaryProps, filterProps, existingFilter} = props;
    i18n.locale = locale;
    const currentURL = getCurrentUrl();

    const excelLink = buildRailsStyleUrl(currentURL, { ...filterProps.params, format: 'xlsx' })

    return (
        <LocaleProvider value={locale}>
            <YearCatalogsProvider value={props.catalogs}>
                <UserProvider value={user}>
                    <VariablesProvider>
                        <MedcodelogicLoader tableScope={props.filterProps.rule.table_scope}>
                            <ExistingFilterProvider value={{ filteredPage: true, filter: existingFilter }}>
                                <React.Suspense fallback={<Spinner />}>
                                    <FilterCases {...filterProps} />
                                </React.Suspense>
                                {props.downloadAsExcel && 
                                    <div className="row vertical-spacer">
                                            <div className="col-lg-9"></div>
                                            <div className="col-lg-3">
                                                <form className="form-inline">
                                                    <a className="btn btn-outline-secondary float-end" 
                                                    href={excelLink}>
                                                        <i className="fa fa-file-excel-o"></i>
                                                        {i18n.t('download_as_excel')}
                                                    </a>
                                                </form>
                                            </div>
                                        </div>                            
                                }
                                <div></div>
                                <React.Suspense fallback={<Spinner />}>
                                    <Summary {...summaryProps} />
                                </React.Suspense>
                            </ExistingFilterProvider>
                        </MedcodelogicLoader>
                    </VariablesProvider>
                </UserProvider>
            </YearCatalogsProvider>
        </LocaleProvider>
    )

}

export default SummaryWithFilterPage
