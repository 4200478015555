import {http} from './api';
import {createCancelTokenHandler} from "./axiosUtils";

export const PcsGroupService = {
  create: (patientCaseJSON, patientCaseURL, id, systemId, locale) =>
      http.post(`/pcs_group`, patientCaseJSON, {
    params: {
      locale: locale,
      pc: patientCaseURL,
      id: id,
      system_id: systemId
    },
    cancelToken: cancelTokenHandler['create'].handleRequestCancellation().token
  }),
};

const cancelTokenHandler = createCancelTokenHandler(PcsGroupService)

