import {http} from './api';
import {createCancelTokenHandler} from "./axiosUtils";

export const GroupResultComparisonService = {
    create: (name, baseGroupResultId, newGroupResultId, description, tags, pcsID, backToPcs) =>
        http.post(`/group_result_comparisons`, {
                group_result_comparison: {
                    name: name,
                    base_group_result_id: baseGroupResultId,
                    new_group_result_id: newGroupResultId,
                    description: description,
                    tags: tags
                },
                pcs_id: pcsID,
                back_to_pcs: backToPcs
            },
            {cancelToken: cancelTokenHandler['create'].handleRequestCancellation().token}
        ),
    update: (description, tags, pcsId, id, backToPcs) =>
        http.put(`/group_result_comparisons/${id}`, {
                group_result_comparison: {
                    name: name,
                    description: description,
                    tags: tags
                },
                pcs_id: pcsId,
                id: id,
                back_to_pcs: backToPcs
            },
            {cancelToken: cancelTokenHandler['update'].handleRequestCancellation().token}),
    recalculate: (id) =>
        http.put(`/group_result_comparisons/${id}/recalculate`, {
            id: id
        },
            {cancelToken: cancelTokenHandler['recalculate'].handleRequestCancellation().token})
};

/** not sure if we really need a cancelTokenHandler here? */
const cancelTokenHandler = createCancelTokenHandler(GroupResultComparisonService)
