import * as React from 'react';
import { i18n } from "../../config/i18n";
import {ISummary } from "../../interfaces";
import Spinner from '../Spinner';
import BasicData from "./BasicData";
import Costs from "./Costs";
import CostComponents from "./CostComponents";
import CodesTables from "../summary-and-codes-tables/CodesTables";
import MedicalLandscape from "./MedicalLandscape";
import {Alert} from "react-bootstrap";
import InteractiveChart from "./InteractiveChart";
import ScatterPlot from "./ScatterPlot";
import { useState} from "react";

export interface ISummaryProps {
    isAdmin: boolean,
    summary: ISummary, 
    summary1?: ISummary
    summary2?: ISummary,
    costsBinSize: number,
    hasCosts: boolean,
    medicalLandscape?: { [key: string] : any},
    codeInfos: {[key: string] : [string, [string[], string][]][]},
    interactiveProps: IInteractiveSummaryProps
}

export interface IInteractiveSummaryProps {
    pcIdsA?: string,
    pcIdsB?: string,
    importId?: string,
    baseUrl: string
}

const Summary: React.FunctionComponent<ISummaryProps> = (props) => {
    const [showScatterplot, setShowScatterplot] = useState<boolean>(false)
    const [showCostComponents, setShowCostComponents] = useState<boolean>(false)

    function summaryOfCases() {
        const {summary, summary2, isAdmin, costsBinSize, hasCosts,
            medicalLandscape, summary1, codeInfos, interactiveProps} = props;

        const sum1 = summary1 || summary

        if (summary.numberOfPatientcases > 0) {
            return (
                <>
                    <BasicData
                        summary={sum1}
                        summary2={summary2}
                        interactiveProps={interactiveProps}
                    />
                    <div className="row mb-3">
                        {hasCosts &&
                            <Costs
                                summary={sum1}
                                summary2={summary2}
                                binSize={costsBinSize}
                            />
                        }
                        <div className="col-6">
                            <InteractiveChart
                                isAdmin={isAdmin}
                                interactiveProps={interactiveProps}
                            />
                            { showScatterplot ?
                                <ScatterPlot
                                    interactiveProps={interactiveProps}
                                    isAdmin={isAdmin}
                                    hideScatterplot={() => setShowScatterplot(false)}
                                />
                                :
                                <div className="card">
                                    <div className="card-header">
                                        <strong>
                                            {i18n.t('scatter_plot')}
                                        </strong>
                                        <span
                                            className="fa fa-plus fa-lg pull-right pseudoLink mt-1"
                                            onClick={() => setShowScatterplot(true)}
                                            title={i18n.t('expand_scatterplot')}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {hasCosts &&
                        <div className="row mb-3">
                            <div className="col-12">
                                {showCostComponents ?
                                    <CostComponents
                                        interactiveProps={interactiveProps}
                                        hideCostComponents={() => setShowCostComponents(false)}
                                        summaryName1={(summary1 || summary).name}
                                        summaryName2={summary2 ? summary2.name : ''}/>
                                    :
                                    <div className="card mt-3">
                                        <div className="card-header">
                                            <strong>
                                                {i18n.t('cost_components')}
                                            </strong>
                                            <span className="ms-2 text-secondary small">
                                                {i18n.t('cost_data_cases_only')}</span>
                                            <span
                                                className="fa fa-plus fa-lg pull-right pseudoLink mt-1"
                                                onClick={() => setShowCostComponents(true)}
                                                title={i18n.t('expand_cost_components')}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>}
                    <CodesTables
                        summary={sum1}
                        summary2={summary2}
                        isAdmin={isAdmin}
                        codeInfos={codeInfos}
                        interactiveProps={interactiveProps}
                    />
                    <div className="vertical-spacer">
                        <h5>
                            <a href="/casematch_zusammenfassungsansicht_dokumentation_statistik.pdf" target='doc'>
                                <i className="fa fa-file-pdf-o"></i> {i18n.t('statistics_documentation')}
                            </a>
                        </h5>
                    </div>
                    {isAdmin && medicalLandscape &&
                        <div className="vertical-spacer">
                            <MedicalLandscape medicalLandscape={medicalLandscape} />
                        </div>
                    }
                </>
            )
        } else {
            return (
            <Alert key={"no_patient_cases"} variant={'info'}>
                {i18n.t('no_patient_cases')}
            </Alert>)
        }
    }

    return (
        <React.Suspense fallback={<Spinner />}>
            {summaryOfCases()}
        </React.Suspense>    
    )

}

export default Summary;

