import * as React from 'react';
import {i18n} from "../config/i18n";
import MCLEditor from "../components/medcodelogic/MCLEditor";
import {ChangeEvent, useEffect, useState} from "react";
import MCLEditorErrorBoundary from "../components/medcodelogic/MCLEditorErrorBoundary";
import { MedcodelogicLoader } from '../contexts/MedcodelogicLoaderContext';
import {IUser, IYearCatalogs} from "../interfaces";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";
import { VariablesProvider } from '../contexts/VariableContext';

interface Props {
    user?: IUser,
    action_url: string,
    locale: string,
    catalogs: IYearCatalogs
}

interface Manipulation {
    variable: string;
    action_name: 'add' | 'replace' | 'delete' | 'set';
    value: string;
    new_value: string;
    filter?: string;
    do_group: boolean
}

const ManipulateImport: React.FunctionComponent<Props> = (props) => {
    const currentUser = props.user;
    const [formData, setFormData] = useState<Manipulation>({
        variable: 'secondary_diagnoses',
        action_name: 'replace',
        value: '',
        new_value: '',
        filter: '',
        do_group: true,
    });
    const codeVariables = ['diagnoses', 'procedures', 'medications', 'tarpos', 'main_diagnosis',
        'secondary_diagnoses', 'tags', 'drugs']
    const isCodeVariable = codeVariables.includes(formData.variable)

    useEffect(() => {
        if(isCodeVariable){
            if(formData.action_name === 'set') {
                setFormData((prevState) => ({...prevState, action_name: 'replace'}))
            }
        } else {
            if(formData.action_name === 'add') {
                setFormData((prevState) => ({...prevState, action_name: 'replace'}))
            }
        }
        if(formData.variable === 'tags' && formData.do_group){
            setFormData((prevState) => ({...prevState, do_group: false}))
        }
    }, [formData]);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({...prevState, [name]: value}));
    };

    const handleChangeCheckbox = (e) => {
        const {name, _} = e.target;
        setFormData((prevState) => ({...prevState, [name]: e.target.checked}));
    };


    const setLogicSrc = (src: string) => {
        setFormData((prevState) => ({...prevState, filter: src}))
    };

    return (
        <div>
            <p>{i18n.t('manipulate_description')}</p>
            <form action={props.action_url} method="post">
                {currentUser.roles.includes("mcl") && (
                    <div className="form-group row">
                        <label htmlFor="filter" className="col-lg-4 col-form-label">
                            {i18n.t('filter_for_subset')}
                        </label>
                        <div className="col-lg-8">
                            <YearCatalogsProvider value={props.catalogs}>
                                <VariablesProvider>
                                    <MedcodelogicLoader tableScope="">
                                        <div className="col-lg-12 form-control">
                                            <MCLEditorErrorBoundary editor={
                                                <MCLEditor logicSrc={formData.filter}
                                                    setLogicSrc={setLogicSrc} />
                                            } />
                                        </div>
                                    </MedcodelogicLoader>
                                </VariablesProvider>
                            </YearCatalogsProvider>
                            <input
                                type="text"
                                readOnly={true}
                                name="filter"
                                hidden={true}
                                className="form-control"
                                size={40}
                                value={formData.filter || ''}
                            />
                        </div>
                    </div>
                )}
                <div className="form-group row">
                    <label htmlFor="variable" className="col-lg-4 col-form-label">
                        {i18n.t('variable')}
                    </label>
                    <div className="col-lg-8">
                        <select
                            name="variable"
                            className="form-control"
                            value={formData.variable}
                            onChange={handleChange}
                        >
                            <option value="main_diagnosis">{i18n.t('pdx')}</option>
                            <option value="secondary_diagnoses">{i18n.t('additional_diagnoses')}</option>
                            <option value="diagnoses">{i18n.t('diagnoses')}</option>
                            <option value="procedures">{i18n.t('procedures')}</option>
                            <option value="drugs">{i18n.t('drugs')}</option>
                            <option value="tags">{i18n.t('tags')}</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <input type="hidden" name="authenticity_token"
                           value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                           autoComplete="off"/>
                    <label htmlFor="action" className="col-lg-4 col-form-label">
                        {i18n.t('action')}
                    </label>
                    <div className="col-lg-8">
                        <select
                            name="action_name"
                            className="form-control"
                            value={formData.action_name}
                            onChange={handleChange}
                        >
                            <option value="replace">{i18n.t('replace')}</option>
                            {isCodeVariable && formData.variable != 'main_diagnosis' &&
                                <option value="add">{i18n.t('add_code')}</option>
                            }
                            {formData.variable != 'main_diagnosis' &&
                                <option value="delete">{i18n.t('delete')}</option>}
                            {!isCodeVariable &&
                                <option value="set">{i18n.t('set_variable')}</option>
                            }
                        </select>
                    </div>
                </div>
                {['replace', 'delete'].includes(formData.action_name) &&
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label htmlFor="value" className="col-form-label">
                                {i18n.t('old_value')}
                            </label>
                            <div className="text-secondary smaller">{i18n.t('old_value_description')}</div>
                        </div>
                        <div className="col-lg-8">
                            <input
                                type="text"
                                name="value"
                                className="form-control"
                                value={formData.value}
                                onChange={handleChange}
                                /*placeholder="z.Bsp: Z83.3, E10*" */
                            />
                        </div>
                    </div>}
                {['replace', 'add', 'set'].includes(formData.action_name) &&
                    <div className="form-group row">
                        <label htmlFor="newValue" className="col-lg-4 col-form-label">
                            {i18n.t('new_value')}
                        </label>
                        <div className="col-lg-8">
                            <input
                                type="text"
                                name="new_value"
                                className="form-control"
                                value={formData.new_value}
                                onChange={handleChange}
                            />
                        </div>
                    </div>}
                {!['tags'].includes(formData.variable) &&
                    <div className="form-group row">
                        <label htmlFor="do_group" className="col-lg-4 col-form-label">
                            {i18n.t('manipulate_group')}
                        </label>
                        <div className="col-lg-1">
                            <input type="checkbox"
                                name="do_group"
                                className="form-check-input"
                                checked={formData.do_group}
                                onChange={handleChangeCheckbox}
                            >
                            </input>
                        </div>
                    </div>
                }
                <button type="submit" className="btn btn-primary">
                    {i18n.t('manipulate_start')}
                </button>
            </form>
        </div>)
}

export default ManipulateImport;
