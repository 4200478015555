import { i18n } from "../config/i18n";

export function accessSummary(summary, name){
    if (summary && summary[name]) {
        return summary[name]
    } else if ( summary && summary.costs && summary.costs[name]) {
        return summary.costs[name]
    } else {
        return null
    }
}

export function confidenceIntervalOfMean(sd, numCases, locale) {
    i18n.locale = locale;
    let confidenceInterval = null;
    let title = "";
    // probably no sd calculated if summary[sd] == 0.0
    if (!sd || sd==0.0) {
        return {confidenceInterval, title: 'No standard deviation could be calculated.'}
    }
    title = i18n.t('wald_confidence') + ": " + sd.toFixed(3)
    confidenceInterval = " ± " + (1.96 * sd / Math.sqrt(numCases)).toFixed(3)
    return {confidenceInterval, title}
}

export function significanceOfDifference(sdA, sdB, numCasesA, numCasesB, diff, locale) {
    i18n.locale = locale;
    let stars = "";
    let title = "";
    // probably no sd calculated if summary[sd] == 0.0
    if (!sdA || !sdB || sdA == 0.0 || sdB == 0.0) {
        return {stars, title: 'No standard deviation could be calculated.'}
    }
    // Calculate standard error of difference of two means (SED), c.f. medizinische statistikbook (4.1) page 81.
    const sed = Math.sqrt(sdA**2/numCasesA + sdB**2/numCasesB)
    // Calculate t-value of the difference, c.f. med. stat. book chapter 5.2.
    const zValue = Math.abs(diff / sed)
    // Convert to significance niveau.
    title = i18n.t('std_error_diff') + ": " + sed.toFixed(3) + ", z: " + zValue.toFixed(3)
    // Set critical z-values for significance levels alpha = 0.95, 0.99 and 0.999.
    // Taking two sided values, since we're interested in the significance of the difference no matter the direction.
    // F.e. for alpha = 0.99 we're using z_krit = z_(1-alpha/2) = z_0.995 = 2.58 and -z_krit = -z_0.995 = -2.55, i.e.
    // abs(z_krit) = 2.58.
    let significance;
    switch (true) {
        case zValue > 3.29:
            significance = i18n.t('highly_significant') + " / > 99.9%" // *** hoch signifikant
            stars = '***'
            break;
        case zValue > 2.58:
            significance = i18n.t('very_significant') + " / > 99%" // ** sehr signifikant
            stars = '**'
            break;
        case zValue > 1.96:
            significance = i18n.t('significant') + " / > 95%" // * signifikant
            stars = '*'
            break;
        default:
            significance = i18n.t('non_significant')
    }
    title += " => " + significance
    return {stars, title}
}
