import * as React from 'react';

import GroupResultComparisonForm from "../forms/GroupResultComparisonForm";
import {LocaleProvider} from '../contexts/LocaleContext';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IGroupResultComparison} from "../interfaces";
import { i18n } from "../config/i18n";

interface Props {
    groupResultComparison: IGroupResultComparison
    locale: string
    // id of selected pcs (available if coming from pcs tabs)
    pcsId: number
    backToPcs: boolean
    // value: id of group result, label: long name
    baseGroupResultsArray?: { value: number, label: string }[]
    // Not the same as base array bcs of filtering when coming from pcs.
    newGroupResultsArray?: { value: number, label: string }[]
}

const GroupResultComparisonPage: React.FunctionComponent<Props> = ({
                                                                       groupResultComparison,
                                                                       locale,
                                                                       baseGroupResultsArray,
                                                                       newGroupResultsArray,
                                                                       pcsId,
                                                                       backToPcs
}) => {
    i18n.locale = locale;
    return (
        <>
            <LocaleProvider value={locale}>
                <GroupResultComparisonForm
                    groupResultComparison={groupResultComparison}
                    baseGroupResultsArray={baseGroupResultsArray}
                    newGroupResultsArray={newGroupResultsArray}
                    pcsId={pcsId}
                    backToPcs={backToPcs}
                />
            </LocaleProvider>
            <ToastContainer hideProgressBar={true}/>
        </>
    )
}

export default GroupResultComparisonPage
