import * as React from 'react';
import {LocaleProvider} from '../contexts/LocaleContext';
import {UserProvider} from '../contexts/UserContext';
import { i18n } from "../config/i18n";
import {ISummary, IUser, IYearCatalogs} from "../interfaces";
import Spinner from '../components/Spinner';
import BasicData from "../components/summary-of-cases/BasicData";
import Costs from "../components/summary-of-cases/Costs";
import Charts from "../components/summary-of-cases/Charts";
import CodesTables from "../components/summary-and-codes-tables/CodesTables";
import {Alert} from "react-bootstrap";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";

export interface ICodeBenchmarkSummaryProps {
    locale: string,
    isAdmin: boolean,
    hasMoreNewborns: boolean
    summary: ISummary,
    summary1?: ISummary,
    summary2?: ISummary,
    user: IUser,
    binSize: number,
    drgVersion: string,
    catalogs: IYearCatalogs,
    titledLinks: {[key: string] : {[key: string] : string}}
}

const DatenspiegelSummary : React.FunctionComponent<ICodeBenchmarkSummaryProps> = (props) => {
    function summaryOfCases() {
        const {locale, summary, summary1, summary2, hasMoreNewborns, isAdmin, binSize, drgVersion, titledLinks} = props;
        i18n.locale = locale;
        const sum1 = summary1 || summary
        if (summary.numberOfPatientcases > 0) {
            return (
                <>
                    <div className="row vertical-spacer">
                        <div className="col-lg-12">
                            <h3>
                                {i18n.t('swissdrg_datenspiegel') + ' ' + drgVersion}
                            </h3>
                        </div>
                    </div>
                    <BasicData
                        summary={sum1}
                        summary2={summary2}
                        isAdmin={isAdmin}
                        hasMoreNewborns={hasMoreNewborns}
                    />
                    {sum1.costs['mean_costs'] != 0 &&
                        <div className="row">
                            <Costs
                                summary={summary}
                                summary2={summary2}
                                binSize={binSize}
                            />
                        </div>}
                    <Charts
                        summary={summary}
                        summary1={sum1}
                        summary2={summary2}
                        hasMoreNewborns={hasMoreNewborns}
                        binSize={binSize}
                    />
                    <CodesTables
                        summary={sum1}
                        summary2={summary2}
                        isAdmin={isAdmin}
                        titledLinks={titledLinks}
                    />
                </>
            )
        } else {
            return (
                <Alert key={"no_patient_cases"} variant={'info'}>
                    {i18n.t('no_patient_cases')}
                </Alert>)
        }
    }

    const {locale, user} = props;
    i18n.locale = locale;
    return (
        <LocaleProvider value={locale}>
            <YearCatalogsProvider value={props.catalogs}>
                <UserProvider value={user}>
                    <React.Suspense fallback={<Spinner/>}>
                        {summaryOfCases()}
                    </React.Suspense>
                </UserProvider>
            </YearCatalogsProvider>
        </LocaleProvider>
    )
}

export default DatenspiegelSummary
