import * as React from 'react';
import GroupResultProgress from "../components/group-result/GroupResultProgress";

interface Props {
    locale: string,
    statusNeeded: string,
    groupResultId: number,
    importId: number,
    pcsId: number,
    pcsName: number,
}


const GroupResultProgressPage : React.FunctionComponent<Props> = (props) => {
    return (<GroupResultProgress {...props} />)
}

export default GroupResultProgressPage
