import * as React from 'react';

import PcsSingleCaseGroupForm from '../forms/PcsSingleCaseGroupForm'
import {LocaleProvider} from '../contexts/LocaleContext';
import { i18n } from "../config/i18n";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IPcs, IUser, IYearCatalogs} from "../interfaces";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {UserProvider} from '../contexts/UserContext';
import {getUrlParam} from "../utils/UrlUtils";
import Spinner from "../components/Spinner";
import TabMenu from "../components/TabMenu";
import {IDrugsData} from "../components/patient-case/Drug";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";

interface Props {
    id: number;
    locale: string,
    systems: IPcs[],
    pageName: string,
    caseNumber?: string,
    links?: {[Identifier: string] : string},
    drugsData?: IDrugsData,
    user?: IUser,
    hideMenu?: boolean,
    catalogs: IYearCatalogs
}

const PcsSingleCaseGroupPage: React.FunctionComponent<Props> = props => {
    const {id, locale, user, caseNumber, links, systems, catalogs} = props

    return (
        <UserProvider value={user}>
            <YearCatalogsProvider value={catalogs}>
                <Router>
                    <Route
                        path='/'
                        render={({location, history}) => {
                            const urlEncodedPc = getUrlParam('pc', location.search);
                            const systemId = getUrlParam('system_id', location.search);
                            i18n.locale = locale;
                            return (<LocaleProvider value={locale}>
                                <>
                                    <React.Suspense fallback={<Spinner/>}>
                                            <TabMenu
                                                urlEncodedPc={urlEncodedPc}
                                                active='pcs-group'
                                                caseNumber={caseNumber}
                                                id={id}
                                                links={links}
                                            />
                                        </React.Suspense>
                                    <PcsSingleCaseGroupForm
                                    urlEncodedPc={urlEncodedPc}
                                    currentSystemId={systemId}
                                    history={history}
                                    systems={systems}
                                    id={id}
                                />
                                </>

                            </LocaleProvider>)
                        }}/>
                </Router>
                <ToastContainer hideProgressBar={true} icon={false}/>
            </YearCatalogsProvider>
        </UserProvider>
    )
}

export default PcsSingleCaseGroupPage
