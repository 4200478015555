
/**
 * Retrieves the value of a query parameter from a URL.
 *
 * @param {string} name - The name of the query parameter.
 * @param {string} url - The URL to extract the query parameter from.
 * @returns {string|null} - The value of the query parameter, or null if it doesn't exist.
 */
export function getUrlParam(name, url) {
    const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(url);
    if (results == null) {
        return null;
    }
    else {
        return decodeURI(results[1]) || '';
    }
}

/** recursive element mapping for Rails style query param creation. */
function mapElement(param, key) {
    if (Array.isArray(param)) {
        return param.map(value => mapElement(value, key + '[]')).join('&');
    } else if (param === null || param === undefined) {
        return null;
    } else if (typeof param === 'object') {
        return Object.keys(param).map(subKey => mapElement(param[subKey], key + '[' + subKey + ']')).join('&');
    } else {
        return key + '=' + param;
    }
    
}

/**
 * function to create a Rails-style URL with query parameters from a params hash.
 *
 * @param {string} url - The base URL.
 * @param {Object} params - The params object (as passed from Rails) to be added to the URL.
 * @returns {string} - The generated URL with query parameters.
 */
export function buildRailsStyleUrl(url, params) {
    const query = Object.keys(params).map(key => mapElement(params[key], key)).filter(e => !(e === null || e === undefined)).join('&');
    return url + '?' + query;
}


/** function that gets the current URL without parameters. */
export function getCurrentUrl() {
    return window.location.href.split('?')[0];
}