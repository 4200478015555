import * as React from 'react';
import {useEffect, useState} from "react";
import {ITable, IYearCatalogs} from "../interfaces";
import {MedcodelogicService} from "../services/MedcodelogicService";
import { setupMedcodelogicInMonaco } from '../components/medcodelogic/MedcodelogicMonaco';
import { LocaleContext } from './LocaleContext';
import { useVariables } from './VariableContext';
import { YearCatalogsContext } from './YearCatalogsContext';
import { IDisposable } from 'monaco-editor';

interface TableScopeDate {
    tableScope: string;
    setTableScope: (tableScope: string) => void;
}

const MedcodelogicLoaderContext = React.createContext<TableScopeDate>(
    {tableScope: '', setTableScope: undefined});

export const useTableScope = (): TableScopeDate => {
    const contextState = React.useContext(MedcodelogicLoaderContext);
    if (contextState === null) {
        throw new Error('useTableScope must be used within a MedcodelogicLoaderContext tag');
    }
    return contextState;
};

export const MedcodelogicLoader: React.FunctionComponent<{tableScope: string, children}> = props => {
    const [tableScope, setTableScope] = useState<string>(props.tableScope)
    const [tables, setTables] = useState<ITable[]>(undefined);
    const locale = React.useContext<string>(LocaleContext)
    const catalogs = React.useContext<IYearCatalogs>(YearCatalogsContext)
    const disposers = React.useRef<IDisposable[]>([]);

    const {variables} = useVariables()

    useEffect(() => { onLoadTables()}, [tableScope]);

    useEffect(() => {
        /** do only load when variables and tables have loaded. */
        if(variables === undefined || tables === undefined)
            return
        /** unregistering before if already registered. */    
        disposers.current.forEach((disposer) => disposer.dispose());
        disposers.current = setupMedcodelogicInMonaco(Object.values(variables || []), locale, tables, tableScope, catalogs);
    }, [variables, tables]);

    function onLoadTables() {
        MedcodelogicService.getTablesList(tableScope)
            .then(response => response.data)
            .then((data) => {
                setTables(data);
            });
    }

    return (
        <MedcodelogicLoaderContext.Provider value={{tableScope: tableScope, setTableScope: setTableScope}}>
            {props.children}
        </MedcodelogicLoaderContext.Provider>
    );  
};
