import * as React from 'react';
import {ErrorMessage, Field, FormikValues} from 'formik';
import UserAutocomplete from "../../form-fields/UserAutocomplete";
import { i18n } from "../../config/i18n";
import {IUser} from "../../interfaces";
import {useContext} from "react";
import {LocaleContext} from "../../contexts/LocaleContext";

interface Props {
    ownerDomainId: string;
    users: IUser[];
    index: number;
    values: FormikValues;
    remove: Function;
    setUsers: Function;
    userIdsFromPermissions: string[]
}

const Permission: React.FunctionComponent<Props> = ({ ownerDomainId, users, index, values,
                                                        remove, setUsers, userIdsFromPermissions }) => {
    const locale = useContext(LocaleContext)
    i18n.locale = locale;
    const renderDomainWarning = (userId) => {
        const user = users.find((u) => (u.id === userId))
        if (user && user.domainId !== ownerDomainId) {
            return (
                <div className="alert alert-warning py-1 px-2 mt-1">
                    <small>
                        {i18n.t('permission_different_domain')}
                        {user.domainName && (
                            <strong>({user.domainName})</strong>
                        )}
                    </small>
                </div>
            )
        }
    }
    return (
        <div className="row with-remove-button" key={index}>
            <div className="col-md-8 mb-3">
                <Field
                    required
                    name={`permissions.${index}.userId`}
                    component={UserAutocomplete}
                    setOptions={setUsers}
                    userIdsFromPermissions={userIdsFromPermissions}
                    options={users}
                />
                {renderDomainWarning(values.permissions[index].userId)}
                <ErrorMessage
                    name={`permissions.${index}.userId`}
                    className="alert alert-danger"
                    render={(errorMessage) => (
                        <div className="alert alert-danger py-1 px-2 mt-1">
                            <small>{errorMessage}</small>
                        </div>
                    )}
                />
            </div>
            <div className="col-md-4 mb-3">
                <Field
                    required
                    as="select"
                    name={`permissions.${index}.role`}
                    className="form-control"
                >
                    <option value="edit">{i18n.t('share_edit')}</option>
                    <option value="show">{i18n.t('share_show')}</option>
                </Field>
                <span
                    className="fa fa-remove remove-row pseudoLink"
                    onClick={() => remove(index)}
                    title={i18n.t('delete')}
                />
            </div>
        </div>
    )
};

export default Permission
