import * as React from "react";
import { i18n } from "../../config/i18n";
import {ISummary, ISummaryTableRowOptions} from "../../interfaces";
import SummaryTableRow from "./SummaryTableRow";

interface Props {
    summary: ISummary,
    summary2: ISummary,
    values: string[],
    titles_by_value: {[key: string] : string},
    options_by_value: {[key: string] : ISummaryTableRowOptions}
    disableHeader: boolean
}

export const InnerSummaryTable: React.FunctionComponent<Props> = (props) => {
    const {summary, summary2, values, titles_by_value, options_by_value, disableHeader} = props;

    return <>
        {summary2 && !disableHeader &&
            <tr>
                <th></th>
                <th className="text-end">{summary.name}</th>
                <th className="text-end">{summary2.name}</th>
                <th className="text-end">{i18n.t("diff")}</th>
            </tr>
        }
        {values.map(value => <SummaryTableRow
            key={value}
            summary={summary}
            summary2={summary2}
            title={titles_by_value[value]}
            options={options_by_value[value]}
            value={value}
        />)}
    </>;

}

export const SummaryTable: React.FunctionComponent<Props> = (props) => {
    return (
        <table className="table mb-0" style={{marginTop: "-1px"}}>
            <tbody>
            <InnerSummaryTable {...props}/>
            </tbody>
        </table>
    );
}

export default SummaryTable
