import * as React from 'react';
import { i18n } from "../config/i18n";
import classnames from 'classnames';

interface Props {
    name: string;
    label?: string;
    inputWrapperClassName?: string;
    grid?: { label: number, input: number };
}

export const FieldWrapper: React.FunctionComponent<Props> =
    ({name, grid, label, inputWrapperClassName, children}) => {
        return (
            <div className="row">
                {grid && grid.label !== 0 && <div className={`col-sm-${grid.label || 6}`}>
                  <label htmlFor={name}>{label || i18n.t(name)}</label>
                </div>}
                <div
                    className={classnames(
                        `text-start col-sm-${grid && grid.input || 6}`,
                        inputWrapperClassName
                    )}
                >
                    {children}
                </div>
            </div>
        );
    };

export default FieldWrapper;
