import * as React from 'react';

const AnalyseForm = React.lazy(() => import('../forms/AnalyseForm'));
const ProposalsForm = React.lazy(() => import('../forms/ProposalsForm'));
const PredictionsForm = React.lazy(() => import('../forms/PredictionsForm'));
const CaseGrouperForm = React.lazy(() => import('../forms/CaseGrouperForm'));
const TabMenu = React.lazy(() => import('../components/TabMenu'));
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {LocaleProvider} from '../contexts/LocaleContext';
import Spinner from '../components/Spinner';
import { i18n } from "../config/i18n";
import {ToastContainer} from 'react-toastify';
import {UserProvider} from "../contexts/UserContext";
import {IDrgSystem, IUser, IYearCatalogs} from "../interfaces";
import {IDrugsData} from "../components/patient-case/Drug";
import {getUrlParam} from "../utils/UrlUtils";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";

interface Props {
    id: number,
    locale: string,
    pageName: string,
    caseNumber?: string,
    links?: {[Identifier: string] : string},
    drugsData?: IDrugsData,
    user?: IUser,
    hideMenu?: boolean,
    systems: IDrgSystem[],
    currentSystem: IDrgSystem,
    catalogs: IYearCatalogs
}

const Analyse: React.FunctionComponent<Props> = ({
                                                     id,
                                                     pageName,
                                                     caseNumber,
                                                     links,
                                                     user,
                                                     locale,
                                                     hideMenu,
                                                     systems,
                                                     currentSystem,
                                                     catalogs
                                                 }) => {
    return (
        <>
            <Router>
                <Route
                    path='/'
                    render={({location, history}) => {
                        const urlEncodedPc = getUrlParam('pc', location.search);
                        const codingState = getUrlParam('coding_state', location.search);
                        i18n.locale = locale;

                        return (
                            <LocaleProvider value={locale}>
                                <YearCatalogsProvider value={catalogs}>
                                    <UserProvider value={user}>
                                        <>
                                            {!hideMenu &&
                                                <React.Suspense fallback={<Spinner/>}>
                                                    <TabMenu
                                                        urlEncodedPc={urlEncodedPc}
                                                        active={pageName}
                                                        caseNumber={caseNumber}
                                                        id={id}
                                                        links={links}
                                                    />
                                                </React.Suspense>}
                                            {pageName === 'analyze' &&
                                                <React.Suspense fallback={<Spinner/>}>
                                                    <AnalyseForm
                                                        urlEncodedPc={urlEncodedPc}
                                                        history={history}
                                                        id={id}
                                                        systems={systems}
                                                        currentSystem={currentSystem}
                                                    />
                                                </React.Suspense>}
                                            {pageName === 'proposals' && <React.Suspense fallback={<Spinner/>}>
                                                <ProposalsForm
                                                    urlEncodedPc={urlEncodedPc}
                                                    history={history}
                                                    id={id}
                                                    systems={systems.filter(s => s.hasModel)}
                                                    currentSystem={currentSystem}
                                                />
                                            </React.Suspense>}
                                            {pageName === 'case_grouper' && <React.Suspense fallback={<Spinner/>}>
                                                <CaseGrouperForm
                                                    urlEncodedPc={urlEncodedPc}
                                                    history={history}
                                                    id={id}
                                                    systems={systems}
                                                    currentSystem={currentSystem}
                                                />
                                            </React.Suspense>}
                                            {pageName === 'predictions' && <React.Suspense fallback={<Spinner/>}>
                                                <PredictionsForm
                                                    urlEncodedPc={urlEncodedPc}
                                                    codingState={codingState}
                                                    history={history}
                                                    id={id}
                                                    caseNumber={caseNumber}
                                                    systems={systems.filter(s => s.hasModel)}
                                                    currentSystem={currentSystem}
                                                />
                                            </React.Suspense>}
                                        </>
                                    </UserProvider>
                                </YearCatalogsProvider>
                            </LocaleProvider>
                        )
                    }}/>
            </Router>
            <ToastContainer hideProgressBar={true} icon={false}/>
        </>
    )
}

export default Analyse
