import {FieldProps} from 'formik'
import * as React from 'react';
import Select, {Option, ReactSelectProps, createFilter, components, MenuListComponentProps, OptionType} from 'react-select'
import SimpleValue from 'react-select-simple-value';

const SearchableSelectField: React.FunctionComponent<ReactSelectProps & FieldProps> = props => {
    const {options, field, form, onChange} = props;
    return (
        <SimpleValue options={options} value={field.value}>
            {simpleProps =>
                <Select
                    {...props}
                    {...simpleProps}
                    classNamePrefix="custom_select"
                    name={field.name}
                    onChange={(option: Option) => {
                        form.setFieldValue(field.name, option.value, true);
                        if (onChange) {
                            onChange(field.name, option.value);
                        }
                    }}
                    onBlur={field.onBlur}
                    filterOption={createFilter({ignoreAccents: false})}
                    components={{MenuList}}
                />}
        </SimpleValue>
    )
};

function MenuList(props: MenuListComponentProps<OptionType>) {
    const children = React.useMemo(() => React.Children.toArray(props.children), [props.children]);
    return (
        <components.MenuList {...props}>
            {children.length > 200 ? children.slice(0, 200) : props.children}
        </components.MenuList>
    );
}

export default SearchableSelectField
