import * as React from 'react';
import { i18n } from "../../config/i18n";;
import {IInteractiveSummaryProps} from "./Summary"
import {SummaryService} from "../../services/SummaryService";
import Select from 'react-select'
import {drawChart} from "../../utils/chartsUtils";
import _ from "lodash";
import Spinner from '../Spinner';
import {MouseEventHandler, useContext, useEffect, useState} from "react";
import {LocaleContext} from "../../contexts/LocaleContext";
import {UserContext} from "../../contexts/UserContext";
import {IUser} from "../../interfaces";
import {getUrlParam} from "../../utils/UrlUtils";
import { useVariables } from '../../contexts/VariableContext';
import { ExistingFilterContext } from '../../contexts/ExistingFilterContext';
import MCLEditor from '../medcodelogic/MCLEditor';
import MCLEditorErrorBoundary from '../medcodelogic/MCLEditorErrorBoundary';

interface Props {
    isAdmin: boolean,
    interactiveProps?: IInteractiveSummaryProps,
    hideScatterplot: MouseEventHandler
}

/**
 * interactive scatter plot with two numerical dimensions:
 */
const ScatterPlot: React.FunctionComponent<Props> = (props) => {
    const encodedParams = getUrlParam('scatter', location.search);
    const [initMaxValue, initXVariable, initYVariable, initIsMclVariable, initIsMclYVariable, initLogicSrcVariable
        , initLogicSrcYVariable] = encodedParams ? encodedParams.split('~') : ['0', null, null, null, null, '', '']

    const [resultA, setResultA] = useState<number[][]>(undefined)
    const [resultB, setResultB] = useState<number[][]>(undefined)
    const [numCasesA, setNumCasesA] = useState<number>(undefined)
    const [numCasesB, setNumCasesB] = useState<number>(undefined)
    const [maxValue, setMaxValue] = useState<number>(parseInt(initMaxValue))
    const [xVariable, setXVariable] = useState<string>(initXVariable || 'los')
    const [yVariable, setYVariable] = useState<string>(initYVariable || 'total_costs')
    // not yet sure if this should rather be a ref
    const [reload, setReload] = useState<boolean>(true)
    const doReload = () => setReload(true)
    const [isMclVariable, setIsMclVariable] = useState<boolean>(initIsMclVariable === "true")
    const [isMclYVariable, setIsMclYVariable] = useState<boolean>(initIsMclYVariable === "true")
    const [logicSrcVariable, setLogicSrcVariable] = useState<string>(decodeURIComponent(initLogicSrcVariable.replace(/\+/g, ' ')) || '')
    const [logicSrcYVariable, setLogicSrcYVariable] = useState<string>(decodeURIComponent(initLogicSrcYVariable.replace(/\+/g, ' ')) || '')
    const [errorMessage, setErrorMessage] = useState<string>(null)
    const locale = useContext<string>(LocaleContext)
    const [bivKeyFigures, setBivKeyFigures] = useState<number>(undefined)

    const {interactiveProps, hideScatterplot} = props;

    const user = useContext<IUser>(UserContext)
    const {variables, variableOptions} = useVariables();
    const existingFilter = useContext(ExistingFilterContext);

    useEffect(() => {
        if(reload)
            loadDataPoints()
    }, [reload]);

    React.useEffect(() => {
        setErrorMessage(null)
    }, [logicSrcVariable, logicSrcYVariable]);

    /** load data points from backend. each point is a patient case. */
    function loadDataPoints() {
        const finalXVariable = isMclVariable ? logicSrcVariable : xVariable
        const finalYVariable = isMclYVariable ? logicSrcYVariable : yVariable
        SummaryService.dataPoints(finalXVariable, finalYVariable, isMclVariable, isMclYVariable, interactiveProps)
            .then(response => (response.status != 200 ?
                { error_message: response.data ? ('Server error: ' + response.data.substring(0, 50)) : 'empty' } : response.data))
            .then((data) => {
                if(data['error_message'] == 'empty') {
                    return;// probably just cancelled, do nothing
                }
                try {
                    // add descriptions to translations
                    i18n.translations[i18n.locale] = {...i18n.translations[i18n.locale], ...data['descriptions']}
                    const valueA = data['A']
                    const valueB = data['B']
                    // set maxValue
                    let maxV
                    if (valueA.length === 0) {
                        maxV = Math.max(...valueB.map(e => e[0])) + 1
                    } else {
                        maxV = Math.max(...valueA.map(e => e[0])) + 1
                    }
                    setResultA(valueA)
                    setResultB(valueB)
                    setNumCasesA(data['num_cases_A'])
                    setNumCasesB(data['num_cases_B'])
                    setMaxValue(maxV)
                    setErrorMessage(null)
                    setReload(false)
                    setBivKeyFigures(data['biv_key_figures'])
                } catch(e) {
                    setResultA(undefined)
                    setResultB(undefined)
                    setBivKeyFigures(data['biv_key_figures'])
                    setMaxValue(0)
                    setErrorMessage(data['error_message'])
                    setReload(false)
                }
                setReload(false)
            });
    }

    function updateURL() {
        // @ts-ignore
        const url = new window.URL(document.location);
        const encodedParams = [maxValue, xVariable, yVariable, isMclVariable, isMclYVariable, logicSrcVariable
            , logicSrcYVariable].join("~")
        url.searchParams.set('scatter', encodedParams);
        history.replaceState(null, null, url.toString());
    }

    /* set xVariable. */
    function updateVariable(value) {
        setXVariable(value)
        setIsMclVariable(value === 'dynamic')
        setResultA(undefined)
        setResultB(undefined)
        setMaxValue(0)
        doReload()
        setBivKeyFigures(undefined)
        return false
    }

    function updateMclVariable(value){
        setLogicSrcVariable(value)
        setResultA(undefined)
        setResultB(undefined)
        setBivKeyFigures(undefined)
    }

    function updateYMclVariable(value){
        setLogicSrcYVariable(value)
        setResultA(undefined)
        setResultB(undefined)
        setBivKeyFigures(undefined)
    }

    function updateYVariable(value) {
        setYVariable(value)
        setIsMclYVariable(value === 'dynamic')
        setResultA(undefined)
        setResultB(undefined)
        setBivKeyFigures(undefined)
        setMaxValue(0)
        doReload()
        return false
    }

    function updateMaxValue(value) {
        setMaxValue(value)
        return false
    }

    function roundBivKeyFigure(bivKeyFigures, keyFigure) {
        return typeof bivKeyFigures[keyFigure] === 'number' ? bivKeyFigures[keyFigure].toFixed(2) :
            bivKeyFigures[keyFigure]
    }

    const finalXVariable = isMclVariable ? logicSrcVariable : xVariable
    const finalYVariable = isMclYVariable ? logicSrcYVariable : yVariable
    const elementPrefix = finalXVariable.replace(/\W/g,"")

    // do nothing before variables are loaded
    if (variables === undefined || variableOptions === undefined)
        return <></>

    // variable options
    const options = variableOptions.map(group => ({
        label: group.label,
        options: group.options.map(option => ({
                value: option.value,
                label: option.value + ': ' + option.label,
                variableType: _.get(variables[option.value], 'variable_type', '')
            }
        ))
    }))
    // filter variable options
    const numericalOptions = options.map(group => Object.assign({}, group, {
        options:
            group.options.filter(o => o.variableType === 'number')
    }))

    numericalOptions.unshift({
        label: 'dynamic',
        options: [{value: 'dynamic', label: i18n.t('mcl_variable'), variableType: 'number'}]
    })

    const defaultLabel = xVariable === 'dynamic' ?  i18n.t('mcl_variable') : (variables[xVariable] ? variables[xVariable]['name_' + locale] : '')
    const defaultLabelY = yVariable === 'dynamic' ?  i18n.t('mcl_variable') : (variables[yVariable] ? variables[yVariable]['name_' + locale] : '')

    updateURL()

    if (resultA != undefined) {
        const resA = {numberOfPatientcases: numCasesA}
        const resB = resultB ? {numberOfPatientcases: numCasesB} : undefined

        resA[xVariable] = resultA.filter(e => e[0] < maxValue)
        if (resB)
            resB[xVariable] = resultB.filter(e => e[0] < maxValue)

        const minValueX = Math.min(...([...resA[xVariable].map(e => e[0]),
            ...(resB ? resB[xVariable].map(e => e[0]) : [])]))
        const minValueY = Math.min(...([...resA[xVariable].map(e => e[1]),
            ...(resB ? resB[xVariable].map(e => e[1]) : [])]))

        const hAxis = [maxValue, finalXVariable, minValueX < 0 ? minValueX : 0]
        const vAxis = [0, finalYVariable, minValueY < 0 ? minValueY : 0]

        // the chart axes are switched for categorical variables.
        const chartOptions = {
            name: finalXVariable,
            dataName: xVariable,
            valueVariableName: finalYVariable,
            types: ['number', 'number'],
            hAxis: hAxis,
            vAxis: vAxis,
            hasMcl: isMclVariable || isMclYVariable,
            height: undefined,
            elementNameSuffix: '_scatterplot_div', // avoid conflicts with '_chart_div'
            series: {
                0: {
                    visibleInLegend: false
                },
                1: {
                    visibleInLegend: false
                }
            },
            trendlines: {
                0: {
                    type: 'linear',
                    visibleInLegend: true
                },
                1: {
                    type: 'linear',
                    visibleInLegend: true
                }
            }
        }
        // @ts-ignore
        google.charts.setOnLoadCallback(function () {
            drawChart(resA, resB, chartOptions, 'scatter', _, props.isAdmin, _, _, existingFilter);
        });
    }

    return (
        <div className="card">
            <div className="card-header">
                <strong>
                    {i18n.t('scatter_plot')}
                </strong>
                <span
                    className="fa fa-window-minimize fa-lg pull-right pseudoLink mt-1"
                    onClick={hideScatterplot}
                    title={i18n.t('minimize')}
                />
            </div>
            <div className="card-body p-0">
                <div className="row mt-2 me-1 ms-1">
                    <div className="col-10">
                        <label>{i18n.t('x_axis')}:</label>
                        <Select
                            options={numericalOptions}
                            defaultValue={{value: xVariable, label: defaultLabel}}
                            onChange={(e) => {
                                updateVariable(e.value)
                            }}
                            isSearchable={true}
                            menuPortalTarget={document.querySelector('body')}
                        />
                        {isMclVariable &&
                            <div className="col-lg-12 form-control mt-2">
                                <MCLEditorErrorBoundary editor={
                                    <MCLEditor logicSrc={logicSrcVariable || ''}
                                        setLogicSrc={updateMclVariable}
                                        onEnter={doReload}
                                        miniVersion={true} />
                                } />
                            </div>   
                        }
                    </div>
                    <div className="col-2">
                        <label>{i18n.t('max')}</label>
                        <input onChange={(e) => {
                            updateMaxValue(e.target.value)
                        }}
                               value={maxValue}
                               className="form-control"
                               type="number"
                        />
                    </div>
                    <div className="row mt-1">
                        <div className="col-10">
                            <label>{i18n.t('y_axis')}:</label>
                            <Select
                                options={numericalOptions}
                                defaultValue={{label: defaultLabelY, value: yVariable}}
                                onChange={(e) => {
                                    updateYVariable(e.value)
                                }}
                                isSearchable={true}
                                menuPortalTarget={document.querySelector('body')}
                            />
                            {isMclYVariable &&
                                <div className="col-lg-12 form-control mt-2">
                                    <MCLEditorErrorBoundary editor={
                                        <MCLEditor logicSrc={logicSrcYVariable || ''}
                                            setLogicSrc={updateYMclVariable}
                                            onEnter={loadDataPoints}
                                            miniVersion={true} />
                                    } />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {bivKeyFigures ? <div className="row mt-1 me-1 ms-1">
                    <div className="col-12">
                        {bivKeyFigures['cor_B'] ? <>
                            <span className="text-primary me-2">Correlation A: {roundBivKeyFigure(bivKeyFigures, 'cor_A')}</span>
                            <span className="text-warning">Correlation B: {roundBivKeyFigure(bivKeyFigures, 'cor_B')}</span>
                        </> :
                            <span>Correlation: {roundBivKeyFigure(bivKeyFigures, 'cor_A')}</span>}
                    </div>
                </div> :  (reload ? <Spinner/> : '')}
                {errorMessage ?
                    <div className="alert alert-warning m-2">{errorMessage}</div>
                    : <>{resultA ? <>
                            <div id={elementPrefix + "_scatterplot_div"}></div>
                            { user.admin &&
                                <div className="row">
                                    <div className="col-1 ms-auto">
                                        <span className="me-1" id={elementPrefix + "_scatter_png_download"}/>
                                    </div>
                                </div>}
                        </>: (reload ? <Spinner /> : 
                        <div className='col-lg-12 m-2'>&gt;_</div>)}</>
                }
            </div>
        </div>
    );
}

export default ScatterPlot
