import * as React from 'react';
import {LocaleProvider} from '../contexts/LocaleContext';
import {UserProvider} from '../contexts/UserContext';
import { i18n } from "../config/i18n";
import {IUser, IImport} from "../interfaces";
import Spinner from '../components/Spinner';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import Overlay from 'react-bootstrap/Overlay';
import ImportActions from "../components/import/ImportActions";
import {useEffect, useRef, useState} from "react";

interface Props {
    locale: string,
    isAdmin: boolean,
    user: IUser,
    imports: IImport[],
}

const ImportsOverview: React.FunctionComponent<Props> = (props) => {
    const {locale, user, imports} = props;
    const [selected, setSelected] = useState<number[]>([])
    const [showMergeForm, setShowMergeForm] = useState(false);
    const mergeFormTarget = useRef(null);

    i18n.locale = locale;
    useEffect(() => {
        // this avoids having inconsistent selected state vs. selected rows after browser's back action.
        setSelected([])
    }, []);

    const handleConfirmDelete = (e) => {
        if(confirm(i18n.t('are_you_sure'))){
            return true
        } else {
            e.preventDefault();
        }
    }

    const dateFormatter = cell =>  (new Date(Date.parse(cell))).toLocaleDateString("de-CH")
    const numCaseFormatter = cell =>  cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    const booleanFormatter = cell => cell ? i18n.t('true') : i18n.t('false')
    const statusFormatter = (cell, row) => <span className={"text-" + row.status_type} dangerouslySetInnerHTML={{__html: cell}} />
    const numCaseFooter = columnData => numCaseFormatter(columnData.reduce((acc, item) => acc + item, 0))
    const showLinkFormatter = (cell, row) => <a href={row.show_link}>{cell}</a>
    const linkActionFormatter = (cell, row) => <ImportActions _import={row}/>

    const onlyUnique = (value, index, array)=> array.indexOf(value) === index;
    const uniqueValueOptions = attr => imports.map(i => i[attr]).filter(onlyUnique)
        .reduce(function(map, obj) {
            map[obj] = obj;
            return map;
        }, {});

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids)
        } else {
            setSelected([]);
        }
    }

    const columns = [
        {
            dataField: 'name',
            text: i18n.t('description'),
            formatter: showLinkFormatter,
            footer: i18n.t('total')
        },
        {
            dataField: 'num_cases',
            text: i18n.t('num_cases'),
            style: {width: "80px", textAlign: 'right'},
            footerStyle: {textAlign: 'right'},
            sort: true,
            footer: numCaseFooter,
            formatter: numCaseFormatter
        },
        {
            dataField: 'import_date',
            text: i18n.t('import_date'),
            formatter: dateFormatter,
            sort: true,
            footer: ''
        },
        {
            dataField: 'has_costs',
            text: i18n.t('cost_data'),
            formatter: booleanFormatter,
            sort: false,
            footer: '',
            filter: selectFilter({
                options: {true: i18n.t('true'), false: i18n.t('false')},
                placeholder: '⌄'
            })
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: statusFormatter,
            sort: true,
            footer: ''
        },
        {
            dataField: 'drg_version',
            text: "DRG " + i18n.t('version'),
            sort: false,
            footer: '',
            filter: selectFilter({
                options: uniqueValueOptions('drg_version'),
                placeholder: '⌄'
            }),
            style: {minWidth: "100px"},
        },
        {
            dataField: 'data_year',
            text: i18n.t('data_year'),
            sort: false,
            footer: '',
            filter: selectFilter({
                options: uniqueValueOptions('data_year'),
                placeholder: '⌄'
            })
        },
        {
            dataField: 'username',
            text: i18n.t('owner'),
            sort: false,
            footer: '',
            filter: selectFilter({
                options: uniqueValueOptions('username'),
                placeholder: '⌄'
            })
        }]

    if(user.admin) {
        columns.push({
            dataField: 'exportable',
            text: 'exportable',
            formatter: booleanFormatter,
            sort: false,
            footer: '',
            // @ts-ignore
            filter: selectFilter({
                options: {true: i18n.t('true'), false: i18n.t('false')},
                placeholder: '⌄'
            })
        })

        columns.push({
            dataField: 'domain',
            text: i18n.t('domain'),
            sort: false,
            footer: '',
            // @ts-ignore
            filter: selectFilter({
                options: uniqueValueOptions('domain'),
                placeholder: '⌄'
            })
        })
    }
    // @ts-ignore
    columns.push({
        dataField: 'id',
        text: '',
        // @ts-ignore
        style: {width: "100px"},
        sort: false,
        footer: '',
        formatter: linkActionFormatter
    })

    const defaultSorted = [{
        dataField: 'import_date',
        order: 'desc'
    }];

    const { SearchBar } = Search;
    const sizePerPageRenderer = ({
                                     options,
                                     currSizePerPage,
                                     onSizePerPageChange
                                 }) => (
        <div className="btn-group" role="group">
            {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                        <span
                            key={option.text}
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-outline-secondary' : 'btn-outline-info'}`}
                        >
                            {option.text}
                        </span>
                    );
                })
            }
        </div>
    );
    const paginationOptions = {
        alwaysShowAllBtns: true, // Always show next and previous button
        prePageText: i18n.t('back'),
        nextPageText: i18n.t('next'),
        showTotal: true,
        disablePageTitle: true,
        sizePerPageRenderer: sizePerPageRenderer
    };
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectColumnPosition: 'right',
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    return (
        <LocaleProvider value={locale}>
            <UserProvider value={user}>
                <React.Suspense fallback={<Spinner/>}>
                    <ToolkitProvider
                    keyField="id"
                    data={ imports }
                    columns={ columns }
                    search={ {  } }
                >
                    {
                        tkProps => (
                            <div>
                                <SearchBar { ...tkProps.searchProps }
                                           className="pull-right"
                                           placeholder={i18n.t('search')}/>
                                <div className={"float-end dropdown" + (selected.length == 0 ? " disabled" : "")}>
                                    <a aria-expanded="false" aria-haspopup={selected.length > 0}
                                       className="btn btn-primary dropdown-toggle"
                                       data-bs-toggle={selected.length > 0 ? "dropdown" : ""}
                                       id="new_dropdown">
                                        {i18n.t('services_actions')}
                                    </a>
                                    <div aria-labelledby="new_dropdown" className="dropdown-menu">
                                        <a className="dropdown-item" href="#">
                                            <form action={'imports/destroy_many'}
                                                  acceptCharset="UTF-8"
                                                  onSubmit={handleConfirmDelete}
                                                  method="post">
                                                <input type="hidden" name="authenticity_token"
                                                       value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                                                       autoComplete="off"/>
                                                {selected.map(import_id =>
                                                    <input type="hidden" name="import_ids[]"
                                                           key={import_id}
                                                           value={import_id}
                                                           autoComplete="off"/>
                                                )}
                                                <i className="fa fa-trash-o action action-delete"></i>
                                                <input type="submit"
                                                       name="commit"
                                                       value={i18n.t('delete_imports')}
                                                       className="btn btn-text p-1"/>
                                            </form>

                                        </a>
                                        <a className={"dropdown-item" + (selected.length < 2 ? " disabled" : "")}
                                           href="#"
                                           ref={mergeFormTarget} onClick={() => setShowMergeForm(!showMergeForm)}>
                                            <i className="fa fa-object-group action action-edit"></i>
                                            {i18n.t('merge_imports')}
                                        </a>
                                        { selected.length > 1 &&
                                            <Overlay target={mergeFormTarget.current}
                                                     show={showMergeForm}
                                                     placement="top"
                                                     rootClose={true}
                                                     rootCloseEvent="click"
                                                     onHide={() => setShowMergeForm(false)}>
                                                {({
                                                      // eslint-disable-next-line no-unused-vars
                                                      placement: _placement,
                                                      // eslint-disable-next-line no-unused-vars
                                                      arrowProps: _arrowProps,
                                                      // eslint-disable-next-line no-unused-vars
                                                      show: _show,
                                                      // eslint-disable-next-line no-unused-vars
                                                      popper: _popper,
                                                      // eslint-disable-next-line no-unused-vars
                                                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                                      ...props
                                                  }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            position: 'absolute',
                                                            backgroundColor: 'rgb(215,233,255)',
                                                            padding: '2px 10px',
                                                            borderRadius: 3,
                                                            border: "1px solid gray",
                                                            // eslint-disable-next-line react/prop-types
                                                            ...props.style,
                                                        }}
                                                    >
                                                        <form className="form-inline m-sm-2" action={'imports/merge'}
                                                              acceptCharset="UTF-8"
                                                              method="post">
                                                            <input type="hidden" name="authenticity_token"
                                                                   value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                                                                   autoComplete="off"/>
                                                            {selected.map(import_id =>
                                                                <input type="hidden" name="import_ids[]"
                                                                       key={import_id}
                                                                       value={import_id}
                                                                       autoComplete="off"/>
                                                            )}
                                                            <input placeholder={i18n.t('import_copy_name')}
                                                                   className="form-control mb-sm-1" size={40}
                                                                   type="text" name="new_import_name" id="new_import_name"
                                                                   required={true}/>
                                                            <input type="submit"
                                                                   name="commit"
                                                                   value={i18n.t('merge_imports')}
                                                                   className="btn btn-secondary ms-2"/>
                                                        </form>
                                                    </div>
                                                )}
                                            </Overlay>
                                        }
                                    </div>
                                </div>

                                <BootstrapTable
                                    striped hover condensed bordered={ false }
                                    classes="table-responsive"
                                    pagination={ paginationFactory(paginationOptions) }
                                    defaultSorted={ defaultSorted }
                                    filter={ filterFactory() }
                                    headerClasses="align-top table-light"
                                    selectRow={ selectRow }
                                    { ...tkProps.baseProps }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                </React.Suspense>
            </UserProvider>
        </LocaleProvider>
    )

}

export default ImportsOverview
