import * as React from 'react';
import { LocaleProvider } from '../contexts/LocaleContext';
import { UserProvider } from '../contexts/UserContext';
import { IUser, IYearCatalogs } from "../interfaces";
import { YearCatalogsProvider } from "../contexts/YearCatalogsContext";
import FilterCases, { IFilterCasesProps } from '../components/filter/FilterCases';
import { i18n } from '../config/i18n';
import { VariablesProvider } from '../contexts/VariableContext';
import { ExistingFilterProvider } from '../contexts/ExistingFilterContext';
import { MedcodelogicLoader } from '../contexts/MedcodelogicLoaderContext';

interface Props {
    locale: string,
    user?: IUser,
    catalogs: IYearCatalogs,
    filterProps: IFilterCasesProps,
    existingFilter: string
}

const FilterCasesPage: React.FunctionComponent<Props> = (props) => {
    const {locale, filterProps, catalogs, user, existingFilter } = props;
    i18n.locale = locale;

   
    return (
        <LocaleProvider value={locale}>
            <YearCatalogsProvider value={catalogs}>
                <UserProvider value={user}>
                    <VariablesProvider>
                        <MedcodelogicLoader tableScope={props.filterProps.rule.table_scope}>
                            <ExistingFilterProvider value={{ filteredPage: true, filter: existingFilter }}>
                                <FilterCases {...filterProps} />
                            </ExistingFilterProvider>
                        </MedcodelogicLoader>
                    </VariablesProvider>
                </UserProvider>
            </YearCatalogsProvider>
        </LocaleProvider>
    )
}

export default FilterCasesPage
