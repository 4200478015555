import * as React from 'react';
import { i18n } from "../config/i18n";
import {LocaleProvider} from "../contexts/LocaleContext";
import MCLEditor from "../components/medcodelogic/MCLEditor";
import { useState} from "react";
import MCLEditorErrorBoundary from "../components/medcodelogic/MCLEditorErrorBoundary";
import {IYearCatalogs} from "../interfaces";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";
import { VariablesProvider } from '../contexts/VariableContext';
import { MedcodelogicLoader } from '../contexts/MedcodelogicLoaderContext';


interface Props {
    logicSrc: string,
    tableScope: string,
    locale: string,
    elementId: string,
    elementName: string,
    year: string,
    catalogs: IYearCatalogs
}

/**
 * stand alone logic editor that is basically just a field for logic_src and can be used in Rails forms by setting the
 * name / id of the respective text field
 * @param locale
 * @param rule
 * @constructor
 */
const LogicEditor: React.FunctionComponent<Props> = props => {
    const {logicSrc, tableScope, locale, elementId, elementName, catalogs} = props
    const [currentLogicSrc, setLogicSrc] = useState<string>(logicSrc);

    i18n.locale = locale;
    return (
        <LocaleProvider value={locale}>
            <YearCatalogsProvider value={catalogs}>
                <VariablesProvider>
                    <MedcodelogicLoader tableScope={tableScope}>
                        <div className="col-lg-12 form-control">
                            <MCLEditorErrorBoundary editor={
                                <MCLEditor logicSrc={logicSrc}
                                    setLogicSrc={setLogicSrc} />
                            } />
                        </div>
                    </MedcodelogicLoader>
                    <textarea hidden={true} id={elementId} name={elementName} value={currentLogicSrc} readOnly={true} />
                </VariablesProvider>
            </YearCatalogsProvider>
        </LocaleProvider>
    )
}

export default LogicEditor
