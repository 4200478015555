import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { i18n } from "../config/i18n";
import {useContext} from "react";
import {LocaleContext} from "../contexts/LocaleContext";
import {Formik, Form, Field} from "formik";
import SearchableSelectField from "../form-fields/SearchableSelectField";
import {GroupResultComparisonService} from "../services/GroupResultComparisonService";
import {IGroupResultComparison} from "../interfaces";
import TagsArray from "../form-fields/TagsArray";
import {toast} from "react-toastify";
interface Props {
    groupResultComparison: IGroupResultComparison
    // value: id of group result, label: long name
    baseGroupResultsArray?: { value: number, label: string }[]
    // Not the same as base array bcs of filtering when coming from pcs.
    newGroupResultsArray?: { value: number, label: string }[]
    // id of selected pcs (available if coming from pcs tabs)
    pcsId?: number
    backToPcs?: boolean
}

const GroupResultComparisonForm: React.FunctionComponent<Props> = ({
                                                                       groupResultComparison,
                                                                       baseGroupResultsArray,
                                                                       newGroupResultsArray,
                                                                       pcsId,
                                                                       backToPcs
                                                                   })  => {
    const locale = useContext(LocaleContext)
    i18n.locale = locale;

    function createOrUpdate(service) {
        service.then(response => {
            if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                if (response.status === 400) {
                    response.data.error.map(e => toast(e, {type: "error"}))
                } else {
                    toast('Something went wrong', {type: 'error'});
                }
            }
            return response;
        })
            .then(response => response.data)
            .then((data) => {
                if (data && data.new_comparison_link) {
                    window.location.replace(data.new_comparison_link)
                }
            });
    }
    
    function saveComparison(values) {
        // locale, baseGroupResultId, newGroupResultId, description, tags, pcsID
        if (groupResultComparison.id) {
            createOrUpdate(
                GroupResultComparisonService.update(
                    values.description,
                    values.tags,
                    pcsId,
                    groupResultComparison.id,
                    backToPcs))
        } else {
            createOrUpdate(
                GroupResultComparisonService.create(
                    values.name,
                    values.groupResultAId,
                    values.groupResultBId,
                    values.description,
                    values.tags.filter(tag => tag.length > 0),
                    pcsId,
                    backToPcs))
        }
    }

    return (
        <Formik
            initialValues={groupResultComparison}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(values, {setSubmitting}) => {
                saveComparison(values);
                setSubmitting(false);
            }}
        >
            {({isSubmitting, values}) => (
                <Form autoComplete="off">
                    {!groupResultComparison.id &&
                    <div className="row vertical-spacer">
                        <div className="row vertical-spacer" id="comparison_name">
                            <div className="col-lg-6 pr-0">
                                <label htmlFor="name">{i18n.t('name')}</label>
                                <br/>
                                <Field
                                    className={'form-control'}
                                    name={"name"}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 pr-0">
                            <label htmlFor="groupResultAId">{i18n.t('group_result') + " A"}</label>
                            <Field
                                name="groupResultAId"
                                component={SearchableSelectField}
                                options={baseGroupResultsArray}
                                id={"base_group_result"}
                            />
                        </div>
                        <div className="col-lg-6 pr-0">
                            <label htmlFor="groupResultBId">{i18n.t('group_result') + " B"}</label>
                            <Field
                                name="groupResultBId"
                                component={SearchableSelectField}
                                options={newGroupResultsArray}
                                id={"new_group_result"}
                            />
                        </div>
                    </div>}
                    <div className="row vertical-spacer" id="comparison_description">
                        <div className="col-lg-6 pr-0">
                            <label htmlFor="description">{i18n.t('description')}</label>
                            <br/>
                                    <Field
                                        className={'form-control'}
                                        name={"description"}
                                    />
                        </div>
                    </div>
                    <div className="row vertical-spacer" id="comparison_tags">
                        <Field
                            component={TagsArray}
                            tags={values.tags}
                        />
                    </div>
                    <button
                        type={"submit"}
                        disabled={isSubmitting}
                        className={"btn btn-primary"}
                        style={{marginRight: '2px'}}
                    >
                        {i18n.t('save')}
                    </button>
                    {!groupResultComparison.id &&
                    <button
                        onClick={() => window.history.go(-1)}
                        className={'btn btn-outline-secondary'}
                        type={"button"}
                    >
                        {i18n.t('cancel')}
                    </button>}
                </Form>
            )}
        </Formik>
    );
}

export default  GroupResultComparisonForm;


