import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import { Form, Formik } from 'formik';
import { UsersService } from '../services/UsersService';
import { ShareService } from '../services/ShareService';
import { toast } from "react-toastify";
import { i18n } from "../config/i18n";
import Spinner from "../components/Spinner";
import * as Yup from 'yup';
import Permissions from "../components/share/Permissions";
import {LocaleContext} from "../contexts/LocaleContext";

interface Props {
    model: string,
    ownerDomainId: string,
    recordId: string,
    permissions: {userId: string, role: string}[],
    toggleModal: Function,
    setPermissions: Function,
}

const ShareForm: React.FunctionComponent<Props> = ({
                                                       model,
                                                       recordId,
                                                       permissions,
                                                       toggleModal,
                                                       ownerDomainId,
                                                       setPermissions
                                                   }) => {
    const locale = useContext(LocaleContext)
    i18n.locale = locale;
    const [users, setUsers] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [userIdsFromPermissions, setUserIdsFromPermissions] = useState(permissions.map(p => p.userId))

    useEffect(() => {
        UsersService.get(userIdsFromPermissions, "").then(response => {
            setUsers(response.data);
        })
    }, []);

    useEffect( () => {
        setUserIdsFromPermissions(permissions.map(p => p.userId))
        }, [permissions]
    )
    if (!users) {
        return (<Spinner/>)
    }

    const initialValues = {
        permissions: permissions.map((p) => ({ userId: p.userId, role: p.role }))
    };

    const schema = Yup.object().shape({
        permissions: Yup.array()
            .of(
                Yup.object().shape({
                    userId: Yup.string().required('Required'),
                    role: Yup.string().required('Required'),
                })
            )
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                if (submitDisabled) {
                    return
                }
                setSubmitDisabled(true)
                ShareService.share(recordId, model, values.permissions)
                    .then(response => {
                        if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                            setSubmitDisabled(false)
                            toast('Something went wrong', { type: 'error' });
                        }
                        return response;
                    })
                    .then(response => response.data)
                    .then((data) => {
                        setPermissions(data.permissions)
                        setSubmitDisabled(false)
                        toggleModal()
                        toast(i18n.t('successfully_saved_permissions'), { type: 'success' });
                    });
            }}
            validationSchema={schema}
        >
            {({ values }) => (
                <Form>
                    <Permissions
                        ownerDomainId={ownerDomainId}
                        users={users}
                        values={values}
                        setUsers={setUsers}
                        userIdsFromPermissions={userIdsFromPermissions}
                    />
                    <input
                        disabled={submitDisabled}
                        type="submit"
                        name="commit"
                        value={i18n.t('save')}
                        className="btn btn-primary float-end"
                    />
                </Form>
            )}
        </Formik>
    )
}

export default ShareForm
