import * as React from 'react';
import Autocomplete from './Autocomplete';
import {ProceduresService} from '../services/ProceduresService';


interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    submitForm: Function;
}

const ProcedureAutocomplete: React.FunctionComponent<Props> = props => {

    return (
        <Autocomplete {...props} service={ProceduresService}/>
    );
};

export default ProcedureAutocomplete;
