import * as React from 'react';
import { i18n } from "../../config/i18n";

interface Props {
    medicalLandscape: { [key: string]: any }
}


const MedicalLandscape: React.FunctionComponent<Props> = (props) => {
    function returnField(field, medicalLandscape) {
        {if (['numberOfPatientcases', 'meanCosts'].includes(field)) {
            // Use Math.round(Number.parseFloat) instead of only Number.parseInt, since this would always round down.
            return field + ": " + Math.round(Number.parseFloat(medicalLandscape['drgSpec'][field]))}
        else {
            return field + ": " + Number.parseFloat(medicalLandscape['drgSpec'][field]).toFixed(2)}
        }
    }

    const {medicalLandscape} = props;
    return (
        <div className="row">
            <div className="col-lg-12">
                <h2>
                    {i18n.t('numcases_estimated_based_on_datenspiegel')}
                </h2>
                <ul>{
                    ['numberOfPatientcases', 'meanCosts', 'pctDrugsAndBlood', 'pctImplantsAndMedMaterial',
                        'pctDoctor', 'pctCare', 'pctHotel', 'pctAnk', 'pctOther'].map(field =>
                        <li key={field}>
                            {returnField(field, medicalLandscape)}
                        </li>
                    )
                }
                </ul>
                {"(Datenspiegel " + medicalLandscape['drgVersion'] + " Katalogdaten aus dem Jahr " +
                    medicalLandscape['catalogYear'] + ")"}
            </div>
        </div>
    )
}

export default MedicalLandscape
