import * as React from 'react';
import {useState} from 'react';
import { i18n } from "../config/i18n";
import Modal from 'react-bootstrap/Modal';
import ShareForm from "../forms/ShareForm";
import {ToastContainer} from "react-toastify";
import {LocaleProvider} from "../contexts/LocaleContext";

interface Props {
    model: string,
    recordId: string,
    permissions: {userId: string, role: string}[],
    ownerDomainId: string,
    locale: string
}

const Share: React.FunctionComponent<Props> = ({
                                                   locale,
                                                   model,
                                                   recordId,
                                                   ownerDomainId,
                                                   ...props
                                               }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [permissions, setPermissions] = useState(props.permissions)

    const toggleModal = () => {
        setModalOpen((prevState => (!prevState)))
    }

    i18n.locale = locale;
    return (
        <LocaleProvider value={locale}>
            <div className="icon" style={{display: 'inline-block'}} title={i18n.t('share_permit')}>
                <a onClick={toggleModal} href={void (0)} className="pseudoLink">
                    <i className="fa fa-user-plus action action-edit"/>
                </a>
                <Modal
                    dialogClassName="modal-lg"
                    show={modalOpen}
                    aria-labelledby="ModalHeader"
                    onHide={toggleModal}
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>{i18n.t('share_permit')}</Modal.Title>
                        <button
                            type="button" className="btn-close" aria-label="Close"
                            onClick={toggleModal}>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <ShareForm
                            model={model}
                            setPermissions={setPermissions}
                            recordId={recordId}
                            permissions={permissions}
                            toggleModal={toggleModal}
                            ownerDomainId={ownerDomainId}
                        />
                    </Modal.Body>
                </Modal>
                <ToastContainer hideProgressBar={true} position={"top-right"} className={"position-fixed"}
                                style={{fontSize: "1rem"}}/>
            </div>
        </LocaleProvider>

    )
}

export default Share
