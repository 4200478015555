import * as React from 'react';
import { i18n } from "../../config/i18n";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import _ from 'lodash'
import {useContext, useRef} from "react";
import { IInteractiveSummaryProps } from '../summary-of-cases/Summary';
import { ExistingFilterContext } from '../../contexts/ExistingFilterContext';

interface Props {
    title: string,
    setA: {
        data: {[index: number]: (string | number)}[],
        numberOfPatientcases: number
    },
    setB?: {
        data: {[index: number]: (string | number)}[],
        numberOfPatientcases: number
    },
    codeInfos: [string, [string[], string][]][],
    summaryName1: string,
    summaryName2: string,
    hasTariff?: boolean,
    numCasesTitle?: string,
    interactiveProps: IInteractiveSummaryProps
}

const CodesTable: React.FunctionComponent<Props> = props => {
    const table = useRef(null);
    const {filteredPage} = useContext(ExistingFilterContext);

    function percentage(actual, total) {
        return parseFloat(actual) * 100 / total
    }

    function cellContent(percentage, numCase, stats) {
        return (<span>
            <strong>{percentage.toFixed(2)}%</strong>
            {stats ? stats : ''}
            <br></br>
            {numCase &&
                <small>
                    {numCase}
                </small>}
        </span>)
    }

    function formatCodeCell(codeEntry, description) {
        const codeWithPoints = codeEntry[0]
        const medcodesearchPath = codeEntry[1]
        const ruleFilterParams = codeEntry[2]
        const isValidCodeAndVersion = description && description.length > 0;
    
        return <> {(props.interactiveProps && filteredPage) ?
            <a title={i18n.t('filter_verb')}
               href={props.interactiveProps.baseUrl + '&' + ruleFilterParams}>{codeWithPoints}</a>
            : codeWithPoints}
            {medcodesearchPath && isValidCodeAndVersion &&
                <a href={medcodesearchPath} target={codeWithPoints}>
                    <img className='mcs-icon' title={i18n.t('catalog_info_mcs')}
                         src='/images/medcodesearch-favicon.ico'></img></a>}
        </>
    }

    function confidenceIntervalOfShare(share, n) {
        const correctedShare = share > 1.0 ? 1.0 : share; // this can happen for procedures
        const standardError = Math.sqrt((correctedShare*(1-correctedShare))/n);
        const title = i18n.t('wald_confidence') + ": " + standardError.toFixed(3) + "%";
        const confidenceInterval = "± " + (1.96 * standardError*100.0).toFixed(2) + "%"
        return (
            <>
                <br></br>
                <span title={title} className='text-secondary smaller'>
                    {confidenceInterval}
                </span>
            </>
        )
    }

    function significanceOfDifference(share1, share2, n1, n2) {
        const correctedShare1 = share1 > 1.0 ? 1.0 : share1;
        const correctedShare2 = share2 > 1.0 ? 1.0 : share2;
        let stars = "";
        let title = "";
        // Calculate standard error of difference of two shares (SED), c.f. medizinische statistikbook (4.3) page 87.
        const sed = Math.sqrt(((correctedShare1*(1-correctedShare1))/n1) + ((correctedShare2*(1-correctedShare2))/n2));
        // Calc t-value of the difference of two shares, anlog to the one of two means, c.f. med. stat. book chapter 5.2.
        const zValue = Math.abs((correctedShare1 - correctedShare2) / sed);
        // Convert to significance niveau.
        title = i18n.t('std_error_diff') + ": " + sed.toFixed(3) + ", z: " + zValue.toFixed(3)
        // Set critical z-values for significance levels alpha = 0.95, 0.99 and 0.999.
        // Taking two sided values, since we're interested in the significance of the difference no matter the direction.
        // F.e. for alpha = 0.99 we're using z_krit = z_(1-alpha/2) = z_0.995 = 2.58 and -z_krit = -z_0.995 = -2.55, i.e.
        // abs(z_krit) = 2.58.
        let significance;
        switch (true) {
            case zValue > 3.29:
                significance = i18n.t('highly_significant') + " / > 99.9%" // *** hoch signifikant
                stars = '***'
                break;
            case zValue > 2.58:
                significance = i18n.t('very_significant') + " / > 99%" // ** sehr signifikant
                stars = '**'
                break;
            case zValue > 1.96:
                significance = i18n.t('significant') + " / > 95%" // * signifikant
                stars = '*'
                break;
            default:
                significance = i18n.t('non_significant')
        }
        title += " => " + significance
        return (<span title={title}>{stars}</span>)
    }

    function mapCodes (setA, setB, hasTariff) {
        // map common codes data with tariff from [[code, tariff], numCases], to [code$tariff, numCases]
        const setAData = hasTariff ?
            setA.data.map((e) => [e[0][0] + '$' + e[0][1], e[1]]) : setA.data
        let setBData = [];
        if (setB && setB["data"].length > 0) {
            setBData = hasTariff ? setB.data.map((e) => [e[0][0] + '$' + e[0][1], e[1]]) : setB.data

        }
        const mappedCodesA = {};
        const mappedCodesB = {};
        let mappedCodes = {};

        // A data element is an array of [code,count], f.e. ["L62B", 1].
        if (setAData.length > 0) {
            setAData.forEach((el) => {
                mappedCodesA[el[0]] = el[1]
            })
            if (setBData && setBData.length > 0) {
                setBData.forEach((el) => {
                    mappedCodesB[el[0]] = el[1]
                })
                mappedCodes = {...mappedCodesA, ...mappedCodesB}
                Object.keys(mappedCodes).forEach((key) => {
                    mappedCodes[key] = (mappedCodesA[key] || 0) + (mappedCodesB[key] || 0)
                })
            } else {
                mappedCodes = mappedCodesA
            }
        } else {
            if (setBData && setBData.length > 0) {
                setBData.forEach((el) => {
                    mappedCodesB[el[0]] = el[1]
                })
                mappedCodes = mappedCodesB
            }
        }
        return {mappedCodes, mappedCodesA, mappedCodesB}
    }

    const {codeInfos, title, setA, setB, summaryName1, summaryName2, hasTariff} = props
    const codeInfosMap = Object.fromEntries(codeInfos)

    // Map codes depending on availability.
    const { mappedCodes, mappedCodesA, mappedCodesB } = mapCodes(setA, setB, hasTariff);

    const totalPcs = setA.numberOfPatientcases + (setB ? setB.numberOfPatientcases : 0)
    const upperCaseTitleLinks = {};
    for (const [ key, codeEntry ] of Object.entries(codeInfosMap)) {
        const  code = Array.isArray(key) ? key[0] : key;
        upperCaseTitleLinks[code.toUpperCase()] = [formatCodeCell(codeEntry[0], codeEntry[1]), codeEntry[1]]
    }

    const numCaseFormatter = cell =>  cellContent(cell[0], cell[1], cell[2])
    const numCaseCSVFormatter = cell => cell[1]
    const numCaseFooter = columnData => cellContent(columnData.reduce((acc, item) => acc + item[0], 0),
        columnData.reduce((acc, item) => acc + item[1], 0), null)
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            hidden: true,
            csvExport: false
        },
        {
            dataField: 'code',
            text: i18n.t(title),
            style: {minWidth: '80px'},
            sort: true,
            sortValue: (cell, row) => row.id,
            filterValue: (cell, row) => _.get(codeInfosMap[row.id], 0, [row.id])[0],
            csvFormatter: (cell, row) => _.get(codeInfosMap[row.id], 0, [row.id])[0],
            footer: i18n.t('total')
        },
        {
            dataField: 'description',
            text: i18n.t('description'),
            style: {},
            sort: true,
            footer: ''
        }]
    if(hasTariff){
        // @ts-ignore
        columns.push({
            dataField: 'tariff',
            text: i18n.t('tariff'),
            sort: true,
            footer: ''
        })
    }
    if (setB) {
        columns.push({
            dataField: 'num_cases',
            text: i18n.t('total'),
            // @ts-ignore
            style: {color: 'gray'},
            sort: true,
            formatter: numCaseFormatter,
            sortValue: (cell) => cell[0],
            csvFormatter: numCaseCSVFormatter,
            // @ts-ignore
            footer: numCaseFooter
        })
        columns.push({
            dataField: 'num_cases_a',
            text: '' + summaryName1,
            // @ts-ignore
            style: {color: '#2d92ff'},
            sort: true,
            formatter: numCaseFormatter,
            sortValue: (cell) => cell[0],
            csvFormatter: numCaseCSVFormatter,
            // @ts-ignore
            footer: numCaseFooter
        })
        columns.push({
            dataField: 'num_cases_b',
            text: '' + summaryName2,
            // @ts-ignore
            style: {color: '#c49704'},
            sort: true,
            formatter: numCaseFormatter,
            sortValue: (cell) => cell[0],
            csvFormatter: numCaseCSVFormatter,
            // @ts-ignore
            footer: numCaseFooter
        })
        columns.push({
            dataField: 'diff',
            text: i18n.t('diff'),
            // @ts-ignore
            style: {color: 'black'},
            sort: true,
            formatter: numCaseFormatter,
            sortValue: (cell) => cell[0],
            filterValue: (cell) => cell[2], // so we can filter for significant differences
            csvFormatter: numCaseCSVFormatter,
            // @ts-ignore
            footer: numCaseFooter
        })
    } else {
        columns.push({
            dataField: 'num_cases',
            text: i18n.t(props.numCasesTitle || 'num_cases'),
            // @ts-ignore
            style: {color: 'black', minWidth: '120px'},
            sort: true,
            formatter: numCaseFormatter,
            sortValue: (cell) => cell[0],
            csvFormatter: numCaseCSVFormatter,
            // @ts-ignore
            footer: numCaseFooter
        })
    }

    const defaultSorted = [{
        dataField: 'num_cases',
        order: 'desc'
    }];

    const codesKeys = Object.keys(mappedCodes)
    const codesData = codesKeys.map((key) => {
        // do we have a tariff
        const keyVars = key.split('$')
        const code = keyVars[0]
        const tariff = keyVars[1] || '001'
        const percent = percentage(mappedCodes[key], totalPcs);
        const ci = confidenceIntervalOfShare(percent / 100.0, totalPcs);
        const baseInfo = {
            id: code,
            code: _.get(upperCaseTitleLinks[key.toUpperCase()], 0, ''),
            description: _.get(upperCaseTitleLinks[key.toUpperCase()], 1, '')
        }
        if(hasTariff){
            baseInfo['tariff'] = tariff
        }
        const numCases = [percent, mappedCodes[key], ci]
        let numCasesInfo = {}
        if (setB) {
            const countA = mappedCodesA[key] || 0
            const countB = mappedCodesB ? (mappedCodesB[key] || 0) : 0
            const percentageA = percentage(countA, setA.numberOfPatientcases);
            const percentageB = percentage(countB, setB.numberOfPatientcases);
            const differenceAbsolute = countA - countB;
            const differencePercentage = percentageA - percentageB;
            const significanceOfDiff = significanceOfDifference(percentageA/100.0,
                percentageB/100.0, setA.numberOfPatientcases, setB.numberOfPatientcases);

            numCasesInfo = {
                num_cases: numCases,
                num_cases_a: [percentageA, countA, null],
                num_cases_b: [percentageB, countB, null],
                diff: [differencePercentage, differenceAbsolute, significanceOfDiff]
            }

        } else {
            numCasesInfo = {
                num_cases: numCases
            }
        }
        return {...baseInfo, ...numCasesInfo}

    })
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const sizePerPageRenderer = ({
                                     options,
                                     currSizePerPage,
                                     onSizePerPageChange
                                 }) => (
        <div className="btn-group" role="group">
            {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                        <span
                            key={option.text}
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-outline-secondary' : 'btn-outline-info'}`}
                        >
                            {option.text}
                        </span>
                    );
                })
            }
        </div>
    );
    const paginationOptions = {
        alwaysShowAllBtns: true, // Always show next and previous button
        prePageText: i18n.t('back'),
        nextPageText: i18n.t('next'),
        showTotal: true,
        disablePageTitle: true,
        sizePerPageRenderer: sizePerPageRenderer
    };

    const SortedExportCSVButton = (props: {onExport: Function }) => {
        const handleClickCSVExport = () => {
            // sort before export according to current sort options
            if (table.current.sortContext.state.sortOrder) {
                props.onExport(table.current.paginationContext.props.data);
            } else {
                props.onExport();
            }
        };
        return (
            <ExportCSVButton
                className="btn btn-outline-secondary" { ...props }
                onClick={handleClickCSVExport}>
                <i className="fa fa-download action action-show"></i> CSV
            </ExportCSVButton>
        );
    };

    if (codesKeys.length > 0) {
        return <ToolkitProvider
            keyField="id"
            data={ codesData }
            columns={ columns }
            search={ { searchFormatted: true } }
            exportCSV={ { onlyExportFiltered: true, exportAll: false } }
        >
            {
                tkProps => (
                    <div>
                        <SearchBar { ...tkProps.searchProps } className="pull-right" placeholder={i18n.t('search')}/>
                        <div className="float-end">
                            <SortedExportCSVButton { ...tkProps.csvProps } ></SortedExportCSVButton>
                        </div>
                        <BootstrapTable
                            striped hover condensed bordered={ false }
                            classes="table-responsive"
                            ref={table}
                            pagination={ paginationFactory(paginationOptions) }
                            defaultSorted={ defaultSorted }
                            { ...tkProps.baseProps }
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    } else {
        return <br></br>
    }
}

export default CodesTable
