import * as React from 'react';
import { i18n } from "../../config/i18n";
import {ISummary} from "../../interfaces";
import {drawChart} from "../../utils/chartsUtils";
import { ExistingFilterContext } from '../../contexts/ExistingFilterContext';
import { useContext } from 'react';

interface Props {
    summary: ISummary,
    summary1: ISummary
    summary2: ISummary,
    hasMoreNewborns: boolean
    binSize: number
}

const Charts: React.FunctionComponent<Props> = (props) => {
    const {summary, summary1, summary2} = props;
    const existingFilter = useContext(ExistingFilterContext);

    function chart(name, options, summary1, summary2) {
        const {binSize} = props
        // @ts-ignore
        google.charts.setOnLoadCallback(function () {
            drawChart(summary1, summary2, options, 'column', binSize, null, null, null, existingFilter);
        });
        return (<div className="card">
            <div className="card-header">
                <strong>
                    {i18n.t(name)}
                </strong>
            </div>
            <div className="card-body p-0">
                <div id={options.name + "_chart_div"}></div>
            </div>
        </div>)
    }

    function ageChart() {
        const {hasMoreNewborns, summary1, summary2} = props
        if (hasMoreNewborns) {
            const options = {
                name: "ageDays",
                types: ['number', 'number'],
                hAxis: [0, 'age', 0],
                vAxis: [0, summary2 ? 'percentage' : 'num_cases', 0]
            }
            return chart("age_days", options, summary1, summary2)
        } else {
            const options = {
                    name: "ageYears",
                    types: ['number', 'number'],
                    hAxis: [0, 'age', 0],
                    vAxis: [0, summary2 ? 'percentage' : 'num_cases', 0]
            }
            return chart("age_years", options, summary1, summary2)
        }
    }


    return (
        <div className="row mb-3">
            <div className="col-sm-6">
                {ageChart()}
            </div>
            <div className="col-sm-6">
                {chart('los', {
                    name: "los",
                    types: ['number', 'number'],
                    hAxis: [0, 'num_days', 0, 3 * summary.avgLos],
                    vAxis: [0, summary2 ? 'percentage' : 'num_cases', 0]
                }, summary1, summary2)}
            </div>
        </div>
    )
}

export default Charts
