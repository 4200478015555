import * as React from 'react';
const RulesForm = React.lazy(() => import('../forms/RulesForm'));
import {LocaleProvider} from '../contexts/LocaleContext';
import {UserProvider} from '../contexts/UserContext';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Spinner from '../components/Spinner';
import { i18n } from "../config/i18n";
import {IRule, IUser, IYearCatalogs} from "../interfaces";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";
import { VariablesProvider } from '../contexts/VariableContext';

interface Props {
    rule?: IRule,
    locale: string,
    user?: IUser,
    year_options: string[],
    catalogs: IYearCatalogs
}

const RulesEditor: React.FunctionComponent<Props> = (props) => {
    const {rule, user, locale, year_options, catalogs} = props;
    i18n.locale = locale;

    return (
        <Router>
            <Route
                path='/'
                render={({history}) => {
                    return (
                        <LocaleProvider value={locale}>
                            <YearCatalogsProvider value={catalogs}>
                                <UserProvider value={user}>
                                    <VariablesProvider>
                                        <React.Suspense fallback={<Spinner/>}>
                                            <RulesForm
                                                rule={rule}
                                                yearOptions={year_options}
                                                history={history}
                                            />
                                        </React.Suspense>
                                    </VariablesProvider>
                                </UserProvider>
                            </YearCatalogsProvider>
                        </LocaleProvider>
                    )
                }}/>
        </Router>
    )
}

export default RulesEditor
