import {http} from './web';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const GroupResultsService = {
  update: (id) => http.put(`group_results/${id}`, {}, {
    headers: {
      'X-CSRF-Token': csrfToken
    }
  })
};
