import {create} from 'apisauce';

export const http = create({
  // eslint-disable-next-line no-undef
  baseURL: (apiUrl),
  headers: {Accept: 'application/json'},
});

export const httpRails = create({
  // eslint-disable-next-line no-undef
  baseURL: ('/'),
  headers: {Accept: 'text/html'},
});

export const httpMedcodeSearch = create({
  // eslint-disable-next-line no-undef
  baseURL: (medcodesearchUrl),
  headers: {Accept: 'application/json'},
});
