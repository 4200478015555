import * as React from 'react';
import { CSSProperties, ReactNode } from 'react';

interface Props {
    children: ReactNode,
    type: string
    style?: CSSProperties
}

const Alert: React.FunctionComponent<Props> = (
    {
        children,
        type,
        style
    }) => {
    return (
        <div className={`alert alert-${type}`} style={style}>
            {children}
        </div>
    );
};

export default Alert
