import * as React from 'react';
import {useEffect, useState} from "react";
import {IVariable} from "../interfaces";
import {MedcodelogicService} from "../services/MedcodelogicService";
import {RulesService} from "../services/RulesService";

interface VariableData {
    variables: { [Identifier: string]: IVariable };
    variableOptions: { label: string, options: { label: string, value: string }[] }[];
}

const VariableContext = React.createContext<VariableData>(
    {variables: undefined, variableOptions: undefined});

export const useVariables = (): VariableData => {
    const contextState = React.useContext(VariableContext);
    if (contextState === null) {
        throw new Error('useVariables must be used within a VariablesProvider tag');
    }
    return contextState;
};

export const VariablesProvider: React.FC<{children}> = (props) => {
    const [variables, setVariables] = useState<{[Identifier: string]: IVariable}>(undefined)
    const [variableOptions, setVariableOptions] = useState<{label: string, options: {label: string, value: string}[]}[]>(undefined)

    useEffect(() => {
        loadVariables()
    }, [])

    function loadVariables() {
        MedcodelogicService.getVariables('yes')
            .then(response => response.data)
            .then((data) => {
                setVariables(data);
            });
        RulesService.getVariableOptions()
            .then(response => response.data)
            .then((data) => {
                setVariableOptions(data);
            });
    }

    return (
        <VariableContext.Provider value={{variables: variables, variableOptions: variableOptions}}>
            {props.children}
        </VariableContext.Provider>
    );  
};
