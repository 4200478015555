import * as React from 'react';
import { useState } from 'react';
import { i18n } from "../../config/i18n";
import Spinner from "../Spinner";
import {GroupResultComparisonService} from "../../services/GroupResultComparisonService";

interface Props {
    groupResultComparisonId
}

const RecalculateButton: React.FunctionComponent<Props> = ({ groupResultComparisonId }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onClick = () => {
        setIsSubmitting(true);
        GroupResultComparisonService.recalculate(groupResultComparisonId)
            .then(response => response.data)
            .then(() => {
                setIsSubmitting(true);
            });
    }

    return (
        <button
            disabled={isSubmitting}
            type="button"
            className="btn btn-primary text-white"
            onClick={onClick}
        >
            {i18n.t('regroup')}
            {' '}
            {isSubmitting && <Spinner size={1}/>}
        </button>
    );
};

export default RecalculateButton
