import * as React from 'react';
import {FieldArray} from 'formik';
import Procedure from './Procedure';
import { i18n } from "../../config/i18n";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {IProcedure, IYearCatalogs} from "../../interfaces";
import {useContext} from "react";
import {YearCatalogsContext} from "../../contexts/YearCatalogsContext";

interface Props {
    procedures: IProcedure[];
    setFieldValue: Function;
    submitForm: Function;
    colorMeterEnabled?: boolean;
}

const Procedures: React.FunctionComponent<Props> = props => {
    const {procedures, setFieldValue, submitForm, colorMeterEnabled} = props;
    const catalogs = useContext<IYearCatalogs>(YearCatalogsContext)

    const onDragEnd = (result, move) => {
        const { source, destination } = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        // move code to destination
        move(source.index, destination.index);
        submitForm();
    };

    return (
        <div className="codes_box" id="srg_box">
            <div className="row">
                <div className="col-sm-12">
                    <h4>
                        {i18n.t("procedures")}
                        <small className="text-secondary ms-2" style={{fontSize: '0.5em'}}>
                            {catalogs.chop.replace('_', ' ')}
                        </small>
                    </h4>
                </div>
            </div>
            <div>
                <FieldArray
                    name="procedures"
                    render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => {onDragEnd(result, arrayHelpers.move)}}>
                            <Droppable droppableId="srg-dropzone">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {procedures && procedures.map((procedure, index) => (
                                            <Draggable key={index} draggableId={'srg' + index} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Procedure
                                                            index={index}
                                                            procedure={procedure}
                                                            setFieldValue={setFieldValue}
                                                            submitForm={submitForm}
                                                            colorMeterEnabled={colorMeterEnabled}
                                                            key={index}
                                                            arrayHelpers={arrayHelpers}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        <div className="row pb-2">
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.push({code: "", active: true})}
                                                    className="float-end btn btn-secondary btn-sm pe-2 ps-2"
                                                >
                                                    <i className="fa fa-plus"/>
                                                </button>
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                />
            </div>
        </div>
    );
};

export default Procedures
