import * as React from 'react';
import GroupResultComparisonProgress from "../components/group-result-comparison/GroupResultComparisonProgress";

interface Props {
    locale: string,
    groupResultComparisonId: number
}

const GroupResultComparisonProgressPage: React.FunctionComponent<Props> = (props) => {
    return (<GroupResultComparisonProgress {...props} />)
}

export default GroupResultComparisonProgressPage
