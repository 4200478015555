import * as React from 'react';
import {Field, FormikValues} from 'formik';
import DatePicker from '../../form-fields/DatePicker';
import { i18n } from "../../config/i18n";
import InputField from "../../form-fields/InputField";
import SelectField from "../../form-fields/SelectField";
import {UserContext} from "../../contexts/UserContext";
import {IDemographicFlags, IUser} from "../../interfaces";
import {useContext} from "react";

interface Props {
    setFieldValue: Function,
    submitForm: Function,
    values: FormikValues,
    flags: IDemographicFlags,
    skipLos?: boolean,
    displayCodingState?: boolean,
    originalAge?: number,
}

const AdministrativeData: React.FunctionComponent<Props> = props => {
    const user = useContext<IUser>(UserContext)

    async function handleChangeLos(name, value) {
        const {values, setFieldValue, submitForm} = props;
        const admDate = name === 'adm_date' ? value : values.adm_date;
        const exitDate = name === 'exit_date' ? value : values.exit_date;
        const vacationDays = name === 'vacation_days' ? value : values.vacation_days;
        const diff = Math.round((Date.parse(exitDate) - Date.parse(admDate)) / (24 * 60 * 60 * 1000)) || 1;
        const los = diff - vacationDays;

        setFieldValue(name, value);
        setFieldValue('los', los);
        await Promise.resolve();
        submitForm();
    }

    async function handleSelectChange(name, value) {
        const {setFieldValue, submitForm} = props;
        setFieldValue(name, value);
        await Promise.resolve();
        submitForm();
    }

    const {values, setFieldValue, flags, skipLos, displayCodingState, originalAge} = props;
    const isChecked = (radioValue, storedValue) =>
        radioValue === storedValue;

    return (
        <div className="codes_box adm_box" id="adm_box">
            <div className="row">
                <div className="col-sm-12">
                    <h4>{i18n.t("adm_variables")}</h4>
                    <div className="row">
                        <div className="col-sm-6 px-0">
                            <SelectField
                                name="sex"
                                flags={flags && flags.sexFlag}
                                grid={{label: 6, input: 6}}
                                options={[
                                    {label: i18n.t("unknown"), value: "U"},
                                    {label: i18n.t("male"), value: "M"},
                                    {label: i18n.t("female"), value: "W"},
                                ]}
                                onChange={(e) => handleSelectChange('sex', e.target.value)}

                            />

                            <InputField
                                name="hmv"
                                flags={flags && flags.hmvFlag}
                                grid={{label: 8, input: 4}}
                            />
                        </div>

                        <div className="col-sm-6 px-0">
                            <div className="row pb-2">
                                {[{value: "1", label: i18n.t("age_years")}, {
                                    value: "0",
                                    label: i18n.t("age_days")
                                }].map((option, index) => (
                                    <div className="col-sm-6 pe-0" key={option.value}>
                                        <label key={index} className="my-2 mt-8 cursor-pointer">
                                            <Field name="age_selection">
                                                {({field}) => (
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        name={field.name}
                                                        checked={isChecked(option.value, field.value)}
                                                        onChange={() => {
                                                            field.onChange(field.name)(option.value);
                                                            if (option.value === "1") {
                                                                setFieldValue("age_years", originalAge);
                                                                setFieldValue("age_days", 0);
                                                                setFieldValue("gest_age", 0);
                                                            } else {
                                                                setFieldValue("age_years", 0);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <span className="ps-2">{option.label}</span>
                                        </label>
                                    </div>

                                ))}
                            </div>

                            <InputField
                                name="age_years"
                                disabled={values['age_selection'] === '0'}
                                flags={flags && flags.ageFlag}
                                grid={{label: 7, input: 5}}
                            />

                            <InputField
                                name="age_days"
                                disabled={values['age_selection'] !== '0'}
                                flags={flags && flags.ageFlag}
                                grid={{label: 7, input: 5}}
                            />


                            <InputField
                                name="adm_weight"
                                disabled={values['age_selection'] !== '0'}
                                flags={flags && flags.admWeightFlag}
                                grid={{label: 7, input: 5}}
                            />

                            <InputField
                                name="gest_age"
                                flags={flags && flags.gestAgeFlag}
                                disabled={values['age_selection'] !== '0'}
                                grid={{label: 7, input: 5}}
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">

                    <SelectField
                        onChange={(e) => handleSelectChange('adm_mode', e.target.value)}
                        name="adm_mode"
                        grid={{label: 3, input: 9}}
                        options={[
                            {label: i18n.t("adm11"), value: "11"},
                            {label: i18n.t("adm06"), value: "06"},
                            {label: i18n.t("adm01"), value: "01"},
                            {label: i18n.t("adm99"), value: "99"},
                        ]}
                    />

                    {!skipLos && <SelectField
                      onChange={(e) => handleSelectChange('sep_mode', e.target.value)}
                      name="sep_mode"
                      flags={flags && flags.sepFlag}
                      grid={{label: 3, input: 9}}
                      options={[
                          {label: i18n.t("sep07"), value: "07"},
                          {label: i18n.t("sep06"), value: "06"},
                          {label: i18n.t("sep04"), value: "04"},
                          {label: i18n.t("sep00"), value: "00"},
                          {label: i18n.t("sep99"), value: "99"},
                      ]}
                    />}

                    <div className="row">
                        {!skipLos && <div className="col-sm-6 px-0">
                          <InputField
                            name="los"
                            flags={flags && flags.losFlag}
                            grid={{label: 6, input: 6}}
                            disabled={!!values.exit_date}
                          />
                        </div>}

                        <div className="col-sm-6 px-0">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label htmlFor="q">{i18n.t("adm_date")}</label>
                                </div>
                                <div className="col-sm-6 text-start">
                                    <DatePicker
                                        name="adm_date"
                                        value={values.adm_date}
                                        onChange={handleChangeLos}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm-6 px-0">
                            <InputField
                                name="vacation_days"
                                label={i18n.t("leave_days")}
                                grid={{label: 6, input: 6}}
                                onChange={(e) => handleChangeLos('vacation_days', e.target.value)}
                            />
                        </div>

                        {!skipLos && <div className="col-sm-6 px-0">
                          <div className="row">
                            <div className="col-sm-6">
                              <label style={{wordBreak: 'break-all'}} id="label_exit_date"
                                     htmlFor="q">{i18n.t("exit_date")}</label>
                            </div>
                            <div className="col-sm-6 text-start">
                              <DatePicker
                                name="exit_date"
                                value={values.exit_date}
                                onChange={handleChangeLos}
                              />
                            </div>
                          </div>

                        </div>}
                    </div>
                    {user.admin && displayCodingState && <SelectField
                      onChange={(e) => handleSelectChange('coding_state', e.target.value)}
                      name="coding_state"
                      grid={{label: 3, input: 9}}
                      options={[
                          {label: i18n.t("coding_state_options.post"), value: "post"},
                          {label: i18n.t("coding_state_options.pre"), value: "pre"},
                      ]}
                    />}
                </div>
            </div>
        </div>
    )
}

export default AdministrativeData
