import * as React from 'react';
import {IUserListEntry} from "../../interfaces";
import { i18n } from "../../config/i18n";

interface Props {
    user: IUserListEntry
}

const UserActions: React.FunctionComponent<Props> = ({user}) => {

    return (
        <>
            { user.policy.edit &&
                <div className="icon" title={i18n.t('edit_userdata')} style={{display: 'inline-block'}}>
                    <a href={user.edit_link}>
                        <i className="fa fa-pencil action action-edit" />
                    </a>
                </div>}
            { user.policy.destroy &&
                <div className="icon" title={i18n.t('remove_user')} style={{display: 'inline-block'}}>
                    <a href={user.edit_link.replace('/edit', '')} rel="nofollow" data-method="delete"
                       data-confirm={i18n.t('are_you_sure')}>
                        <i className="fa fa-trash-o action action-delete" />
                    </a>
                </div>}
        </>
    )
}

export default UserActions
