import * as React from 'react';
import { i18n } from "../../config/i18n";
import SelectField from "../../form-fields/SelectField";
import InputField from "../../form-fields/InputField";
import {IDrug} from "../../interfaces";

export interface IDrugsData {
    applications: { [Identifier: string]: string },
    units: { [Identifier: string]: string },
    annexes: { [Identifier: string]: string }
}

interface Props {
    index: number;
    setFieldValue: Function;
    drugs: IDrug[];
    errors: any;
    submitForm: Function;
    drugsData: IDrugsData
}

const Drug: React.FunctionComponent<Props> = props => {
    function validateDose(value) {
        const {index, drugs} = props;
        const drugValues = drugs[index];
        return (drugValues.code && (typeof value == 'undefined' || value.length === 0))
    }

    const {index, setFieldValue, drugs, errors, submitForm, drugsData} = props;
    const drugValues = (drugs || {})[index] || {};
    const doseValid = (((errors || {}).drugs || {})[index] || {}).dose !== true;
    const flags = {used: drugValues.used, valid: drugValues.valid};
    if (!drugsData) {
        return null;
    }
    return (
        <div className="code_input_div row">
            <div className="col-lg-12">
                <div className="input-group">
                    <div className="col-sm-2 px-0">
                        <InputField
                            flags={flags}
                            name={`drugs[${index}].code`}
                            grid={{label: 0, input: 12}}
                            inputWrapperClassName="px-0"
                        />
                    </div>
                    <div className="col-sm-3 px-0">
                        <SelectField
                            prompt={true}
                            options={drugsData.annexes}
                            name={`drugs[${index}].annex`}
                            grid={{label: 0, input: 12}}
                            inputWrapperClassName="px-0"
                        />
                    </div>
                    <div className="col-sm-2 px-0">
                        <SelectField
                            prompt={true}
                            options={drugsData.applications}
                            name={`drugs[${index}].application`}
                            grid={{label: 0, input: 12}}
                            inputWrapperClassName="px-0"
                        />
                    </div>
                    <div className="col-sm-2 px-0">
                        <InputField
                            validate={validateDose}
                            flags={{valid: doseValid, used: false}}
                            name={`drugs[${index}].dose`}
                            grid={{label: 0, input: 12}}
                            inputWrapperClassName="px-0"
                        />
                    </div>
                    <div className="col-sm-2 px-0">
                        <SelectField
                            prompt={true}
                            options={drugsData.units}
                            name={`drugs[${index}].unit`}
                            grid={{label: 0, input: 12}}
                            inputWrapperClassName="px-0"
                        />
                    </div>
                    <div className="col-sm-1 px-0">
                                    <span className="fa fa-remove removetag"
                                          title={i18n.t("remove_code")}
                                          onClick={async () => {
                                              setFieldValue(`drugs[${index}].code`, '');
                                              await Promise.resolve();
                                              submitForm();
                                          }}/>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Drug
