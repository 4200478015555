import * as React from 'react';
import {IImport, IUser} from "../../interfaces";
import { i18n } from "../../config/i18n";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";

interface Props {
    _import: IImport
}

const ImportActions: React.FunctionComponent<Props> = ({_import}) => {
    const user = useContext<IUser>(UserContext)

    return (
        <>
            { _import.policy.show &&
                <>
                <div className="icon" title={i18n.t('case_analysis')} style={{display: 'inline-block'}}>
                    <a href={_import.show_link + '/outliers'}>
                        <i className="fa fa-list action action-show" />
                    </a>
                </div>
                <div className="icon" title={i18n.t('summarize')} style={{display: 'inline-block'}}>
                    <a href={_import.show_link + '/summarize'}>
                        <i className="fa fa-pie-chart action action-edit" />
                    </a>
                </div>
                <div className="icon" title={i18n.t('copy')} style={{display: 'inline-block'}}>
                    <a className="pseudoLink"
                       id="new_dropdown"
                       aria-expanded={false}
                       data-bs-toggle='dropdown'
                       aria-haspopup={true}>
                        <i className="fa fa-copy action action-edit" />
                    </a>
                    <div className="dropdown-menu" aria-labelledby='new_dropdown' style={{width: '400px !important'}}>
                        <form className="form-inline m-sm-2" action={_import.show_link + '/copy'}
                              acceptCharset="UTF-8"
                              method="post">
                            <input type="hidden" name="authenticity_token"
                                   value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                                   autoComplete="off" />
                            <input placeholder={i18n.t('import_copy_name')} className="form-control mb-sm-1" size={40}
                                   type="text" name="name" id="name"/>
                            { user.roles.includes('mcl') &&
                                <input placeholder={i18n.t('import_copy_filter')}
                                       className="form-control mb-sm-1" size={40} type="text" name="filter" id="filter"/>}
                            <input type="submit"
                                   name="commit"
                                   value={i18n.t('copy')}
                                   className="btn btn-outline-secondary"
                                   data-disable-with={i18n.t('copy')}/>
                        </form>
                    </div>
                </div>
                </>
            }
            { _import.policy.destroy &&
                <div className="icon" title={i18n.t('delete_import')} style={{display: 'inline-block'}}>
                    <a href={_import.show_link} rel="nofollow" data-method="delete"
                       data-confirm={i18n.t('are_you_sure')}>
                        <i className="fa fa-trash-o action action-delete" />
                    </a>
                </div>}
        </>
    )
}

export default ImportActions
