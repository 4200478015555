import React, {Component} from "react";

interface Props {
    editor: any
}

interface State {
    hasError: boolean,
    logicSrc: string
}

/**
 * Wrapper around MCLEditor to catch errors and show a simple text field in case there is a problem.
 * This has to be a class component: https://stackoverflow.com/questions/48482619/how-can-i-make-use-of-error-boundaries-in-functional-react-components
 * */
class MCLEditorErrorBoundary extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { hasError: false, logicSrc: props.editor.props.logicSrc};
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // TODO: emit rollbar error
        console.log(error, errorInfo);
    }

    render() {
        const editor = this.props.editor
        if (this.state.hasError) {
            return <div>
                <div className="alert alert-warning">Could not display Monaco editor</div>
                <textarea
                    value={this.state.logicSrc}
                    onChange={(e) => {
                        editor.props.setLogicSrc(e.target.value)
                        this.setState({logicSrc: e.target.value})
                    }}
                    className="form-control"/>
            </div>
        }

        return this.props.editor;
    }
}

export default MCLEditorErrorBoundary
