import * as React from 'react';
import { i18n } from "../../config/i18n";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import {MouseEventHandler, useContext, useEffect, useRef, useState} from "react";
import {IInteractiveSummaryProps} from "../../pages/Summary";
import {LocaleContext} from "../../contexts/LocaleContext";
import {SummaryService} from "../../services/SummaryService";
import Spinner from "../Spinner";
import { formattedValue } from '../summary-and-codes-tables/SummaryTableRow';
import { significanceOfDifference } from '../../utils/summaryUtils';

interface Props {
    summaryName1: string,
    summaryName2: string,
    interactiveProps: IInteractiveSummaryProps
    hideCostComponents: MouseEventHandler
}

interface Result {
    [index: string]:
        { avg: number, name: string, text: string }
}

const CostComponents: React.FunctionComponent<Props> = props => {
    const table = useRef(null);

    const [errorMessage, setErrorMessage] = useState<string>(null)
    const [resultA, setResultA] = useState<Result>(undefined)
    const [resultB, setResultB] = useState<Result>(undefined)

    const [reload, setReload] = useState<boolean>(true)
    const locale = useContext<string>(LocaleContext)
    const {interactiveProps, hideCostComponents} = props;

    useEffect(() => {
        if(reload)
            loadCostComponents()
    }, [reload]);

    /** load data points from backend. each point is a patient case. */
    function loadCostComponents() {
        SummaryService.costComponents(interactiveProps)
            .then(response => (response.status != 200 ?
                { error_message: response.data ? ('Server error: ' + response.data.substring(0, 50)) : 'empty' } : response.data))
            .then((data) => {
                if(data['error_message'] == 'empty') {
                    return;// probably just cancelled, do nothing
                }
                try {
                    setResultA(data['A'])
                    setResultB(data['B'])
                    setErrorMessage(data['A'] ? null : data['error_message'])
                    setReload(false)
                } catch(e) {
                    setResultA(undefined)
                    setResultB(undefined)
                    setErrorMessage(data['error_message'])
                    setReload(false)
                }
            });
        setReload(false)
    }

    const numCasesA = resultA ? resultA['num_cases'] : 0
    const numCasesB = resultB ? resultB['num_cases'] : 0

    const costFormatter = cell => formattedValue(cell, 0, 'money', '', 'de')
    const shareFormatter = cell => formattedValue(cell, 0, 'percentage', '', 'de')

    const costWithShareFormatter = (shareId, cell, row) => {
        return (
            <span>
            <strong>{costFormatter(cell)}</strong>
            <br></br>
            <small>{shareFormatter(row[shareId])}</small>
        </span>)
    }
    const costDiffFormatter = (cell, row) => {
        const stats = significanceOfDifference(row.var_a, row.var_b, numCasesA, numCasesB, cell, locale);
        return (<><strong title={stats.title}>{costFormatter(cell)}</strong>{' ' + stats.stars}</>);
    }
    const costFormatterBase = (cell, row) => costWithShareFormatter('share', cell, row)
    const costFormatterA = (cell, row) => costWithShareFormatter('share_a', cell, row)
    const costFormatterB = (cell, row) => costWithShareFormatter('share_b', cell, row)

    const costFooter = columnData => costFormatter(columnData.reduce((acc, item) => acc + item, 0))

    const costVariableNames = resultA ? Object.keys(resultA).filter(key => !['total_costs', 'num_cases'].includes(key)) : []
    const tabularData = costVariableNames.map(key => {
        const entry = resultA[key];
        const baseInfo = {
            id: key,
            description: entry['text']
        }
        let averages = {}
        if (resultB) {
            const entryB = resultB[key];

            // TODO calculate significance of difference between averages with significanceOfDifference from summaryUtils
            averages = {
                avg_a: entry['avg'],
                share_a: entry['share'],
                var_a: entry['var'],
                avg_b: entryB['avg'],
                share_b: entryB['share'],
                var_b: entryB['var'],
                diff: entry['avg'] - entryB['avg'],
                diff_share: entry['share'] - entryB['share'],
            }

        } else {
            averages = {
                share: entry['share'],
                avg: entry['avg']
            }
        }
        return {...baseInfo, ...averages}

    });

    const columns = [
        {
            dataField: 'id',
            text: i18n.t('cost_component'),
            sort: true,
            footer: i18n.t('total')
        },
        {
            dataField: 'description',
            text: i18n.t('description'),
            sort: true,
            footer: ''
        }]

    if (resultB) {
        columns.push({
            dataField: 'avg_a',
            text: i18n.t('average') + ' ' + props.summaryName1,
            // @ts-ignore
            style: {color: '#2d92ff'},
            formatter: costFormatterA,
            sort: true,
            // @ts-ignore
            footer: costFooter
        })
        columns.push({
            dataField: 'avg_b',
            text: i18n.t('average') + ' ' + props.summaryName2,
            // @ts-ignore
            style: {color: '#c49704'},
            formatter: costFormatterB,
            sort: true,
            // @ts-ignore
            footer: costFooter
        })
        columns.push({
            dataField: 'diff',
            text: i18n.t('diff'),
            // @ts-ignore
            style: {color: 'black'},
            formatter: costDiffFormatter,
            sort: true,
            // @ts-ignore
            footer: costFooter
        })
        columns.push({
            dataField: 'diff_share',
            text: i18n.t('diff') + ' ' + i18n.t('share'),
            // @ts-ignore
            style: {color: 'black'},
            formatter: shareFormatter,
            sort: true,
            footer: ''
        })
    } else {
        columns.push({
            dataField: 'avg',
            text: i18n.t('average'),
            // @ts-ignore
            style: {color: 'black' },
            formatter: costFormatterBase,
            sort: true,
            // @ts-ignore
            footer: costFooter
        })
    }

    const defaultSorted = [{
        dataField: resultB ? 'diff' : 'avg',
        order: 'desc'
    }];

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const sizePerPageRenderer = ({
                                     options,
                                     currSizePerPage,
                                     onSizePerPageChange
                                 }) => (
        <div className="btn-group" role="group">
            {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                        <span
                            key={option.text}
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-outline-secondary' : 'btn-outline-info'}`}
                        >
                            {option.text}
                        </span>
                    );
                })
            }
        </div>
    );

    const paginationOptions = {
        alwaysShowAllBtns: true, // Always show next and previous button
        prePageText: i18n.t('back'),
        nextPageText: i18n.t('next'),
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [10, 25, 50, 80],
        sizePerPageRenderer: sizePerPageRenderer
    };

    const SortedExportCSVButton = (props: {onExport: Function }) => {
        const handleClickCSVExport = () => {
            // sort before export according to current sort options
            if (table.current.sortContext.state.sortOrder) {
                props.onExport(table.current.paginationContext.props.data);
            } else {
                props.onExport();
            }
        };
        return (
            <ExportCSVButton
                className="btn btn-outline-secondary" { ...props }
                onClick={handleClickCSVExport}>
                <i className="fa fa-download action action-show"></i> CSV
            </ExportCSVButton>
        );
    };

    const renderTable = () => {
        return <ToolkitProvider
            keyField="id"
            data={tabularData}
            columns={columns}
            search={{searchFormatted: true}}
            exportCSV={{onlyExportFiltered: true, exportAll: false}}
        >
            {
                tkProps => (
                    <div>
                        <SearchBar {...tkProps.searchProps} className="pull-right" placeholder={i18n.t('search')}/>
                        <div className="float-end">
                            <SortedExportCSVButton {...tkProps.csvProps} ></SortedExportCSVButton>
                        </div>
                        <BootstrapTable
                            striped hover condensed bordered={false}
                            classes="table-responsive"
                            ref={table}
                            pagination={paginationFactory(paginationOptions)}
                            defaultSorted={defaultSorted}
                            {...tkProps.baseProps}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    }

    return (
        <div className="card">
            <div className="card-header">
                <strong>
                    {i18n.t('cost_components')}
                </strong>
                <span className="ms-2 text-secondary small">{i18n.t('cost_data_cases_only')}</span>
                <span
                    className="fa fa-window-minimize fa-lg pull-right pseudoLink mt-1"
                    onClick={hideCostComponents}
                    title={i18n.t('minimize')}
                />
            </div>
            <div className="card-body p-0">
                <div className="row mt-2 me-1 ms-1">
                    <div className="col-12">
                        {errorMessage ?
                            <div className="alert alert-warning m-2">{errorMessage}</div>
                            : <>{resultA ? renderTable() : <div className="mb-2"><Spinner/></div>}</>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CostComponents
