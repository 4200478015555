import * as React from 'react';
import {FieldArray} from 'formik';
import Drug from './Drug';
import { i18n } from "../../config/i18n";
import {DrugsService} from "../../services/DrugsService";
import {IDrug} from "../../interfaces";
import {useEffect, useState} from "react";

interface Props {
    drugs: IDrug[];
    setFieldValue: Function;
    errors: any;
    submitForm: Function;
}

const Drugs: React.FunctionComponent<Props> = props => {
    const [drugsData, setDrugsData] = useState<{
        applications: { [Identifier: string]: string },
        units: { [Identifier: string]: string },
        annexes: { [Identifier: string]: string }
    }>(undefined)

    useEffect(() => {
        onLoadDrugs()
    }, [])

    function onLoadDrugs() {
        DrugsService.getAutocomplete()
            .then(response => response.data)
            .then((data) => {
                setDrugsData(data);
            });
    }


    const {drugs, setFieldValue, errors, submitForm} = props;
    return (
        <div className="codes_box">
            <div className="row">
                <div className="col-sm-12">
                    <h4>
                        {i18n.t("drugs")}
                    </h4>
                </div>
            </div>

            <div>
                <div className="code_input_div row">
                    <div className="col-lg-12">
                        <div className="input-group">
                            <div className="col-sm-2 px-0">
                                <div className="me-2 text-ellipsis">
                                    {i18n.t("atc_code")}
                                </div>
                            </div>
                            <div className="col-sm-3 px-0">
                                <div className="me-2 text-ellipsis">
                                    {i18n.t("atc_annex")}
                                </div>
                            </div>
                            <div className="col-sm-2 px-0">
                                <div className="me-2 text-ellipsis">
                                    {i18n.t("atc_application")}
                                </div>
                            </div>
                            <div className="col-sm-2 px-0">
                                <div className="me-2 text-ellipsis">
                                    {i18n.t("dose")}
                                </div>
                            </div>
                            <div className="col-sm-2 px-0">
                                <div className="me-2 text-ellipsis">
                                    {i18n.t("unit")}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <FieldArray
                    name="drugs"
                    render={arrayHelpers => (
                        <div>
                            {drugs && drugs.map((drug, index) => (
                                <Drug
                                    index={index}
                                    drugsData={drugsData}
                                    errors={errors}
                                    drugs={drugs}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                    key={index}
                                />
                            ))}
                            <div className="row pb-2">
                                <div className="col-sm-12">
                                    <button
                                        type="button"
                                        onClick={() => arrayHelpers.push({code: "", dose: 0, active: true})}
                                        className="float-end btn btn-secondary btn-sm pe-2 ps-2"
                                    >
                                        <i className="fa fa-plus"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    )
}

export default Drugs
