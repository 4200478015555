import * as React from 'react';
import {Field} from 'formik';
import ProcedureAutocomplete from "../../form-fields/ProcedureAutocomplete";
import DatePicker from "../../form-fields/DatePicker";
import ColorMeter from "./ColorMeter";
import { i18n } from "../../config/i18n";
import {IProcedure} from "../../interfaces";

interface Props {
    index: number;
    procedure: IProcedure;
    setFieldValue: Function;
    submitForm: Function;
    arrayHelpers: any;
    colorMeterEnabled: boolean;
}

const Procedure: React.FunctionComponent<Props> = props => {
    const {index, procedure, setFieldValue, submitForm, colorMeterEnabled, arrayHelpers} = props;
    return (
        <div key={index}>
            <div className="code_input_div row">
                <div className="col-lg-6">
                    <div className={'input-group ' + (!procedure.active ? 'disabled' : '')}>
                        <div className="col-sm-4 px-sm-0">
                            <ProcedureAutocomplete
                                name={`procedures[${index}].code`}
                                setValue={setFieldValue}
                                used={procedure.used}
                                valid={procedure.valid}
                                value={procedure && procedure.code || ""}
                                submitForm={submitForm}
                            />
                        </div>
                        <div className="col-sm-3 px-sm-0">
                            <Field component="select"
                                   name={`procedures[${index}].side`}
                                   className="form-control form-select"
                                   value={procedure.side || '-'}>
                                <option value="">-</option>
                                <option value="R">R</option>
                                <option value="L">L</option>
                                <option value="B">B</option>
                            </Field>
                        </div>
                        <div className="col-sm-5 px-sm-0">
                            <DatePicker
                                name={`procedures[${index}].date`}
                                value={procedure.date ? Date.parse(procedure.date) : null}
                                onChange={(v, vi) => {
                                    // set hours to 2 to avoid problems with time zone and summer time conversions
                                    vi.setHours(2);
                                    setFieldValue(v, vi)
                                }}
                            />
                            <span className="fa fa-check removetag me-3"
                                  title={i18n.t(`${procedure.active ? 'de' : ''}activate_code`)}
                                  onClick={async () => {
                                      setFieldValue(`procedures[${index}].active`, !procedure.active);
                                      await Promise.resolve();
                                      submitForm();
                                  }}/>
                            <span
                                className="fa fa-remove removetag"
                                onClick={async () => {
                                    arrayHelpers.remove(index);
                                    await Promise.resolve();
                                    submitForm();
                                }}
                                title="Delete code"/>
                        </div>
                    </div>
                </div>
                {procedure.color && colorMeterEnabled &&
                <div className="col-sm-1 px-0">
                  <ColorMeter color={procedure.color}/>
                </div>}
                <div className="col-lg-5 code_description">
                    <a href={procedure.url} target="_blank">{procedure.text}</a>
                </div>
            </div>
        </div>
    )
};

export default Procedure
