import * as React from "react";
import {drawStacked} from "../../utils/chartsUtils";
import {ISummary, ISummaryTableRowOptions} from "../../interfaces";
import {accessSummary, significanceOfDifference, confidenceIntervalOfMean} from "../../utils/summaryUtils";
import {useContext} from "react";
import {LocaleContext} from "../../contexts/LocaleContext";
import { ExistingFilterContext } from "../../contexts/ExistingFilterContext";

interface Props {
    summary: ISummary,
    summary2: ISummary,
    title: string,
    options: ISummaryTableRowOptions,
    value: string
}

export  function formattedValue(value, round, type, stars, locale) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'CHF',
        maximumFractionDigits: 0
    });
    let val = value;
    if (!value || isNaN(parseFloat(value))) {
        val = 0
    }
    const style = val < 0 ? {color: '#d00000'} : null
    switch(type) {
        case 'percentage':
            val = (val * 100).toFixed(round || 2) + "%";
            break;
        case 'money':
            val = formatter.format(val).toString().replace(/\./g,"'");
            break;
        default:
            val = round ? val.toFixed(round) : val
    }
    return <span style={style}>{stars + val}</span>
}

const SummaryTableRow: React.FunctionComponent<Props> = props => {
    const locale = useContext<string>(LocaleContext)
    
    function handleCollection(attributeName, summary, summary2, key_names, key_ids) {
        const existingFilter = useContext(ExistingFilterContext);
        let data = [summary[attributeName], accessSummary(summary2, attributeName)];
        if (key_names) {
            data = [{}, summary2 ? {} : null]
            key_names.forEach((key, i) => {
                const key2 = key_ids ? key_ids[i] : key
                const value1 = accessSummary(summary, attributeName) == 0 ? 0 : accessSummary(accessSummary(summary, attributeName), key2)
                data[0][key] = value1 ? value1 : 0
                if (summary2) {
                    const value2 = accessSummary(summary2, attributeName) == 0 ? 0 : accessSummary(accessSummary(summary2, attributeName), key2)
                    data[1][key] = value2 ? value2 : 0
                }
            })
        }
        // @ts-ignore
        google.charts.setOnLoadCallback(function() {
            const columnTitles = [summary.name, summary2 ? summary2.name : null];
            drawStacked(attributeName, attributeName, data, columnTitles, key_ids, existingFilter);
        });
        return <div id={attributeName + "_chart_div"}></div>
    }

    function significanceOfDifferenceSummary(summary, summary2, sd, diff){
        if (!sd ) {
            return {stars: '', title: ''}
        }
        const sdA = summary[sd]
        const sdB = summary2[sd]
        const numCasesA = summary.numberOfPatientcases
        const numCasesB = summary2.numberOfPatientcases
        return significanceOfDifference(sdA, sdB, numCasesA, numCasesB, diff, locale)
    }

    function confidenceIntervalOfMeanSummary(summary, sd){
        if (!sd ) {
            return {confidenceInterval: null, title: ''}
        }
        const stddev = summary[sd]
        const numCases = summary.numberOfPatientcases
        return confidenceIntervalOfMean(stddev, numCases, locale)
    }

    function tableData() {
        const {summary, summary2, options, value} = props;
        if (options && options['type'] === 'collection') {
            return (
                <td className="text-end" colSpan={summary2 ? 1 : 3} width={'60%;'} >
                    {handleCollection(value, summary, summary2, options['key_names'], options['key_ids'])}
                </td>
            )
        } else if (summary2) {
            const val1 = summary[value] || 0
            const val2 = summary2[value] || 0
            const {stars, title} = significanceOfDifferenceSummary(summary, summary2, options['sd'], val1 -val2)
            return (
                <>
                    <td className="table-primary text-end"  id={"summary_" + value + "_A"}>
                        {formattedValue(val1, options['round'], options['type'], '', locale)}
                    </td>
                    <td className="table-warning text-end" id={"summary_" + value + "_B"}>
                        {formattedValue(val2, options['round'], options['type'], '', locale)}
                    </td>
                    <td className="table-secondary text-end" title={title} id={"summary_" + value + "_diff"}>
                        {formattedValue(val1 - val2, options['round'], options['type'], stars + ' ', locale)}
                    </td>
                </>
            )
        } else {
            const {confidenceInterval, title} = confidenceIntervalOfMeanSummary(summary, options['sd'])
            return (
                    <td className="table-secondary text-end" width={'100px;'} id={"summary_" + value}>
                        {formattedValue(summary[value], options['round'], options['type'], '', locale)}
                    {options['sd'] &&
                        <span className="text-secondary" title={title}>
                            {confidenceInterval}
                        </span>
                    }
                    </td>
            )
        }
    }

    return (
        <tr>
            <th style={{minWidth: '200px'}}>
                {props.title}
            </th>
            {tableData()}
        </tr>
    );
}

export default SummaryTableRow
