import * as React from 'react';
import classnames from 'classnames';
import {LocaleContext} from '../contexts/LocaleContext';
import {useContext} from "react";

interface Props {
    title?: string;
    active: string;
    urlEncodedPc?: string;
    text: string;
    path: string;
    name: string;
}

const TabMenuItem: React.FunctionComponent<Props> = props => {
    const {title, active, urlEncodedPc, text, path, name} = props;
    const locale = useContext<string>(LocaleContext)

    if (!path) {
        return null;
    }

    const paramsCharacter = path.includes('?') ? '&' : '?'
    return (
        <li className="nav-item" title={title}>
            <a
                className={classnames('nav-link', {'active': active === name})}
                href={urlEncodedPc ? `${path}${paramsCharacter}locale=${locale}&pc=${urlEncodedPc}` : `${path}${paramsCharacter}locale=${locale}`}
            >
                {text}
            </a>
        </li>)
};

export default TabMenuItem
