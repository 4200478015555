import {useEffect} from "react";
import { createConsumer } from "@rails/actioncable"

export const useConsumer = ({channel, id, received}) => {
  useEffect(() => {
    const consumer = createConsumer()
    consumer.subscriptions.create({
      channel: channel,
      id: id
    }, {
      received: (data) => {
        received(data)
      }
    })
  }, [])
}
