import {create} from "apisauce";

export const http = create({
  // eslint-disable-next-line no-undef
  baseURL: (medcodesearchSearchPath + 'chops/'),
  headers: {Accept: 'application/json'},
});

export const ProceduresService = {
  get: (term, version, terminalOnly = 1) => http.get(version + '/search',
    {
      search: term,
      terminal_only: terminalOnly,
      max_results: 10,
      highlight: 1
    }),
  catalog: 'chop'
};
