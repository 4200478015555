import React from 'react';
import Node, {INode} from "./Node";
import classNames from 'classnames';

interface Props {
  node: INode,
  chapter_node_id: string,
  title?: [Function, Node],
  subtitle?: [Function, Node],
  path: (string | number)[],
  treeIndex: number,
  treeId: string,
  isSearchMatch: boolean,
  isSearchFocus?: boolean,
  canDrag?: boolean,
  scaffoldBlockPxWidth: number,
  toggleChildrenVisibility?: Function,
  buttons?: Node[],
  className?: string,
  style?: {},
  multilabel: boolean,

  // Drag and drop API functions
  // Drag source
  connectDragPreview: Function,
  connectDragSource: Function,
  parentNode?: {}, // Needed for dndManager
  isDragging: boolean,
  didDrop: boolean,
  draggedNode?: { },
  // Drop target
  isOver: boolean,
  canDrop?: boolean,

  // rtl support
  rowDirection?: string,

  addNode?: Function,
  removeNode?: Function,
  changeNode?: Function,
  updateNode?: Function,
  updateHeight?: Function,
  isRequesting?: boolean,
  id?: string
}


const CustomThemeNodeContentRenderer: React.FunctionComponent<Props> = props => {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      multilabel,
      // title,
      // subtitle,
      // draggedNode,
      path,
      // treeIndex,
      // isSearchMatch,
      // isSearchFocus,
      // buttons,
      className,
      // style,
      didDrop,
      // treeId,
      // isOver, // Not needed, but preserved for other renderers
      // parentNode, // Needed for dndManager
      // rowDirection,
      chapter_node_id,
      // ...otherProps
    } = props;


    const isLandingPadActive = !didDrop && isDragging;

    const nodeContent = connectDragPreview(
      <div
        className={classNames(
          className,
          'nodeRow',
          {'nodeRow-expanded': node.editable},
          {'rowLandingPad': isLandingPadActive},
          {'rowCancelPad': isLandingPadActive && !canDrop},
        )
        }

      >
        <Node
          node={node}
          removeNode={props.removeNode}
          updateNode={props.updateNode}
          changeNode={props.changeNode}
          isRequesting={props.isRequesting}
          id={props.id}
          addNode={props.addNode}
          multilabel={multilabel}
          //updateHeight={props.updateHeight}
        />
      </div>
    );


    const renderLines = () => {
      const lines = [];
      // do not draw red line on first level (start node or chapter node)
      const depth = path.length - 1
      for (let i = 1; i < depth ; i++) {
        lines.push(<div className={"verticalNoLine" + (multilabel ? "Blue" : "")} style={{left: -scaffoldBlockPxWidth * i + 30}} key={i}/>);
      }
      return lines;
    };
    return (
      <div>
        {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === 'function') && (
          <div>
            {
              !isDragging && (
                <>
                  { node.parent_id != null && chapter_node_id != node.id &&
                    <div
                      style={{width: scaffoldBlockPxWidth}}
                      className={"noLineHead" + (multilabel ? "Blue" : "")}
                    />
                  }
                  <div
                    className="verticalYesLine"
                  />
                </>
              )}
          </div>
        )}
        {node.chapter_entry && chapter_node_id != node.id &&
            <>
              <div
                  className={"verticalNoLineShort" + (multilabel ? "Blue" : "")}
              />
            </>
        }

        {renderLines()}

        <div className={classNames('rowWrapper', {'rowWrapperDragDisabled': !canDrag})}>
          {canDrag
            ? connectDragSource(nodeContent, {dropEffect: 'copy'})
            : nodeContent}
        </div>
      </div>

    );
}

CustomThemeNodeContentRenderer.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  multilabel: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: '',
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: 'ltr',
};

export default CustomThemeNodeContentRenderer;
