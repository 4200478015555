import * as React from 'react';
import { i18n } from "../config/i18n";
import {IPcs} from "../interfaces";
import Select from 'react-select'

interface Props {
    systems: IPcs[],
    currentSystem: IPcs,
    changeSystem: Function
}

const PcsSystemSelection: React.FunctionComponent<Props> = (
    { systems, currentSystem, changeSystem}) => {
    return (
        <div className="codes_box">
            <div className="row">
                <div className="col-sm-3">
                    <h4>{i18n.t("system_selection")}</h4>
                </div>
                <div className="col-sm-9">
                    <Select
                        name="system_selection"
                        id="system_selection"
                        options={systems.map(system => ({value: system.id, label: system.name}))}
                        defaultValue={{value: currentSystem.id, label: currentSystem.name}}
                        onChange={(e) => {
                            changeSystem(e.value)
                        }}
                        isSearchable={true}
                        menuPortalTarget={document.querySelector('body')}
                    />
                </div>
            </div>
        </div>
    );
};

export default PcsSystemSelection
