import * as React from 'react';
import { useState } from 'react';
import { i18n } from "../../config/i18n";
import { ToastContainer } from 'react-toastify';
import Progressbar from "../progress/Progressbar";
import { useConsumer } from "../../hooks/useConsumer"
import Alert from "../Alert";
import Spinner from "../Spinner";
import RecalculateButton from "./RecalculateButton";
interface Props {
    locale: string
    groupResultComparisonId: number}

const GroupResultComparisonProgress: React.FC<Props> = ({locale, groupResultComparisonId    }) => {
    i18n.locale = locale;
    const [initialDataReceived, setInitialDataReceived] = useState(false)
    const [progress, setProgress] = useState(0)
    const [status, setStatus] = useState(null)
    useConsumer({
            channel: "GroupResultComparisonsChannel",
            id: groupResultComparisonId,
            received: (data) => {
                if (!initialDataReceived) {
                    setInitialDataReceived(true)
                }
                setProgress(data.progress)
                setStatus(data.status)
                if (data.status === 'finished') {
                    location.reload();
                }
            },
        }
    )
    if (!initialDataReceived) {
        return <Spinner />
    }

    if (status === 'failed') {
        return (
            <>
                <Alert type="danger">
                    {i18n.t('group_failed')}
                </Alert>
                <RecalculateButton
                    groupResultComparisonId={groupResultComparisonId}
                />
            </>)
    }

    return (
        <>
            <Alert type="info">
                {i18n.t('group_result_comparison_status.' + status)}
                <Progressbar
                    total={100}
                    current={progress}
                    unit="%"
                />
            </Alert>
            <ToastContainer hideProgressBar={true} icon={false}/>
        </>
    )
}

export default GroupResultComparisonProgress
