import * as React from 'react';
import Editor from '@monaco-editor/react';
import { i18n } from "../../config/i18n";

interface Props {
    logicSrc: string,
    setLogicSrc: Function,
    onEnter?: Function,
    miniVersion?: boolean
}

const MCLEditor: React.FunctionComponent<Props> = (props) => {
    const {logicSrc, setLogicSrc, onEnter, miniVersion} = props

    const onChange = (value) => {
        setLogicSrc(value)
    }

    const wrapperProps = onEnter ? {
        onKeyDown: (e) => {
            if (e.key === 'Enter') {
                onEnter();
                e.preventDefault();
            }
        }
    } : {}

    return (
        <Editor height={miniVersion ? "3vh" : "18vh"}
            width={miniVersion ? "5" : ""}
            language="medcodelogic" value={logicSrc}
            options={{
                readOnly: false,
                lineNumbers: 'off',
                scrollbar: { vertical: "hidden" },
                minimap: { enabled: false },
                wordBasedSuggestions: false,
                renderLineHighlight: "none"
            }}
            theme="medcodelogicTheme"
            onChange={onChange}
            wrapperProps={wrapperProps}
            loading={i18n.t('loading_mcl_editor')}
        />
    )
}

export default MCLEditor
