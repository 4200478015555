import * as React from 'react';
import { i18n } from "../../config/i18n";

interface Props {
    color: string;
}

const ColorMeter: React.FunctionComponent<Props> = props => {
    const {color} = props;

    return (
        <div className="meter">
        <span
              style={{backgroundColor: color}}
              title={i18n.t("code_likelihood_explanation")}/>
        </div>
    );
};

export default ColorMeter
