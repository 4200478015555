export const linkifyLogic = (logic) => {
    function convert(str, p1) {
        const href = `table_by_name?name=${p1}`;
        return `class="table"><a href="${href}">${p1}</a></span>`;
    }
    // normalize white space
    if(logic === undefined)
        return '';
    logic = logic.replace(/[\s]+/g, ' ');
    const regExp = /class='table'>([^<]*)<\/span>/ig;
    return logic.replace(regExp, convert);
};
