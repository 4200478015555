import * as React from 'react';
import {LocaleProvider} from '../contexts/LocaleContext';
import {UserProvider} from '../contexts/UserContext';
import { i18n } from "../config/i18n";
import {IUser, IUserListEntry} from "../interfaces";
import Spinner from '../components/Spinner';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import UserActions from "../components/user/UserActions";
import {useEffect, useState} from "react";

interface Props {
    locale: string,
    isAdmin: boolean,
    user: IUser,
    users: IUserListEntry[],
}

const UsersOverview: React.FunctionComponent<Props> = (props) => {
    const {locale, user, users} = props;
    const [selected, setSelected] = useState<number[]>([])
    i18n.locale = locale;
    useEffect(() => {
        // this avoids having inconsistent selected state vs. selected rows after browser's back action.
        setSelected([])
    }, []);

    const handleConfirmDelete = (e) => {
        if(confirm(i18n.t('are_you_sure'))){
            return true
        } else {
            e.preventDefault();
        }
    }

    const dateFormatter = cell =>  {
        const date = new Date(Date.parse(cell))
        if(Number.isNaN(date.valueOf()))
            return ''
        return date.toLocaleDateString("de-CH")
    }
    const booleanFormatter = cell => cell ? i18n.t('true') : i18n.t('false')
    const showLinkFormatter = (cell, row) => <a href={row.show_link}>{cell}</a>
    const linkActionFormatter = (cell, row) => <UserActions user={row}/>
    const arrayFormatter = cell => cell.join(', ')

    const onlyUnique = (value, index, array)=> array.indexOf(value) === index;
    const uniqueValueOptions = attr => users.map(i => i[attr]).filter(onlyUnique)
        .reduce(function(map, obj) {
            map[obj] = obj;
            return map;
        }, {});

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids)
        } else {
            setSelected([]);
        }
    }

    const columns = [
        {
            dataField: 'username',
            text: i18n.t('username'),
            sort: true,
            formatter: showLinkFormatter,
        },
        {
            dataField: 'email',
            text: i18n.t('email'),
            sort: true,
        },
        {
            dataField: 'created_at',
            text: i18n.t('creation_date'),
            formatter: dateFormatter,
            sort: true,
        },
        {
            dataField: 'current_sign_in_at',
            text: i18n.t('last_sign_in'),
            formatter: dateFormatter,
            sort: true,
        },
        {
            dataField: 'sign_in_count',
            text: i18n.t('sign_in_count'),
            sort: true,
        },
        {
            dataField: 'two_factor_enabled',
            text: '2FA ' + i18n.t('active'),
            formatter: booleanFormatter,
            sort: true,
        },
        {
            dataField: 'roles',
            text: i18n.t('roles') + ' ',
            formatter: arrayFormatter,
            sort: false,
            // @ts-ignore
            filter: selectFilter({
                options: {'casematch': 'casematch',
                    'import': 'import',
                    'mcl': 'mcl',
                    'data-collection': 'data-collection',
                    'pcs': 'pcs',
                    'api': 'api',
                    'ccc': 'ccc',
                    'dynamic_import': 'dynamic_import',
                    'prediction': 'prediction'},
                placeholder: '⌄',
                comparator: Comparator.LIKE
            })
        }
    ]

    if(user.admin) {
        columns.push({
            dataField: 'admin',
            text: 'Admin ',
            // @ts-ignore
            formatter: booleanFormatter,
            sort: false,
            // @ts-ignore
            filter: selectFilter({
                options: {true: i18n.t('true'), false: i18n.t('false')},
                placeholder: '⌄'
            })
        })

        columns.push({
            dataField: 'domain',
            text: i18n.t('domain') + ' ',
            sort: false,
            // @ts-ignore
            filter: selectFilter({
                options: uniqueValueOptions('domain'),
                placeholder: '⌄'
            })
        })
    }
    // @ts-ignore
    columns.push({
        dataField: 'id',
        text: '',
        // @ts-ignore
        style: {width: "50px"},
        sort: false,
        footer: '',
        formatter: linkActionFormatter
    })

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    const { SearchBar } = Search;
    const sizePerPageRenderer = ({
                                     options,
                                     currSizePerPage,
                                     onSizePerPageChange
                                 }) => (
        <div className="btn-group" role="group">
            {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                        <span
                            key={option.text}
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-outline-secondary' : 'btn-outline-info'}`}
                        >
                            {option.text}
                        </span>
                    );
                })
            }
        </div>
    );
    const paginationOptions = {
        alwaysShowAllBtns: true, // Always show next and previous button
        prePageText: i18n.t('back'),
        nextPageText: i18n.t('next'),
        showTotal: true,
        disablePageTitle: true,
        sizePerPageRenderer: sizePerPageRenderer
    };
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectColumnPosition: 'right',
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    return (
        <LocaleProvider value={locale}>
            <UserProvider value={user}>
                <React.Suspense fallback={<Spinner/>}>
                    <ToolkitProvider
                    keyField="id"
                    data={ users }
                    columns={ columns }
                    search={ {  } }
                >
                    {
                        tkProps => (
                            <div>
                                <SearchBar { ...tkProps.searchProps }
                                           className="float-end"
                                           placeholder={i18n.t('search')}/>
                                <div className={"float-end dropdown" + (selected.length == 0 ? " disabled" : "")}>
                                    <form action={'users/destroy_many'}
                                          acceptCharset="UTF-8"
                                          onSubmit={handleConfirmDelete}
                                          method="post">
                                        <input type="hidden" name="authenticity_token"
                                               value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                                               autoComplete="off"/>
                                        {selected.map(import_id =>
                                            <input type="hidden" name="user_ids[]"
                                                   key={import_id}
                                                   value={import_id}
                                                   autoComplete="off"/>
                                        )}
                                        <input type="submit"
                                               name="commit"
                                               disabled={selected.length == 0}
                                               value={i18n.t('remove_user')}
                                               className="btn btn-primary"/>
                                    </form>
                                </div>
                                <BootstrapTable
                                    striped hover condensed bordered={ false }
                                    classes="table-responsive"
                                    pagination={ paginationFactory(paginationOptions) }
                                    defaultSorted={ defaultSorted }
                                    filter={ filterFactory() }
                                    headerClasses="align-top table-light"
                                    selectRow={ selectRow }
                                    { ...tkProps.baseProps }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                </React.Suspense>
            </UserProvider>
        </LocaleProvider>
    )

}

export default UsersOverview
