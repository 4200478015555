import {http} from './api';
import { createCancelTokenHandler } from "./axiosUtils";

export const SummaryService = {
    aggregate: (aggregator, variable, dynamic, interactiveProps) =>
        http.get(`summaries/aggregate`,
        {}, {
            params: {
                aggregator: aggregator,
                variable: variable,
                import_id: interactiveProps.importId,
                pc_ids_a: interactiveProps.pcIdsA,
                pc_ids_b: interactiveProps.pcIdsB,
                medcodelogic: dynamic ? '1' : '',
            }, cancelToken: cancelTokenHandler['aggregate'].handleRequestCancellation().token
        }),
    chartData: (chartType, variable, binSize, yVariable, yAggregator, dynamic, yDynamic, interactiveProps) =>
        http.get(`summaries/chart_data`,
        {}, {
            params: {
                chart_type: chartType,
                variable: variable,
                import_id: interactiveProps.importId,
                pc_ids_a: interactiveProps.pcIdsA,
                pc_ids_b: interactiveProps.pcIdsB,
                bin_size: binSize,
                y_variable: yVariable,
                y_aggregator: yAggregator,
                medcodelogic: dynamic ? '1' : '',
                y_medcodelogic: yDynamic ? '1' : '',
            },
            cancelToken: cancelTokenHandler['chartData'].handleRequestCancellation().token
        }),
    dataPoints: (variable, yVariable, dynamic, yDynamic, interactiveProps) => http.get(`summaries/data_points`,
        {}, {
            params: {
                variable: variable,
                import_id: interactiveProps.importId,
                pc_ids_a: interactiveProps.pcIdsA,
                pc_ids_b: interactiveProps.pcIdsB,
                y_variable: yVariable,
                medcodelogic: dynamic ? '1' : '',
                y_medcodelogic: yDynamic ? '1' : '',
            }, cancelToken: cancelTokenHandler['dataPoints'].handleRequestCancellation().token
        }),
    costComponents: (interactiveProps) =>
        http.get(`summaries/cost_components`,
            {}, {
                params: {
                    import_id: interactiveProps.importId,
                    pc_ids_a: interactiveProps.pcIdsA,
                    pc_ids_b: interactiveProps.pcIdsB,
                }, cancelToken: cancelTokenHandler['aggregate'].handleRequestCancellation().token
            }),
};

const cancelTokenHandler = createCancelTokenHandler(SummaryService)

