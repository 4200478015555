import * as React from 'react';
import Select, { components, createFilter, Option } from 'react-select'
import { i18n } from "../config/i18n";
import {IUser} from "../interfaces";
import {UsersService} from "../services/UsersService";

interface Props {
    options: IUser[],
    field: any,
    form: any,
    setOptions: Function,
    userIdsFromPermissions: string[]
}

const UserAutocomplete: React.FunctionComponent<Props> = props => {
    const { options, field, form, setOptions, userIdsFromPermissions } = props;

    const getOptionLabel = (option) => (
        [option.username, option.email, option.domainName].filter((o) => !!o).join(' - ')
    )

    const CustomOption = ({ data, ...rest }) => (
        <components.Option {...rest}>
            <div>{`${data.username} - ${data.email}`}</div>
            <small>{data.domainName}</small>
        </components.Option>
    )

    function searchUser(input) {
        UsersService.get(userIdsFromPermissions, input).then(response => {
            setOptions(response.data)
        })
    }

    return (
        <Select
            onInputChange={(e) => searchUser(e)}
            placeholder={i18n.t('select_user')}
            required
            classNamePrefix="custom_select"
            name={field.name}
            onChange={(option: Option) => {
                form.setFieldValue(field.name, option.id, true);
            }}
            options={options}
            value={options.find((o) => o.id === field.value)}
            onBlur={field.onBlur}
            filterOption={createFilter({ ignoreAccents: false })}
            getOptionValue={(option) => (option.id)}
            getOptionLabel={(option) => getOptionLabel(option)}
            components={{
                Option: CustomOption
            }}
        />
    )
};

export default UserAutocomplete
