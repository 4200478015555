import * as React from 'react';
import classNames from 'classnames';

interface Props {
    icon: string;
    onClick?: any;
    href?: string;
    title?: string;
    target?: string;
    disabled?: boolean;
}

const ActionIcon: React.FunctionComponent<Props> = props => {
    return (
        <div
            className={classNames('icon', {'action-disabled': props.disabled})}
            style={{display: "inline-block", cursor: "pointer"}} title={props.title}
        >
            <a
                onClick={props.disabled ? undefined : props.onClick}
                href={props.disabled ? undefined : props.href}
                target={props.target}
            >
                <i className={`fa fa-${props.icon} action`}/>
            </a>
        </div>
    )
};

export default ActionIcon
