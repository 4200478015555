import * as React from 'react';
import { useState } from 'react';
import { i18n } from "../../config/i18n";
import { ToastContainer } from 'react-toastify';
import Progressbar from "../progress/Progressbar";
import { useConsumer } from "../../hooks/useConsumer"
import Alert from "../Alert";
import RegroupButton from "../group-result/RegroupButton";
import Spinner from "../Spinner";

interface Props {
    locale: string,
    statusNeeded: string,
    groupResultId: number,
    importId: number,
    pcsId: number,
    pcsName: number,
}

const GroupResultProgress: React.FC<Props> = (
    {
        locale,
        statusNeeded,
        groupResultId
    }) => {
    i18n.locale = locale;
    const [initialDataReceived, setInitialDataReceived] = useState(false)
    useConsumer({
            channel: "GroupResultsChannel",
            id: groupResultId,
            received: (data) => {
                if (!initialDataReceived) {
                    setInitialDataReceived(true)
                }
                setProgress(data.progress)
                setStatus(data.status)
                if (data.status === statusNeeded || data.status === 'finished') {
                    location.reload();
                }
            },
        }
    )
    const [progress, setProgress] = useState(0)
    const [status, setStatus] = useState(null)

    if (!initialDataReceived) {
        return <Spinner />
    }

    if (status === 'outdated') {
        return (<>
            <Alert type="info">
                {i18n.t('outdated_need_regroup')}
            </Alert>
            <RegroupButton
                groupResultId={groupResultId}
            />
        </>)
    }

    if (status === 'failed') {
        return (<>
            <Alert type="danger">
                {i18n.t('group_failed')}
            </Alert>
            <RegroupButton
                groupResultId={groupResultId}
            />
        </>)
    }

    return (
        <>
            <Alert type="info">
                {i18n.t('group_result_status.' + status)}
                <Progressbar
                    total={100}
                    current={progress}
                    unit="%"
                />
            </Alert>
            <ToastContainer hideProgressBar={true} icon={false}/>
        </>
    )
}

export default GroupResultProgress
