import * as React from 'react';
import {Field} from "formik";
import classnames from 'classnames';
import FieldWrapper from "./FieldWrapper";
import {IFlag} from "../interfaces";

interface Props {
    name: string;
    label?: string;
    disabled?: boolean;
    flags?: IFlag;
    inputWrapperClassName?: string;
    options: any;
    grid?: { label: number, input: number };
    prompt?: string|boolean;
    onChange?: any;
}

export const SelectField: React.FunctionComponent<Props> =
    ({flags, disabled, name, options, prompt, onChange, ...otherProps}) => {
        const onChangeProps = (typeof onChange === 'undefined') ? {} : {onChange: onChange};
        return (
            <FieldWrapper {...otherProps} name={name}>
                <Field
                    component="select"
                    name={name}
                    id={name}
                    {...onChangeProps}
                    className={
                        classnames(
                            "form-control form-select",
                            {"input-invalid": flags && flags.valid === false},
                            {"input-used": flags && flags.used === true},
                            {"input-disabled": disabled}
                        )
                    }
                    disabled={disabled}
                >
                    {prompt && <option>{prompt === true ? '-' : prompt}</option>}
                    {options && options.map(option => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                    ))}
                </Field>
            </FieldWrapper>
        );
    };

export default SelectField;
