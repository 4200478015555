import {http} from './api';

export const MedcodelogicService = {
  getCodeText: (codeType, code, locale, year) => http.get('medcodelogic/code_text',
      {code_type: codeType, code: code, locale: locale, year: year}),
  getTablesList: (scope=null) => http.get('medcodelogic/tables_list' + (scope != null ? '?scope=' + scope : '')),
  getVariables: (includeSubAttributes = 'no') => http.get('medcodelogic/variables',
      {include_sub_attributes: includeSubAttributes}),
  checkCode: (logicSrc, tableScope) => http.get('medcodelogic/check_code',
      {logic_src: logicSrc, table_scope: tableScope}),
};
