import * as React from 'react';
const FilterForm = React.lazy(() => import('../../forms/FilterForm'));
import { i18n } from "../../config/i18n";
import { IRule, IUser } from "../../interfaces";
import PreparedFilters from '../../components/filter/PreparedFilters';
import { buildRailsStyleUrl, getCurrentUrl } from '../../utils/UrlUtils';
import MCLEditorErrorBoundary from '../../components/medcodelogic/MCLEditorErrorBoundary';
import MCLEditor from '../../components/medcodelogic/MCLEditor';
import { useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTableScope } from '../../contexts/MedcodelogicLoaderContext';

export interface IFilterCasesProps {
    submitLink: string,
    cancelLink: string,
    saveLink: string,
    pcsOptions: [],
    rule: IRule;
    filterCategory: {
        id: string,
        rules: IRule[];
    };
    filterDescription: string;
    hidePreparedFilters: boolean;
    params: { [key: string]: any | null };
    filterErrors: string[] | null;
    filterSqlErrors: string | null;
}

const FilterCases: React.FunctionComponent<IFilterCasesProps> = (props) => {

    const { rule, submitLink, cancelLink, saveLink, pcsOptions, filterCategory, 
        hidePreparedFilters, params, filterDescription, filterErrors, filterSqlErrors } = props;

    const logicSrcBlank = !rule.logic_src || rule.logic_src === '';
    const [showFilterForm, setShowFilterForm] = useState<boolean>(!logicSrcBlank);
    const [currentLogicSrc, setLogicSrc] = useState<string>(rule.logic_src);
    const {tableScope, setTableScope} = useTableScope();

    const user = React.useContext<IUser>(UserContext);
    const locale = React.useContext<string>(LocaleContext);

    const handleShowFilterForm = () => setShowFilterForm(true);
    const based_on_conditions = rule.conditions && rule.conditions.length > 0;
    const currentURL = getCurrentUrl();
    const filterId = params.filter;
    const handleChangeTableScope = (event) => setTableScope(event.target.value)

    return (<><div className='row vertical-spacer'>
        <PreparedFilters
            filterId={filterId}
            filterCategory={filterCategory}
            filterDescription={filterDescription}
            hidePreparedFilters={hidePreparedFilters}
            params={params} />
        {logicSrcBlank && !showFilterForm && <div className="col-lg-3">
            <button
                id="filterButton"
                className="btn btn btn-outline-secondary float-end"
                onClick={handleShowFilterForm}>
                {i18n.t('filter_verb')}
                <span className="fa fa-filter fa-lg"></span>
            </button>
        </div>}
    </div>

        <div className="row vertical-spacer" style={showFilterForm ? {} : { display: 'none' }}>
            <div className="col-lg-12">
                <div className="card bg-light">
                    <div className="card-header font-weight-bold pb-1">
                        {i18n.t('define_filter')}
                        {user.roles.includes('mcl') && (
                            <ul id="filterTab" className="nav nav-tabs card-header-tabs float-end" role="tablist">
                                <li className="nav-item">
                                    <a
                                        id="conditionsEditorTab"
                                        className={`nav-link ${based_on_conditions ? 'active' : ''}`}
                                        href="#conditionsEditor"
                                        data-bs-toggle="tab"
                                        role="tab"
                                        aria-controls="conditionsEditor"
                                        aria-selected={based_on_conditions ? 'true' : 'false'}
                                    >
                                        Classic
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="mclEditorTab"
                                        className={`nav-link ${based_on_conditions ? '' : 'active'}`}
                                        href="#mclEditor"
                                        data-bs-toggle="tab"
                                        role="tab"
                                        aria-controls="mclEditor"
                                        aria-selected={based_on_conditions ? 'false' : 'true'}
                                    >
                                        MedcodeLogic
                                    </a>
                                </li>
                            </ul>
                        )}
                    </div>
                    <div id="filterTabContent" className="card-body p-2 tab-content">
                        {filterErrors && <div className="col-lg-12 alert alert-danger"><ul>
                            {filterErrors.map((error, index) => (
                                <li key={index}>
                                    <em>{i18n.t(error[0])}</em>
                                    <span>{error[1]}</span>
                                </li>
                            ))}
                        </ul></div>}
                        {filterSqlErrors && !logicSrcBlank ?
                            <div className="col-lg-12 alert alert-danger">{filterSqlErrors}</div> : null}
                        <div id="conditionsEditor"
                            className={`tab-pane fade show ${based_on_conditions || !user.roles.includes('mcl') ? 'active' : ''}`}
                            role="tabpanel"
                            aria-labelledby="mclEditorTab"
                        >
                            <FilterForm
                                rule={rule}
                                locale={locale}
                                submitLink={submitLink}
                                cancelLink={cancelLink}
                                saveLink={saveLink}
                                pcsOptions={pcsOptions}
                            />
                        </div>
                        {user.roles.includes('mcl') && (
                            <div
                                id="mclEditor"
                                className={`tab-pane fade show ${based_on_conditions ? '' : 'active'
                                    }`}
                                role="tabpanel"
                                aria-labelledby="conditionsEditorTab"
                            >
                                <form
                                    action={buildRailsStyleUrl(currentURL, params)}
                                    method="get"
                                >
                                    {['sort_by', 'sort_order', 'classname', 'import_a_id', 'import_b_id', 'rule_a_id', 'rule_b_id', 'single_agg', 'interactive'].map(key => (
                                        params[key] ? <input key={key} type="hidden" name={key} value={params[key]} /> : null
                                    ))}
                                    {pcsOptions.length > 1 && (
                                        <div className="row">
                                            <div className="col-lg-6 form-inline mb-1">
                                                <label htmlFor="rule[table_scope]">{i18n.t('table_scope')}</label>
                                                <select
                                                    name="rule[table_scope]"
                                                    value={tableScope}
                                                    onChange={handleChangeTableScope}
                                                    className="form-control form-select ms-2"
                                                >
                                                    {pcsOptions.map(option => (
                                                        <option key={option[1]} value={option[1]}>{option[0]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-lg-12">
                                        <MCLEditorErrorBoundary editor={
                                            <MCLEditor logicSrc={rule.logic_src || ''}
                                                setLogicSrc={setLogicSrc} />
                                        } />
                                    </div>
                                    <textarea hidden={true} id="logic_src" name="rule[logic_src]" value={currentLogicSrc} readOnly={true} />

                                    <button type="submit" className="btn btn-primary">
                                        <span className="fa fa-check fa-lg"></span>
                                        {i18n.t('filter_cases')}
                                    </button>
                                    {!logicSrcBlank &&
                                        <a className="btn btn btn-outline-secondary ms-1" href={buildRailsStyleUrl(currentURL, { ...params, rule: null })}>
                                            {i18n.t('reset_filter')}
                                        </a>
                                    }
                                    <a
                                        id="saveFilter"
                                        className="btn btn btn-outline-secondary float-end"
                                        href={buildRailsStyleUrl('/rules/new', { ...params, logic_src: currentLogicSrc, category_id: filterCategory.id, type: 'medcodelogic' })}
                                    >
                                        <span className="fa fa-save fa-lg"></span>
                                        {' ' + i18n.t('save_filter')}
                                    </a>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default FilterCases
