import * as React from 'react';
import { i18n } from "../../config/i18n";
import {IInteractiveSummaryProps} from "./Summary"
import {SummaryService} from "../../services/SummaryService";
import _ from "lodash";
import Spinner from "../Spinner";
import Select from 'react-select'
import {significanceOfDifference, confidenceIntervalOfMean} from "../../utils/summaryUtils";
import {useContext, useState} from "react";
import {getUrlParam} from "../../utils/UrlUtils";
import { useVariables } from '../../contexts/VariableContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import MCLEditor from '../medcodelogic/MCLEditor';
import MCLEditorErrorBoundary from '../medcodelogic/MCLEditorErrorBoundary';

interface Props {
    numCasesA: number,
    numCasesB: number,
    interactiveProps?: IInteractiveSummaryProps
}

const SingleVariableAggregation: React.FunctionComponent<Props> = (props) => {
    const encodedParams = getUrlParam('single_agg', location.search);
    const [initVariable, initAggregator, initLogicSrcVariable]
        = encodedParams ? encodedParams.split('~') : ['age_years', 'avg', '']

    const {interactiveProps, numCasesA, numCasesB} = props;
    const [resultA, setResultA] = useState<number>(NaN)
    const [resultB, setResultB] = useState<number>(NaN)
    const [stddevA, setStddevA] = useState<number>(NaN)
    const [stddevB, setStddevB] = useState<number>(NaN)
    const [variable, setVariable] = useState<string>(initVariable)
    const [aggregator, setAggregator] = useState<string>(initAggregator)
    const [reload, setReload] = useState<boolean>(true)
    const doReload = () => setReload(true)
    const [isMclVariable, setIsMclVariable] = useState<boolean>(initVariable === 'dynamic')
    const [logicSrcVariable, setLogicSrcVariable] = useState<string>(decodeURIComponent(initLogicSrcVariable.replace(/\+/g, ' ')))
    const [errorMessage, setErrorMessage] = useState<string>(null)
    const locale = useContext<string>(LocaleContext)

    React.useEffect(() => {
        if (reload)
            loadAggregate()
    }, [reload]);

    React.useEffect(() => {
        setErrorMessage(null)
    }, [logicSrcVariable]);
        
    const {variables, variableOptions} = useVariables();

    function loadAggregate() {
        const finalVariable = isMclVariable ? logicSrcVariable : variable
        if(finalVariable === '')
            // do nothing when no MCL has been entered yet
            return
        SummaryService.aggregate(aggregator, finalVariable, isMclVariable, interactiveProps)
            .then(response => (response.status != 200 ?
                    { error_message: response.data ? ('Server error: ' + response.data.substring(0, 50)) : 'empty' } : response.data))
            .then((data) => {
                if(data['error_message'] == 'empty') {
                    return;// probably just cancelled, do nothing
                }
                try {
                    const valueA = data[finalVariable][aggregator]['A']
                    const valueB = data[finalVariable][aggregator]['B']
                    if(aggregator == 'avg'){
                        const sdA = data[finalVariable]['stddev']['A']
                        const sdB = data[finalVariable]['stddev']['B']
                        setStddevA(sdA === null ? NaN : sdA)
                        setStddevB(sdB === null ? NaN : sdB)
                        setErrorMessage(null)
                    }
                    setResultA(valueA)
                    setResultB(valueB)
                    setErrorMessage(null)
                } catch(e) {
                    setResultA(NaN)
                    setResultB(NaN)
                    setErrorMessage(data['error_message'])
                }
                setReload(false)
            });
    }

    function resetResults(){
        setResultA(NaN)
        setResultB(NaN)
        setStddevA(NaN)
        setStddevB(NaN)
    }

    function updateURL() {
        // @ts-ignore
        const url = new window.URL(document.location);
        const encodedParams = [variable, aggregator, logicSrcVariable].join('~')
        url.searchParams.set('single_agg', encodedParams);
        history.replaceState(null, null, url.toString());
    }

    function updateVariable(value) {
        setVariable(value)
        setIsMclVariable(value === 'dynamic')
        resetResults()
        setReload(value !== 'dynamic')
        return false
    }

    function updateAggregator(value) {
        setAggregator(value)
        resetResults()
        doReload()
        return false
    }

    function updateMclVariable(value){
        setLogicSrcVariable(value)
        resetResults()
    }

    function formatValue(val, stars = ''){
        if(isNaN(val)){
            return reload ? <Spinner/> : ">_"
        }
        if(val === null){
            return 'NaN'
        }
        const style = val < 0 ? {color: '#d00000'} : null
        return <span style={style}>{(stars ? stars + " " : "") + val.toFixed(2)}</span>
    }

    // do nothing before variables are loaded
    if (variables === undefined || variableOptions === undefined)
        return <></>

    const filteredOptions = variableOptions.map(group => ({
        label: group.label,
        options: group.options.filter(option =>
            (_.get(variables[option.value], 'variable_type', '') === 'number' &&
            !variables[option.value].dimensionless)).map(option => ({
                value: option.value,
                label: option.value + ': ' + option.label
            }
        ))
    }))
    filteredOptions.unshift({label: 'dynamic', options: [{value: 'dynamic', label: i18n.t('mcl_variable')}]})

    const defaultLabel = variables[variable] ? variables[variable]['name_' + locale] : ''
    const diff = resultA - resultB
    const stats = interactiveProps.pcIdsB ?
        significanceOfDifference(stddevA, stddevB, numCasesA, numCasesB, diff, locale)
        : confidenceIntervalOfMean(stddevA, numCasesA, locale)

    updateURL()

    return (
        <tr>
            <td width={interactiveProps.pcIdsB ? '60%;' : '80%;'}>
                <div className="row">
                    <div className="col-9">
                        <Select
                            options={filteredOptions}
                            defaultValue={{value: variable, label: variable === 'dynamic' ?  i18n.t('mcl_variable') : defaultLabel}}
                            onChange={(e) => {
                                updateVariable(e.value)
                            }}
                            isSearchable={true}
                            menuPortalTarget={document.querySelector('body')}
                        />
                        {isMclVariable &&
                            <div className="col-lg-12 form-control mt-2">
                                <MCLEditorErrorBoundary editor={
                                    <MCLEditor logicSrc={logicSrcVariable || ''}
                                        setLogicSrc={updateMclVariable}
                                        onEnter={doReload} 
                                        miniVersion={true} />
                                } />
                            </div>
                           
                        }
                    </div>
                    <div className="col-3">
                        <Select
                            name="aggregator"
                            id="aggregator_selection"
                            defaultValue={{value: aggregator, label: aggregator}}
                            isSearchable={false}
                            onChange={(e) => updateAggregator(e.value)}
                            options={['avg', 'sum', 'max', 'min', 'count', 'stddev', 'variance', 'median', 'homogeneity'].map(e => ({
                                label: e,
                                value: e
                            }))}
                            menuPortalTarget={document.querySelector('body')}
                            components={{DropdownIndicator: () => <></>}}
                        />
                    </div>
                </div>
            </td>
            {errorMessage ?
                <td className="table-warning">{errorMessage}</td>
                :
                <>{interactiveProps.pcIdsB ?
                    <>
                        <td className={"table-primary text-end"} id="dynamicVariableAggResultA">
                            {formatValue(resultA)}
                        </td>
                        <td className={"table-warning text-end"} id="dynamicVariableAggResultB">
                            {formatValue(resultB)}
                        </td>
                        <td className={"table-secondary text-end"}
                            id="dynamicVariableAggResultDiff"
                            title={aggregator == 'avg' ? stats.title : ''}>
                            {formatValue(diff, aggregator == 'avg' ? stats.stars : '')}
                        </td>
                    </>
                    :
                    <td className={"table-secondary text-end"} id="dynamicVariableAggResult"
                        title={aggregator == 'avg' ? stats.title : ''}>
                        {formatValue(resultA)}
                    </td>
                }</>
            }
        </tr>
    );
}

export default SingleVariableAggregation
