/* empty actions. at the moment, no AST is constructed. */
const MedcodelogicActions = {
    makeExpression(input, start, end, elements) {
        return {};
    },

    makeOr(input, start, end, elements) {
        return {};
    },

    makeAnd(input, start, end, elements) {
        return {};
    },

    makeComparison(input, start, end, elements) {
        return {};
    },

    makeSum(input, start, end, elements) {
        return {};
    },

    makeProduct(input, start, end, elements) {
        return {};
    },

    makeWhere(input, start, end, elements) {
        return {};
    },

    makeInTable(input, start, end, elements) {
        return {};
    },

    makeInList(input, start, end, elements) {
        return {};
    },

    makeSimpleExpression(input, start, end, elements) {
        return {};
    },

    makeVariable(input, start, end, elements) {
        return {};
    },

    makeLookup(input, start, end, elements) {
        return {};
    },

    makeFunctionCall(input, start, end, elements) {
        return {};
    },

    makeList(input, start, end, elements) {
        return {};
    },

    makeTableList(input, start, end, elements) {
        return {};
    },

    makeTable(input, start, end, elements) {
        return {};
    },

    makeCodeList(input, start, end, elements) {
        return {};
    },

    makeNumberConstant(input, start, end, elements) {
        return {};
    },

    makeStringConstant(input, start, end, elements) {
        return {};
    },

    makeCodeConstant(input, start, end, elements) {
        return {};
    },

    makeBlock(input, start, end, elements) {
        return {};
    },

    makeFunctionDefinition(input, start, end, elements) {
        return {};
    },

    makeParameterList(input, start, end, elements) {
        return {};
    },

    makeType(input, start, end, elements) {
        return {};
    },

}

export default MedcodelogicActions
