import {create} from "apisauce";

export const http = create({
  // eslint-disable-next-line no-undef
  baseURL: (medcodesearchSearchPath + 'supplements/'),
  headers: {Accept: 'application/json'},
});

export const SupplementsService = {
    get: (term, version) => http.get(version + '/search',
    {
      search: term,
      terminal_only: 1,
      max_results: 10,
      highlight: 1
    }),
    catalog: 'drg'
};
