import * as React from 'react';
import {FieldArray, FormikValues} from 'formik';
import { i18n } from "../../config/i18n";
import Permission from "./Permission";
import {IUser} from "../../interfaces";
import {useContext} from "react";
import {LocaleContext} from "../../contexts/LocaleContext";

interface Props {
    ownerDomainId: string;
    users: IUser[];
    values: FormikValues;
    setUsers: Function;
    userIdsFromPermissions: string[]
}

const Permissions: React.FunctionComponent<Props> = ({ ownerDomainId, users, values,
                                                         setUsers, userIdsFromPermissions}) => {
    const locale = useContext(LocaleContext)
    i18n.locale = locale;
    const selectedUserIds = values.permissions.map((p) => p.userId)
    const availableUsers = users.filter((u) => !selectedUserIds.includes(u.id))

    const getUsers = (id) => {
        const currentUser = users.find((u) => u.id === id)
        return currentUser ? [...availableUsers, currentUser] : availableUsers
    }

    const renderButton = (push) => (
        <span
            className="mx-2 pseudoLink"
            title={i18n.t('add_permission')}
        >
                                    <span
                                        onClick={() => push({
                                            userId: '',
                                            role: 'edit',
                                        })}
                                        className="fa fa-plus-circle fa-lg"
                                    />
        </span>
    )

    const renderEmpty = (push) => (
        <div className="alert alert-info">
            {i18n.t('add_permission')}:
            {renderButton(push)}
        </div>
    )
    return (
        <FieldArray
            name="permissions"
            render={({ push, remove }) => (
                <div>
                    {values.permissions.length > 0 ?
                        <>
                            {values.permissions.map((_, index) => (
                                <Permission
                                    key={`${index}-${values.permissions[index].userId}-${values.permissions[index].role}`}
                                    ownerDomainId={ownerDomainId}
                                    users={getUsers(values.permissions[index].userId)}
                                    index={index}
                                    values={values}
                                    remove={remove}
                                    setUsers={setUsers}
                                    userIdsFromPermissions={userIdsFromPermissions}
                                />
                            ))}
                            {renderButton(push)}
                        </>
                        : renderEmpty(push)}
                </div>
            )}
        />
    )
};

export default Permissions
