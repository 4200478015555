import {http} from './api';

export const ClassificationSystemsService = {
  createNode: (data, chapterNodeId, locale) => http.post(`pcs_nodes`, data, {
    params: {
      chapter_node_id: chapterNodeId,
      locale: locale
    }
  }),
  updateNode: (id, data, chapterNodeId, locale) => http.put(`pcs_nodes/${id}`, data, {
    params: {
      chapter_node_id: chapterNodeId,
      locale: locale
    }
  }),
  destroyNode: (id, chapterNodeId, locale) => http.delete(`pcs_nodes/${id}`, {}, {
    params: {
      chapter_node_id: chapterNodeId,
      locale: locale
    }
  }),
  updateNodes: (id, data, chapterNodeId, locale) => http.put(`classification_systems/${id}/update_nodes`, {nodes: data}, {
    params: {
      chapter_node_id: chapterNodeId,
      locale: locale
    }
  }),
};
