import { i18n } from "../config/i18n";

// The parameter "summary" contains the filtered results (N best results).
// The parameter "totalSummary" contains all results, used for CSV download.
export function drawChart(summary, summary2, attrs, type, binSize, isAdmin, totalSummary, totalSummary2, existingFilter) {
  type = type || 'bar'
  const name = attrs.name
  const dataName = attrs.dataName || name
  const values = summary[dataName]
  const values2 = summary2 ? summary2[dataName] : {}
  const totalValues = totalSummary[dataName]
  const totalValues2 = totalSummary2 ? totalSummary2[dataName] : {}
  const keys = Object.keys({...values, ...values2})
  const totalKeys = Object.keys({...totalValues, ...totalValues2})
  if (attrs.height === 'auto') {
    var height = keys.length * 20 + 100
  }
  const chartArea = attrs.chartArea || ['auto', 'auto'];
  const bar = attrs.bar || ['80%'];
  const dataTypes = attrs.types;
  const valueVariableName = attrs.valueVariableName || 'num_cases'
  const labelTypes = [name, valueVariableName];
  const hasMcl = attrs.hasMcl || false

  let data;
  let totalData;
  // Since we're adding bivariate key figures for scatter plots, we don't collect summary1 and summary2 data in one.
  // Use two data tables and join them, s.t. you can add a trendline for each series.
  if (type === 'scatter') {
    const translate = (key) => i18n.translations[i18n.locale][key.replace(/\./g,'')] === undefined ? key : i18n.t(key.replace(/\./g,''))
    const scatterTooltip = e => translate(name) + ': ' + e[0] + '\n' + translate(valueVariableName) + ': ' + e[1]
    const data1 = new google.visualization.DataTable();
    for (var i = 0; i < dataTypes.length; i++) {
      data1.addColumn(dataTypes[i], i18n.t(labelTypes[i]));
    }
    data1.addColumn( {'type': 'string', 'role': 'style'} )
    data1.addColumn( {'type': 'string', 'role': 'tooltip'} )
    data1.addRows(values.map(e => [...e, 'point {fill-color: #2d92ff}',  scatterTooltip(e)]))

    if(!!summary2){
      const data2 = new google.visualization.DataTable();
      for (var i = 0; i < dataTypes.length; i++) {
        data2.addColumn(dataTypes[i], i18n.t(labelTypes[i]));
      }
      data2.addColumn( {'type': 'string', 'role': 'style'} )
      data2.addColumn( {'type': 'string', 'role': 'tooltip'} )
      data2.addRows(values2.map(e => [...e, 'point {fill-color: #f9c10c}', scatterTooltip(e)]))
      data = google.visualization.data.join(data1,data2,'full',[[0,0]],[1,2,3],[1,2,3])
    } else {
      data = data1
    }
  } else {
    data = new google.visualization.DataTable();
    totalData = new google.visualization.DataTable();
    setColumnHeaders(data, dataTypes, labelTypes, type === 'bar', !!summary2);
    setColumnHeaders(totalData, dataTypes, labelTypes, type === 'bar', !!totalSummary2);
    setData(attrs.keys || keys, attrs.key_ids, data, summary, summary2, dataName, type === 'bar', valueVariableName, binSize);
    setData(totalKeys, undefined, totalData, totalSummary, totalSummary2, dataName, type === 'bar', valueVariableName, binSize);
  }
  let minX = attrs.hAxis[2] - (summary2 ? binSize/2.0 : 0)
  if(minX < 0)
    minX-=binSize/2.0;
  const options = setChartOptions(name, attrs.hAxis, attrs.vAxis, bar, chartArea, height, minX, attrs.trendlines, attrs.series);

  const elementId = name.replace(/\W/g,"") + (attrs.elementNameSuffix || '_chart_div');
  if (type === 'bar') {
    drawBarChart(data, options, elementId, name, attrs.sortOrder, isAdmin, totalData, existingFilter);
  } else if (type === 'column') {
    drawColumnChart(data, options, elementId, binSize, name, hasMcl, isAdmin, totalData, existingFilter);
  } else if (type === 'scatter') {
    drawScatterplot(data, options, elementId, attrs.hAxis[1], valueVariableName, hasMcl, isAdmin, existingFilter);
  }
  if (attrs.baseLink) {
    replaceLinks(elementId, keys, attrs.baseLink, attrs.ids);
  }
}

function setChartOptions(chartTitle, hAxis, vAxis, bar, chartArea, height, minX, trendlines, series) {
  const hTitle = i18n.translations[i18n.locale][hAxis[1].replace(/\./g,'')] === undefined ? hAxis[1] : (' ' + i18n.t(hAxis[1].replace(/\./g,'')))

  var options = {
    hAxis: {minValue: hAxis[0], title: hTitle, viewWindow: {min: minX}},
    vAxis: {minValue: vAxis[0], viewWindow: {min: vAxis[2]}},
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 9,
      },
    },
    colors: ['#2d92ff', '#f9c10c'],
    bar: {groupWidth: bar[0]},
    height: height,
    chartArea: {left: chartArea[0], width: chartArea[1], top: 40, bottom: 60},
    legend: {position: "none"},
    trendlines: trendlines,
    series: series
  };

  if (vAxis[1] != '') {
    const vTitle = i18n.translations[i18n.locale][vAxis[1].replace(/\./g,'')] === undefined ? vAxis[1] : (' ' + i18n.t(vAxis[1].replace(/\./g,'')))
    options['vAxis']['title'] = vTitle;
  }
  return options;
}

function setData(key_names, key_ids, data, summary, summary2, name, annotation, valueVariableName, binSize) {
  var isComparison = !!summary2;
  var attribute = summary[name];
  var usedKeys = key_ids || key_names;
  var rows = []
  const isHist = valueVariableName === 'num_cases'
  const iName = i18n.translations[i18n.locale][name.replace(/\./g,'')] === undefined ? '' : (' ' + i18n.t(name.replace(/\./g,'')))
  for (var i = 0, size = key_names.length; i < size; i++) {
    if (annotation) {
      var value = attribute[usedKeys[i]]
      var strValue = (value === undefined || value === null) ? 'NaN' : value.toFixed(2)
      const key = key_names[i].toString()
      const description = key + (i18n.translations[i18n.locale][key.replace(/\./g,'')] === undefined ? '' : (' ' + i18n.t(key.replace(/\./g,''))))
      var row = [key_names[i].toString(), value, ((isHist && !isComparison) ? percentage(value, summary.numberOfPatientcases): strValue) + (isHist ? '%' : ''),
        tooltip(strValue, iName + ':' + ' ' + description,
            (isComparison && isHist) ? 'percentage' : valueVariableName,
            summary.numberOfPatientcasesByVar ? summary.numberOfPatientcasesByVar[usedKeys[i]] : 0,
            isComparison, summary.numberOfPatientcases)];

      if (isComparison) {
        var value2 = summary2[name][usedKeys[i]]
        var strValue2 = value2 === undefined ? 'NaN' : value2.toFixed(2)
        row.push(value2);
        row.push(isHist ? strValue2 + '%' : strValue2)
        const key = key_names[i].toString()
        const description = key + (i18n.translations[i18n.locale][key.replace(/\./g,'')] === undefined ? '' : (' ' + i18n.t(key.replace(/\./g,''))))
        row.push(tooltip(strValue2,
                iName + ':' + ' ' + description, isHist ? 'percentage' : valueVariableName,
            summary2.numberOfPatientcasesByVar ? summary2.numberOfPatientcasesByVar[usedKeys[i]] : 0,
            isComparison, summary2.numberOfPatientcases));
      }
    } else {
      value = parseFloat(key_names[i])
      var description = key_names[i]
      if(binSize != 1){
        const numBinSize = parseFloat(binSize)
        const numDecimals = countDecimals(binSize)
        description = parseFloat(description).toFixed(numDecimals)
        description += " ≤ x < " + (value + numBinSize).toFixed(numDecimals)
        value += numBinSize/2.0
      }
      row = [value, attribute[key_names[i]], tooltip(attribute[usedKeys[i]],
          iName + ':' + ' ' + description,
          (isComparison && isHist) ? 'percentage' : valueVariableName,
          summary.numberOfPatientcasesByVar ? summary.numberOfPatientcasesByVar[usedKeys[i]] : 0,
          isComparison, summary.numberOfPatientcases)];

      if (isComparison) {
        if(summary2[name] == undefined){
            row.push(0);
            row.push(tooltip(attribute[usedKeys[i]], iName + ':' + ' ' + value, isHist ? 'percentage' : valueVariableName,
                summary2.numberOfPatientcasesByVar ? summary2.numberOfPatientcasesByVar[usedKeys[i]] : 0,
                isComparison, summary2.numberOfPatientcases));
        }  else {
            row.push(summary2[name][key_names[i]]);
            row.push(tooltip(summary2[name][usedKeys[i]],
                iName + ':' + ' ' + description,
                isHist ? 'percentage' : valueVariableName,
                summary2.numberOfPatientcasesByVar ? summary2.numberOfPatientcasesByVar[usedKeys[i]] : 0,
                isComparison, summary2.numberOfPatientcases));
        }
      }
    }
    rows.push(row)
  }
  data.addRows(rows);
}

var countDecimals = function (value) {
  if(Math.floor(value) === value) return 0;
  let decimalSplit = value.toString().split(".")[1]
  return decimalSplit ? value.toString().split(".")[1].length : 0;
}

// numCases is the number of cases for the current bin. It is used to show number of cases in the tooltip if the
// selected variables for yaxis isn't num_cases.
function tooltip(value, description, yaxisName, numCases, isComparison, totalNumCases) {
  const fixedValue = value ? parseFloat(value).toFixed(2) : value;
  const iYaxisName = i18n.translations[i18n.locale][yaxisName.replace(/\./g,'')] === undefined ? '' : (' ' + i18n.t(yaxisName.replace(/\./g,'')))
  const iPercentage = i18n.t('percentage');
  const iNumCases = i18n.t('num_cases');
  if (isComparison) {
    // isHist, i.e. y axis is percentage.
    if (yaxisName === 'percentage') {
      return `${description}\n${iNumCases}: ${numCases}\n${iPercentage}: ${(numCases / totalNumCases).toFixed(2)}` ;
    } else {
      return `${description}\n${iYaxisName}: ${fixedValue}\n${iNumCases}: ${numCases}\n${iPercentage}: ${(numCases / totalNumCases).toFixed(2)}` ;
    }
  } else {
    // isHist, i.e. y axis is num_cases.
    if (yaxisName === 'num_cases') {
    return `${description}\n${iNumCases}: ${numCases}`
    } else {
        return `${description}\n${iYaxisName}: ${fixedValue}\n${iNumCases}: ${numCases}`
    }
  }
}

function percentage(value, total) {
  return Math.round(value * 100 / total);
}

function setColumnHeaders(data, names, titles, annotation, isComparison) {
  for (var i = 0; i < names.length; i++) {
    data.addColumn(names[i], i18n.t(titles[i]));
  }
  if (annotation) {
    data.addColumn({type: 'string', role: 'annotation'});
  }
  data.addColumn({type: 'string', role: 'tooltip'});

  if (isComparison) {
    data.addColumn(names[names.length - 1], i18n.t(titles[names.length - 1]));
    if (annotation) {
      data.addColumn({type: 'string', role: 'annotation'});
    }
    data.addColumn({type: 'string', role: 'tooltip'});
  }
}

function addPNGDownload(chart, elementId) {
  // create PNG download icon if png_download element is present
  const pngElement = document.getElementById(elementId.replace(/\W/g,""))
  if(pngElement) {
    google.visualization.events.addListener(chart, 'ready', function () {
      pngElement.innerHTML = '<a target="png" href="' + chart.getImageURI() + '"><i class="fa fa-image"></i></a>';
    });
  }
}

function addCSVDownload(data, elementId, hasAnnotation) {
  // create CSV download icon if csv_download element is present
  const csvDonwload = document.getElementById(elementId.replace(/\W/g,""))
  if(csvDonwload){
    var csvColumns = '';
    const num_columns = data.getNumberOfColumns()
    const isComparison = num_columns > 4
    const columnIndexes = [0,1]
    if(isComparison){
      columnIndexes.push(hasAnnotation ? 4 : 3)
    }
    const lastColumn = columnIndexes[columnIndexes.length - 1]
    /* skip last column which is the description. */
    for (const i of columnIndexes) {
      csvColumns += '"' + data.getColumnLabel(i).replace(/,/g,'') + '"';
      if (i !== lastColumn) {
        csvColumns += ',';
      }
    }
    csvColumns += '\n';
    var csvBody = '';
    for (var r = 0; r < data.getNumberOfRows(); r++) {
      for (const c of columnIndexes) {
        const value = data.getFormattedValue(r, c)
        if(data.getColumnType(c) === 'string')
          csvBody += '"' + value + '"';
        else
          csvBody += value.replace(/,/g,'');
        if (c !== lastColumn) {
          csvBody += ',';
        }
      }
      csvBody += '\n';
    }

    const csvContent = csvColumns + csvBody;
    csvDonwload.innerHTML = '<a href="data:text/csv;charset=utf-8,' + encodeURI(csvContent) + '"><i class="fa fa-file-excel-o"></i></a>';
  }
}

export function drawBarChart(data, options, elementId, variableName, sortOrder, isAdmin, totalData, existingFilter) {
  const {filteredPage, filter} = existingFilter;
  if (document.getElementById(elementId)) {
    var chart = new google.visualization.BarChart(document.getElementById(elementId));
    data.sort([{column: 1, desc: sortOrder === 'desc'}])
    if (isAdmin) {
      addPNGDownload(chart, variableName + '_png_download')
    }

    chart.draw(data, options);

    if(filteredPage){ 
      google.visualization.events.addListener(chart, 'select', function (e) {
        var selection = chart.getSelection();
        if (selection.length) {
          const row = selection[0].row;
          let value = data.getValue(row, 0);
          // chain existing filter together
          var logicSrc = (filter === null || filter.match(/^ *$/) !== null) ? '' : filter + ' and ';
          // URL encoded assignment of: {"rule"=>{"logic_src"=>"variableName = value"}
          var varForFilter = variableName;
          const inListVars = ['diagnoses', 'secondary_diagnoses', 'main_diagnosis', 'procedures', 'drg', 'tarpos', 'drugs', 'tags', 'supplements']
          inListVars.forEach(inListVar => {
            if(variableName.includes(inListVar)){
              varForFilter = inListVar;
            }})
          const inListOp = inListVars.includes(varForFilter)
          const stringOrCodeValue = value === value.replace(/[^a-zA-Z0-9.]/g, '') ? value : "'" + value + "'" 
          logicSrc += inListOp ? (varForFilter + " in list(" + stringOrCodeValue + ")") : (variableName + " = '" + value + "'")

          var params = window.location.search.replace(/rule%5B(conditions|logic_src)%5D[^&]*(&|$)/g, '')
          params += params.includes('?') ? '&' : '?'
          window.open( params + encodeURI("rule[logic_src]=") + encodeURIComponent(logicSrc), "_blank");
        }
      });
    }
    addCSVDownload(totalData, variableName + '_csv_download', true)
  } else {
    console.error("no element present with id " + elementId)
  }
}

export function drawColumnChart(data, options, elementId, binSize, variableName, hasMcl, isAdmin, totalData, 
  existingFilter) {
  const {filteredPage, filter} = existingFilter;
  if (document.getElementById(elementId)) {
    var chart = new google.visualization.ColumnChart(document.getElementById(elementId));
    if (isAdmin) {
      addPNGDownload(chart, variableName + '_png_download')
    }

    chart.draw(data, options);

    if (filteredPage) {
      // set callback for automatic filter creation when clicking on specific values in a column chart.
      google.visualization.events.addListener(chart, 'select', function (e) {
        var selection = chart.getSelection();
        if (selection.length) {
          const row = selection[0].row;
          let value = data.getValue(row, 0);
          // chain existing filter together
          let link = ''
          const snakeCaseVariable = (['costs_hist', 'costsHist'].includes(variableName)) ?
            'total_costs' : variableName;
          const numBinSize = parseFloat(binSize)
       
          if (!window.location.search.includes('logic_src') && !hasMcl) { // condition: is not MCL dynamic variable
            
            if (binSize != undefined) {
              if (binSize != 1) {
                value -= numBinSize / 2.0
              }
              link = "rule[conditions][][variable]=" + snakeCaseVariable;
              link += "&rule[conditions][][operator]=>=";
              link += "&rule[conditions][][value]=" + value;

              link += "&rule[conditions][][variable]=" + snakeCaseVariable;
              link += "&rule[conditions][][operator]=<";
              link += "&rule[conditions][][value]=" + (parseFloat(value) + numBinSize);
            } else {
              link = "rule[conditions][][variable]=" + snakeCaseVariable;
              link += "&rule[conditions][][operator]==";
              link += "&rule[conditions][][value]=" + value;
              //link += "&rule[conditions][][type]=number";
            }
            link = encodeURI(link)
          } else {
            var logicSrc = (filter === null || filter.match(/^ *$/) !== null) ? '' : filter + ' and ';
            // URL encoded assignment of: {"rule"=>{"logic_src"=>"variableName = value"}
            if (binSize != undefined) {
              const corrValue = parseFloat(value) - numBinSize / 2.0;
              logicSrc += snakeCaseVariable + " >= " + corrValue + " and " + snakeCaseVariable + " < "
                + (corrValue + numBinSize)
            }
            else
              logicSrc += snakeCaseVariable + " = " + value
            link = encodeURI("rule[logic_src]=") + encodeURIComponent(logicSrc)
          }

          var params = window.location.search.replace(/rule%5Blogic_src%5D[^&]*(&|$)/g, '')
          params += params.includes('?') ? '&' : '?'
          window.open(params + link, "_blank");
        }
      });
    }
    addCSVDownload(totalData, variableName + '_csv_download', false, totalData)

  } else {
    console.error("no element present with id " + elementId)
  }
}

export function drawScatterplot(data, options, elementId, variableName, yVariableName, hasMcl, isAdmin, existingFilter)
 {
  const {filteredPage, filter} = existingFilter;

  if (document.getElementById(elementId)) {
    var chart = new google.visualization.ScatterChart(document.getElementById(elementId));
    options.dataOpacity = 0.3
    // TODO: Currently legend has given width and is cut with "...", even if there is enough space.
    //  Don't know how to change this?!
    options.legend = {position: 'right'}
    options.chartArea = {  width: "60%", height: "70%" }
    if (isAdmin) {
      addPNGDownload(chart, variableName + '_scatter_png_download')
    }

    chart.draw(data, options);

    if(filteredPage){
      google.visualization.events.addListener(chart, 'select', function (e) {
        var selection = chart.getSelection();
        if (selection.length) {
          const row = selection[0].row;
          const column = selection[0].column;
          const xValue = data.getValue(row, 0)
          const yValue = data.getValue(row, column)
          // chain existing filter together

          let link = ''
          if(!window.location.search.includes('logic_src') && !hasMcl){
            if(![">", "<", "="].some(e => variableName.includes(e))) {
              link += "rule[conditions][][variable]=" + variableName;
              link += "&rule[conditions][][operator]==";
              link += "&rule[conditions][][value]=" + xValue;
            }
            if(![">", "<", "="].some(e => yVariableName.includes(e))) {
              link += "&rule[conditions][][variable]=" + yVariableName;
              link += "&rule[conditions][][operator]==";
              link += "&rule[conditions][][value]=" + yValue;
            }
            link = encodeURI(link)
          } else {
            var logicSrc = (filter === null || filter.match(/^ *$/) !== null) ? '' : filter + ' and ';
            // URL encoded assignment of: {"rule"=>{"logic_src"=>"variableName = value"}
            logicSrc += variableName
            // only add comparison if this is not already a comparison
            if(![">", "<", "="].some(e => variableName.includes(e)))
              logicSrc += " = " + xValue
            // chain y variable condition
            logicSrc += ' and ' + yVariableName
            if(![">", "<", "="].some(e => yVariableName.includes(e)))
              logicSrc += " = " + yValue

            link = encodeURI("rule[logic_src]=") + encodeURIComponent(logicSrc)
          }

          var params = window.location.search.replace(/rule%5Blogic_src%5D[^&]*(&|$)/g, '')

          params += params.includes('?') ? '&' : '?'
          window.open( params + link, "_blank");
        }
      });
    }
  } else {
    console.error("no element present with id " + elementId)
  }
}

function replaceLinks(id, keys, baseLink, ids) {
  $('#' + id).find('text').each(function (i, el) {
    if (keys.indexOf(el.textContent) != -1) {
      if (ids[el.textContent] === undefined) {
        return;
      }
      var parent = el.parentNode;
      var ns = 'http://www.w3.org/1999/xlink';
      var link = baseLink.replace('placeholder', ids[el.textContent]);
      var a = document.createElementNS('http://www.w3.org/2000/svg', 'a');
      a.setAttributeNS(ns, 'xlink:href', link);
      a.setAttributeNS(ns, 'title', el.textContent);
      a.setAttribute('href', link);
      a.setAttribute('target', '_blank');
      a.setAttribute('class', 'chart-link');
      a.appendChild(parent.removeChild(el));
      parent.appendChild(a);
    }
  });
}

export function drawBoxPlot(data, columnTitles, name) {
  google.charts.load('current', {'packages': ['corechart']});
  google.charts.setOnLoadCallback(function () {
    return drawBoxPlotCall(data, columnTitles, name);
  });

  function processData(data, title) {
    data = [...data] // copy array before editing
    data.unshift(0);
    data.unshift(title);
    return data;
  }

  function drawBoxPlotCall(data, columnTitles, name) {
    var dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', 'x');
    dataTable.addColumn('number', 'series0');
    dataTable.addColumn({id: 'min', type: 'number', role: 'interval'});
    dataTable.addColumn({id: 'firstQuartile', type: 'number', role: 'interval'});
    dataTable.addColumn({id: 'median', type: 'number', role: 'interval'});
    dataTable.addColumn({id: 'thirdQuartile', type: 'number', role: 'interval'});
    dataTable.addColumn({id: 'max', type: 'number', role: 'interval'});
    dataTable.addRow(processData(data[0], columnTitles[0]));
    if (data[1]) {
      dataTable.addRow(processData(data[1], columnTitles[1]));
    }

    var options = {
      height: 200,
      legend: {position: 'none'},
      hAxis: {
        gridlines: {color: '#fff'}
      },
      lineWidth: 0,
      series: [{'color': '#2f91f7'}],
      intervals: {
        barWidth: 1,
        boxWidth: 1,
        lineWidth: 2,
        style: 'boxes',
        color: '#2f91f7',
      },
      interval: {
        max: {
          style: 'bars',
          fillOpacity: 1,
          color: '#777'
        },
        min: {
          style: 'bars',
          fillOpacity: 1,
          color: '#777'
        }
      }
    };

    var chart = new google.visualization.LineChart(document.getElementById('box_plot'));
    chart.draw(dataTable, options);
  }
}

// actually not stacked anymore!
export function drawStacked(name, title, data, columnTitles, key_ids, existingFilter) {
  const {filteredPage, filter} = existingFilter;
  drawStackedMain(name, title, data, columnTitles);

  function getKeys(data) {
    var keys = Object.keys(data);
    keys.unshift(title);
    keys.push({role: 'annotation'});
    return keys;
  }

  function getValues(data, title) {
    var values = Object.values(data);
    values.unshift(title);
    values.push('');
    return values;
  }

  function drawStackedMain(name, title, data, columnTitles) {
    const elementName = name + "_chart_div"
    columnTitles = columnTitles ? columnTitles : [];
    var keys = getKeys(data[0]);
    var processedData = [keys, getValues(data[0], columnTitles[0])];
    if (data[1]) {
      var data1 = Object.keys(data[0]).map(function (key) {
        return data[1][key];
      });
      data1.unshift(columnTitles[1]);
      data1.push('');
      processedData.push(data1);
    }

    var dataTable = google.visualization.arrayToDataTable(processedData);

    var chartArea = columnTitles.length && !!columnTitles[0] && columnTitles[0].length > 3 ? {left: '25%', width: '80%'} : {left: '4%', right: '4%'};
    var options = {
      chartArea: chartArea,
      legend: {position: 'top', maxLines: 3},
      bar: {groupWidth: '75%'},
      hAxis: {
        ticks: [0, .25, .5, .75, 1]
      },
      series: {
        0: {color: '#efdcdc'},
        1: {color: '#d7e8f8'},
        2: {color: '#afd4fa'},
        3: {color: '#6baef1'},
        4: {color: '#4289d2'},
        5: {color: '#1d66a6'},
        6: {color: '#014481'},
      },
      height: 120
    };

    var chart = new google.visualization.BarChart(document.getElementById(elementName));
    chart.draw(dataTable, options);

    if (filteredPage) {
      // set callback for automatic filter creation when clicking on specific values in a bar chart.
      google.visualization.events.addListener(chart, 'select', function (e) {
        const selection = chart.getSelection();
        if (selection.length) {
          const column = selection[0].column;
          let value = (key_ids == null) ? keys[column] : "'" + key_ids[column - 1] + "'"
          // Reverse camel case conversion (f.e. 'w' to 'W').
          // TODO: NO LONGER VALID IF LOWER CASE VALUES PRESENT
          if (typeof value === 'string') {
            value = value.toUpperCase()
          }
          const variableName = ['adm', 'sep'].includes(name) ? name + '_mode' : name
          // URL encoded assignment of: {"rule"=>{"logic_src"=>"variableName = value"}
          // chain existing filter together
          let link = ''
          if (!window.location.search.includes('logic_src')) {
            link = "rule[conditions][][variable]=" + variableName;
            link += "&rule[conditions][][operator]==";
            link += "&rule[conditions][][value]=" + value;
            //link += "&rule[conditions][][type]=string";
            link = encodeURI(link)
          } else {
            var logicSrc = (filter === null || filter.match(/^ *$/) !== null) ? '' : filter + ' and ';
            logicSrc += variableName + " = " + value
            link = encodeURI("rule[logic_src]=") + encodeURIComponent(logicSrc)
          }

          var params = window.location.search.replace(/rule\[logic_src\][^&]*(&|$)/g, '')
          params += params.includes('?') ? '&' : '?'
          window.open(params + link, "_blank");
        }
      });
    }
  }
}
