import * as React from 'react';

interface Props {
    size?: number;
}

const Spinner: React.FunctionComponent<Props> = props => {
    const size = props.size || 2;
    return (
        <i className={`fa fa-spinner fa-spin fa-${size}x fa-fw`}/>
    )
};

export default Spinner
