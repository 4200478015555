import * as React from 'react';
import {LocaleProvider} from '../contexts/LocaleContext';
import {UserProvider} from '../contexts/UserContext';
import { i18n } from "../config/i18n";
import {IUser, IYearCatalogs} from "../interfaces";
import Spinner from '../components/Spinner';
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";
import Summary, { ISummaryProps } from '../components/summary-of-cases/Summary';
import { VariablesProvider } from '../contexts/VariableContext';


interface Props {
    locale: string,
    user: IUser,
    catalogs: IYearCatalogs,
    summaryProps: ISummaryProps
}

const SummaryPage: React.FunctionComponent<Props> = (props) => {
    const {locale, user, summaryProps, catalogs} = props;
    i18n.locale = locale;

    return (
        <LocaleProvider value={locale}>
            <YearCatalogsProvider value={catalogs}>
                <UserProvider value={user}>
                    <VariablesProvider>
                        <React.Suspense fallback={<Spinner/>}>
                            <Summary {...summaryProps} />
                        </React.Suspense>
                    </VariablesProvider>    
                </UserProvider>
            </YearCatalogsProvider>
        </LocaleProvider>
    )

}

export default SummaryPage
