import { i18n } from "../config/i18n";

export default function transformItems(items) {
  if(items == null) {
    // access to search.eonum.ch failed
    return []
  }
  return items.map((v) => {
    var text = '<strong>' + v.code + '</strong> ' + v.text;
    var highlight = v['highlight'];
    if (highlight != null) {
      var highlight_text = highlight['text'];
      if (highlight_text != null) {
        for (var i = 0; i < highlight_text.length; i++) {
          var text_original = highlight_text[i].replace('<em>', '').replace('</em>', '');
          text = text.replace(text_original, highlight_text[i]);
        }
      } else {
        var alternative_fields = Object.keys(highlight);
        for (var k = 0; k < alternative_fields.length; k++) {
          var field = alternative_fields[k];
          if (field == 'code') { continue; }
          if (highlight[field] != null) {
            var hf = highlight[field];
            if (!Array.isArray(hf))
              hf = [hf];
            text += '<div class="alternative_search_highlight"><strong>' + i18n.t(field) + ': </strong>';
            for (var j = 0; j < hf.length; j++) {
              text += hf[j] + '<br/>';
            }
            text += '</div>';
          }
        }
      }
    }
    return {
      text: text,
      code: v.code
    };
  });
}
