import * as React from 'react';
import 'react-sortable-tree/style.css';
import NodeRenderer from "../components/classification-system/NodeRenderer";
import {
    removeNodeAtPath,
    changeNodeAtPath,
    toggleExpandedForAll,
    getFlatDataFromTree,
    addNodeUnderParent
} from 'react-sortable-tree';
import {ClassificationSystemsService} from '../services/ClassificationSystemsService';
import {toast} from "react-toastify";
import { i18n } from "../config/i18n";
import {LocaleProvider} from "../contexts/LocaleContext";
import _ from 'lodash'

import SortableTree from 'react-sortable-tree';
import {useEffect, useRef, useState} from "react";
import {YearCatalogsProvider} from "../contexts/YearCatalogsContext";
import {IYearCatalogs} from "../interfaces";
import { VariablesProvider } from '../contexts/VariableContext';
import { MedcodelogicLoader } from '../contexts/MedcodelogicLoaderContext';

interface Props {
    data: any,
    id: any,
    chapter_node_id?: any,
    link?: any
    locale: string,
    multilabel: boolean,
    table_scope: string,
    catalogs: IYearCatalogs
}

const ClassificationSystem: React.FunctionComponent<Props> = (props) => {
    const [isRequesting, setIsRequesting] = useState<boolean>(false)
    const [treeData, setTreeData] = useState<any>(toggleExpandedForAll({
        treeData: [props.data]
    }))
    const anchorHash = useRef<string>('')

    const {locale, id, chapter_node_id, multilabel} = props;

    useEffect(() => {
        i18n.locale = props.locale;
        scrollToAnchor()
    }, [])

    function scrollToAnchor() {
        const hash = window.location.hash;

        if (hash) {
            // We want to reset if the hash has changed
            if (anchorHash.current !== hash) {
                anchorHash.current = hash
                const id = hash.replace('#', '');
                const element = document.getElementById(`noanchor_${id}`);
                if (element) {
                    element.style.outlineColor = '#00c9e4';
                    element.style.outlineWidth = '4px';
                    element.style.outlineStyle = 'solid'
                    const y = element.getBoundingClientRect().top + window.scrollY - 50;
                    window.scrollTo({top: y, behavior: 'smooth'});
                }
            }
        }
    }

    function onChange(newTreeData) {
        const {chapter_node_id, id} = props;
        // avoid unnecessary requests when just dragging some pixels
        if (_.isEqual(newTreeData, treeData)) {
            return;
        }
        setTreeData(newTreeData);
        proceedUpdate(() =>
            ClassificationSystemsService.updateNodes(
                id, getFlatDataFromTree(
                    {
                        treeData: newTreeData,
                        getNodeKey: node => node.id,
                    }
                ),
                chapter_node_id
            )
        );
    }

    function removeNode(rowInfo) {
        const {chapter_node_id} = props;
        const {node, path} = rowInfo;
        setTreeData(removeNodeAtPath({
            treeData: treeData,
            path: path,
            getNodeKey: ({treeIndex}) => treeIndex,
        }));

        proceedUpdate(() => ClassificationSystemsService.destroyNode(node.id, chapter_node_id))
    }

    function proceedUpdate(action) {
        setIsRequesting(true);
        action()
            .then(response => {
                if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                    toast('Something went wrong', {type: 'error'});
                    setIsRequesting(false);
                }
                return response;
            })
            .then(response => response.data)
            .then((data) => {
                if (data && data.nodes) {
                    setIsRequesting(false);
                    setTreeData(toggleExpandedForAll({
                        treeData: [data.nodes]
                    }))
                }
            });
    }

    function changeNode(rowInfo, data) {
        const {node, path} = rowInfo;
        setTreeData(changeNodeAtPath({
            treeData: treeData,
            path: path,
            newNode: Object.assign({}, node, data),
            getNodeKey: ({treeIndex}) => treeIndex,
        }))
    }

    function callService(values) {
        const {chapter_node_id} = props;
        const {id} = values;
        if (id) {
            return ClassificationSystemsService.updateNode(id, values, chapter_node_id);

        } else {
            return ClassificationSystemsService.createNode(values, chapter_node_id);
        }
    }

    function updateNode(data) {
        proceedUpdate(() => callService(data));
    }

    function addNode(rowInfo) {
        const {node} = rowInfo;
        setTreeData(addNodeUnderParent({
            treeData: treeData,
            newNode: {
                parent_id: node.id,
                editable: true,
            },
            parentKey: rowInfo ? rowInfo.treeIndex : undefined,
            getNodeKey: ({treeIndex}) => treeIndex,
            addAsFirstChild: true
        }).treeData)
    }

    function canDrop(nextParent) {
        const {chapter_node_id} = props;
        return !!nextParent || !!chapter_node_id
    }

    function canDrag(node) {
        return !node.editable && !isRequesting;
    }

    return (
        <LocaleProvider value={locale}>
            <YearCatalogsProvider value={props.catalogs}>
                <VariablesProvider>
                    <MedcodelogicLoader tableScope={props.table_scope}>
                        <SortableTree
                            treeData={treeData}
                            isVirtualized={false}
                            onChange={onChange}
                            nodeContentRenderer={NodeRenderer}
                            scaffoldBlockPxWidth={140}
                            canDrop={({ nextParent }) => canDrop(nextParent)}
                            canDrag={({ node }) => canDrag(node)}
                            generateNodeProps={rowInfo => ({
                                removeNode: () => removeNode(rowInfo),
                                changeNode: (data) => changeNode(rowInfo, data),
                                addNode: () => addNode(rowInfo),
                                updateNode: updateNode,
                                id: id,
                                isRequesting: isRequesting,
                                chapter_node_id: chapter_node_id,
                                multilabel: multilabel
                            })}
                        />
                    </MedcodelogicLoader>
                </VariablesProvider>
            </YearCatalogsProvider>
        </LocaleProvider>
    );

}

export default ClassificationSystem
