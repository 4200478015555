import * as React from 'react';
import { i18n } from "../config/i18n";
import TabMenuItem from "./TabMenuItem";

interface Props {
    urlEncodedPc: string;
    active: string;
    caseNumber: string;
    id: number;
    links?: {[Identifier: string] : string};
}

const TabMenu: React.FunctionComponent<Props> = props => {
    const {urlEncodedPc, active, caseNumber, id, links} = props;
    const webgrouperLink = links.webgrouper + `&pc=${urlEncodedPc}`
    return (
        <>
            {caseNumber && <h2>
                {i18n.t("case")}
                {' '}
                {caseNumber}</h2>}

            <div className="col-lg-12">
                <ul className="nav nav-tabs flex-wrap">
                    <TabMenuItem
                        active={active}
                        text={i18n.t("selection")}
                        path={links.index}
                        name="index"
                    />
                    <TabMenuItem
                        active={active}
                        urlEncodedPc={urlEncodedPc}
                        text={i18n.t("case_analysis")}
                        path={id ? links.analyze : links.analyzeNew}
                        name="analyze"
                    />

                    <TabMenuItem
                        active={active}
                        urlEncodedPc={urlEncodedPc}
                        text={i18n.t("code_proposal")}
                        path={links.codingProposals}
                        name="proposals"
                        title={i18n.t("code_proposal_description")}
                    />

                    <TabMenuItem
                        active={active}
                        urlEncodedPc={urlEncodedPc}
                        text={i18n.t("prognosis")}
                        path={links.losPredictions}
                        name="predictions"
                        title={i18n.t("prognosis")}
                    />

                    <TabMenuItem
                        active={active}
                        urlEncodedPc={urlEncodedPc}
                        text={"PCS " + i18n.t("group")}
                        path={links.pcsGroup}
                        name="pcs-group"
                        title={"PCS " + i18n.t("group")}
                    />

                    {id && <TabMenuItem
                        active={active}
                        urlEncodedPc=""
                        text={i18n.t("detail_view")}
                        path={links.detail}
                        name="detail"
                        title={i18n.t("detail_view")}
                    />}

                    <div className="ms-auto">
                        <a target="swissdrg" className="btn btn-outline-secondary" id="grouper_link"
                           href={webgrouperLink}>{links.webgrouper.includes("streha") ?
                            i18n.t('edit_in_reha_webgrouper') : i18n.t("edit_in_webgrouper")}</a>
                    </div>
                </ul>
            </div>
        </>
    );
};

export default TabMenu
