import {Field, FieldArray} from "formik";
import * as React from "react";
import {useContext} from "react";
import {LocaleContext} from "../contexts/LocaleContext";
import { i18n } from "../config/i18n";

interface Props {
    tags: string[];
}

export const TagsArray: React.FunctionComponent<Props> =
    ({tags}) => {
        const locale = useContext(LocaleContext)
        i18n.locale = locale;
        return (
            <>
                <label htmlFor="tags">{i18n.t('tags')}</label>
                <FieldArray
                    name={"tags"}
                >
                    {({remove, push}) => (
                        <div>
                            {tags.length > 0 &&
                                tags.map((tag, index) => (
                                    <div className='col-lg-2 vertical-spacer' key={index}>
                                        <div className="btn-group">
                                            <Field
                                                name={`tags.${index}`}
                                                type="text"
                                                className={'form-control listsOnly'}
                                                style={{marginRight: '2px'}}
                                            />
                                            <div className={"btn-group"} onClick={() => remove(index)}>
                                                <span className="fa fa-remove removetag"/>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            <div className={"col-lg-1 pseudoLink"} onClick={() => push("")}>
                                <span className="fa fa-plus-circle fa-lg"/>
                            </div>
                        </div>
                    )}
                </FieldArray>
            </>
        );
    };

export default TagsArray;


