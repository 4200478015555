import * as React from 'react';
import DiagnoseAutocomplete from "../../form-fields/DiagnoseAutocomplete";
import ColorMeter from "./ColorMeter";
import { i18n } from "../../config/i18n";;
import {IDiagnosis} from "../../interfaces";

interface Props {
    diagnose: IDiagnosis,
    index: number,
    setFieldValue: Function,
    mainDiagnosisCode?: string,
    submitForm: Function,
    colorMeterEnabled?: boolean,
    highlight?: boolean;
}

const Diagnose: React.FunctionComponent<Props> = props => {

    async function switchCode() {
        const {mainDiagnosisCode, setFieldValue, submitForm, index, diagnose} = props;
        setFieldValue('main_diagnosis.code', diagnose.code);
        setFieldValue(`secondary_diagnoses[${index}].code`, mainDiagnosisCode);
        await Promise.resolve();
        submitForm();
    }

        const {diagnose, index, setFieldValue, colorMeterEnabled, submitForm, highlight} = props;
        return (

        <div key={index}>
            <div className={'code_input_div row ' + (!diagnose.active ? 'disabled' : '')}>
                <div className="col-sm-3">
                    <div className="input-group">
                        <DiagnoseAutocomplete
                            name={`secondary_diagnoses[${index}].code`}
                            value={diagnose.code || ""}
                            valid={diagnose.code && diagnose.valid}
                            used={diagnose.used}
                            setValue={setFieldValue}
                            highlight={highlight}
                            submitForm={submitForm}
                        />
                        <span className="fa fa-check removetag me-3" title={i18n.t(`${diagnose.active ? 'de' : ''}activate_code`)}
                              onClick={async () => {
                                  setFieldValue(`secondary_diagnoses[${index}].active`, !diagnose.active);
                                  await Promise.resolve();
                                  submitForm();
                              }}/>
                        <span className="fa fa-remove removetag" title={i18n.t("remove_code")}
                              onClick={async () => {
                                  setFieldValue(`secondary_diagnoses[${index}].code`, '');
                                  await Promise.resolve();
                                  submitForm();
                              }}/>
                    </div>
                </div>
                <div className="col-sm-1 px-0">
                    {colorMeterEnabled && diagnose.color && <ColorMeter color={diagnose.color}/>}
                    {diagnose.ccl != undefined && <span
                      className='ccl-info'>CCL {diagnose.ccl}
                    </span>}
                </div>
                {diagnose.swap_pdx && <div
                  className="swap_sdx col-sm-1"
                  title={i18n.t("swap_sdx")}
                  onClick={switchCode}
                >
                  <img src="/images/icons/arrow_switch.png"/>{diagnose.swap_pdx}
                </div>}
                <div className="col-sm-6 code_description">
                    <a href={diagnose.url} target="diagnose">{diagnose.text}</a>
                </div>
            </div>
        </div>
    );
}

export default Diagnose
