import * as React from 'react';
import { i18n } from "../../config/i18n";
import {ISummary, IYearCatalogs} from "../../interfaces";
import CodesTable from "./CodesTable";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useContext} from "react";
import {YearCatalogsContext} from "../../contexts/YearCatalogsContext";
import {IInteractiveSummaryProps} from "../summary-of-cases/Summary";

interface Props {
    summary: ISummary,
    summary2: ISummary,
    isAdmin: boolean,
    codeInfos: {[key: string] : [string, [string[], string][]][]},
    interactiveProps?: IInteractiveSummaryProps,
}

const CodesTables: React.FunctionComponent<Props> = props => {
    const {summary, summary2, isAdmin, codeInfos, interactiveProps} = props;
    const isDynamic = interactiveProps != undefined
    const catalogs = useContext<IYearCatalogs>(YearCatalogsContext)

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <strong>{i18n.t('common_codes')}</strong>
                        </div>
                        <div className="card-body p-3 tab-content" id="dataTableCollapse">
                            <Tabs
                                defaultActiveKey="drgs"
                                id="codes-lists"
                                className="mb-3"
                            >
                                <Tab eventKey="drgs" title={i18n.t('drgs') + ' ' + catalogs.drg}>
                                    <CodesTable
                                        title={'drg'}
                                        setA={{
                                            data: summary.commonDrg,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: summary2.commonDrg,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        codeInfos={codeInfos['drg']}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>
                                {(summary.commonPdx.length > 0 || (summary2 ? summary2.commonPdx.length > 0 : false)) &&
                                    <Tab eventKey="pdxs" title={i18n.t('pdxs') + ' ' + catalogs.icd}>
                                    <CodesTable
                                        title={'pdxs'}
                                        setA={{
                                            data: summary.commonPdx,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: summary2.commonPdx,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        codeInfos={codeInfos['pdx']}
                                        numCasesTitle={'num_codings'}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>}
                                {(summary.commonSdx.length > 0 || (summary2 ? summary2.commonSdx.length > 0 : false)) &&
                                <Tab eventKey="additional_diagnoses" title={i18n.t('additional_diagnoses') + ' ' + catalogs.icd}>
                                    <CodesTable
                                        title={'additional_diagnoses'}
                                        setA={{
                                            data: summary.commonSdx,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: summary2.commonSdx,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        codeInfos={codeInfos['sdx']}
                                        numCasesTitle={'num_codings'}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>}
                                {(summary.commonSrg.length > 0 || (summary2 ? summary2.commonSrg.length > 0 : false))  &&
                                    <Tab eventKey="procedures" title={i18n.t('procedures') + ' ' + catalogs.chop.replace('_', ' ')}>
                                    <CodesTable
                                        title={'procedures'}
                                        setA={{
                                            data: summary.commonSrg,
                                            numberOfPatientcases: summary.numberOfPatientcases
                                        }}
                                        setB={summary2 ? {
                                            data: summary2.commonSrg,
                                            numberOfPatientcases: summary2.numberOfPatientcases
                                        } : null}
                                        summaryName1={summary.name}
                                        summaryName2={summary2? summary2.name : ''}
                                        codeInfos={codeInfos['srg']}
                                        numCasesTitle={'num_codings'}
                                        interactiveProps={interactiveProps}
                                    />
                                </Tab>}
                                {((summary.commonHospitals.length > 0 || (summary2 ?
                                            summary2.commonHospitals.length > 0 : false     ))
                                        // Only show hospitals if admin or summary is computed on the go,
                                        // i.e. no data breach.
                                        && (isAdmin || isDynamic)) &&
                                    <Tab eventKey="hospitals" title={i18n.t('hospitals')}>
                                        <CodesTable
                                            title={'hospitals'}
                                            setA={{
                                                data: summary.commonHospitals,
                                                numberOfPatientcases: summary.numberOfPatientcases
                                            }}
                                            setB={summary2 ? {
                                                data: summary2.commonHospitals,
                                                numberOfPatientcases: summary2.numberOfPatientcases
                                            } : null}
                                            summaryName1={summary.name}
                                            summaryName2={summary2? summary2.name : ''}
                                            codeInfos={codeInfos['hospitals']}
                                            interactiveProps={interactiveProps}
                                        />
                                    </Tab>}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CodesTables
