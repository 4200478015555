import * as React from 'react';
import { i18n } from "../../config/i18n";
import {ISummary} from "../../interfaces";
import {InnerSummaryTable, SummaryTable} from "../summary-and-codes-tables/SummaryTable";
import {IInteractiveSummaryProps} from "./Summary"
import SingleVariableAggregation from "./SingleVariableAggregation";
import {LocaleContext} from "../../contexts/LocaleContext";
import {useContext} from "react";

interface Props {
    summary: ISummary,
    summary2: ISummary
    interactiveProps?: IInteractiveSummaryProps
}

const BasicData: React.FunctionComponent<Props> = (props) => {
    const {summary, summary2, interactiveProps} = props;
    const locale = useContext<string>(LocaleContext)

    i18n.locale = locale;

    const titles_by_value = {
        'numberOfPatientcases': i18n.t('num_cases'),
        'avgLos': i18n.t('avg_duration'),
        'pccl': i18n.t('pccl'),
        'avgSdx': i18n.t('avg_number') + ' ' + i18n.t('additional_diagnoses'),
        'avgSrg': i18n.t('avg_number') + ' ' + i18n.t('procedures'),
        'avgEcw': i18n.t('avg_ecw'),
        'avgAgeDays': i18n.t('age_days') + ' (' + i18n.t('average') + ')',
        'avgAgeYears': i18n.t('age_years') + ' (' + i18n.t('average') + ')',
        'numberOfDistinctHospitals': i18n.t('distinct') + ' ' + i18n.t('hospitals'),
        'dmiReha': i18n.t('DMI') + ' ST Reha'
    };

    const values1 = ['numberOfPatientcases', 'numberOfDistinctHospitals'];
    const values2 = ['avgLos', 'avgSdx', 'avgSrg', 'avgEcw'];
    const values3 = ['pccl'];
    if (!(summary.commonHospitals.length > 0))
        delete values1[1]

    if (summary.dmiReha)
        values2.push('dmiReha')

    const options_by_value = {
        'numberOfPatientcases': {'round': null, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgLos': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'pccl': {
            'round': 2,
            'type': 'collection',
            'key_names': [0, 1, 2, 3, 4, 5, 6],
            'key_ids': null,
            'sd': null
        },
        'avgSdx': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgSrg': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgEcw': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'dmiReha': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgAgeDays': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgAgeYears': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': 'sdAgeYears'},
        'numberOfDistinctHospitals': {'round': null, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null}
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <strong>
                    {i18n.t('key_figures')}
                </strong>
            </div>
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="table-responsive">
                            <SummaryTable
                                summary={summary}
                                summary2={summary2}
                                titles_by_value={titles_by_value}
                                values={values1}
                                options_by_value={options_by_value}
                                disableHeader={false}
                            />
                        </div>
                        <div className="table-responsive">
                            <SummaryTable
                                summary={summary}
                                summary2={summary2}
                                titles_by_value={titles_by_value}
                                values={values3}
                                options_by_value={options_by_value}
                                disableHeader={true}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="table-responsive">
                            <table className="table mb-0" style={{marginTop: "-1px"}}>
                                <tbody>
                                <InnerSummaryTable
                                    summary={summary}
                                    summary2={summary2}
                                    titles_by_value={titles_by_value}
                                        values={values2}
                                        options_by_value={options_by_value}
                                        disableHeader={false}
                                    />
                                    {interactiveProps &&
                                        <SingleVariableAggregation
                                            interactiveProps={interactiveProps}
                                            numCasesA={summary.numberOfPatientcases}
                                            numCasesB={summary2 ? summary2.numberOfPatientcases : 0}
                                        />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicData
