import * as React from 'react';
import { i18n } from "../../config/i18n";

interface Props {
    errors: { msg: string }[];
}

const Errors: React.FunctionComponent<Props> = props => {
    if (!props.errors || props.errors.length === 0) {
        return null;
    }
    const tooltipText = props.errors.map((e) => e.msg).join(' | ');
    return (
        <div title={tooltipText}>
            {i18n.t('error')}
            <span className="badge bg-danger">{props.errors.length}</span>
        </div>
    )
};

export default Errors
