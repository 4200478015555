import * as React from 'react';
import ImportProgress from "../components/import/ImportProgress";

interface Props {
    locale: string,
    importId: number,
    userName: number,
    inProgress: boolean
}

const ImportProgressPage: React.FunctionComponent<Props> = (props) => {
    return (<ImportProgress {...props} />)
}

export default ImportProgressPage
